import React, { Component } from "react";
import Trix from "trix";
// import "trix/dist/trix";
import "trix/dist/trix.css";
import { getUserCharColor } from "helper/methods/PlaceholderColor";
import { calc_file_size } from "helper/methods/GetFileSize";
import { v4 as uuidv4 } from "uuid";
// import "tributejs/tribute.css";
import Tribute from "tributejs";
import "./Tribute.style.css";

import TrixEditorWrapper from "components/TaskDetails/TrixEditorWrapper.style";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import { getForwardCommentsUrl } from "helper/services/taskServices";
const publicPaths = {
  txtIcon: "/img/txtIcon.png",
  htmlIcon: "/img/html.png",
  pdf: "/img/pdf.png",
  zip: "/img/zip.png",
  file: "/img/file.png"
};

export default class TrixEditor extends Component {
  constructor(props) {
    super(props);
    this.editorInputRef = React.createRef();
    this.tribute = React.createRef();

    if (this.props.collaboratorsList) {
      var collabs = [];
      this.props.collaboratorsList.map((data, i) => {
        if (data.user) {
          collabs.push({
            id: data.user.id,
            username: data.user.name,
            value: data.user.name,
            key: data.user.name.replace(" ", ""),
            email: data.user.email,
            userMetaUrl: data.user.userMeta
              ? data.user.userMeta.thumbnail
              : null
          });
        }
      });
      this.userCollabList = collabs;
    }
  }

  componentDidMount() {
    this.editorInputRef.current.addEventListener("trix-initialize", e => {
      let reff = this.editorInputRef.current.editor;
      const trixInput = this.editorInputRef.current;

      trixInput.focus();

      // If last character matches my trigger, init my search
      var tr = new Tribute({
        values: [...this.userCollabList],
        iframe: null,
        replaceTextSuffix: "",
        selectTemplate: function(item) {
          return `<a href="#mention-user-${item.original.id}" target="_blank" title="${item.original.key}" data-id="${item.original.id}">@${item.original.key}</a>`;
          // code to add image placeholder in mention
          // var userCharColor = getUserCharColor(
          //   item.original.username.charAt(0)
          // );
          // var userCharLetter = item.original.username[0].toUpperCase();
          // var attachment = new Trix.Attachment({
          //   content: `<span contenteditable='false'  style="display:flex ;align-items: center; padding-right:5px ;height:28px; ">
          //   ${
          //     item.original.userMetaUrl
          //       ? '<span style="display:flex;align-items: center;position:relative;top:4px"><img style=" border-radius:50% ; height:20px; width:20px; margin-right:5px; max-width:20px !important; max-height:20px !important;"  src="' +
          //         item.original.userMetaUrl +
          //         '" ></img></span> <span  style="display:flex;align-items: center;position:relative;top:5px;height:28px;font-size:14px;">' +
          //         item.original.username +
          //         "</span>"
          //       : `<div  style="background:${userCharColor};
          //           border-radius: 50%;
          //          width: 20px;
          //          height: 20px;
          //          font-weight: 600;
          //          text-align: center;
          //          color: white;
          //          font-size: 12px;
          //          display: flex;
          //          justify-content: center;
          //          align-items: center;
          //          margin-right: 5px;
          //          object-fit: cover;" > ${userCharLetter}</div>
          //          <span  style="display:flex;align-items: center;position:relative;top:-1px;height:28px;font-size:14px;">
          //          ${item.original.username}</span>`
          //   }
          //  </span>`
          // });

          // reff.insertAttachment(attachment);
          // return `<span contenteditable="true" style="color:red;">hello</span>`;
        },
        // noMatchTemplate: function() {
        //   return "<span class=mention >not found</span>";
        // },
        menuItemTemplate: function(item) {
          var userCharColor = getUserCharColor(
            item.original.username.charAt(0)
          );
          var userCharLetter = item.original.username[0].toUpperCase();
          return `<span style="display:flex;height:28px;">
  ${
    item.original.userMetaUrl
      ? '<img style=" border-radius:50%; margin-right: 5px; height:20px; width:20px;" src="' +
        item.original.userMetaUrl +
        '" ></img>'
      : `<div  style="background:${userCharColor}; 
      border-radius: 50%;
       width: 20px;
       height: 20px; 
       font-weight: 600;
       text-align: center;
       color: white;
       font-size: 12px;
       display: flex;
       justify-content: center;
       align-items: center;
       margin-right: 5px;
       object-fit: cover;" > ${userCharLetter}</div>`
  }${item.original.username}
  <span>`;
        },
        trigger: "@"
      }).attach(this.editorInputRef.current);
    });

    this.editorInputRef.current.addEventListener("trix-change", event => {
      this.props.onChange(event.target.innerHTML);
      const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
      if (
        this.props.comment.length > 0 &&
        !this.props.comment.match(urlRegex)
      ) {
        this.props.setIsDisable(true);
      }
    });

    this.editorInputRef.current.addEventListener("trix-paste", async event => {
      const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;

      const clipboardText = await navigator.clipboard.readText();
      this.handlePaste(clipboardText);
      if (clipboardText?.match(urlRegex)) {
        this.props.setIsDisable(false);
      } else {
        this.props.setIsDisable(true);
      }
    });
    this.editorInputRef.current.addEventListener("tribute-replaced", event => {
      console.log("here");
    });
    this.editorInputRef.current.addEventListener(
      "tribute-active-true",
      function(e) {
        // console.log("Menu opened!", e);
      }
    );
    this.editorInputRef.current.addEventListener(
      "tribute-active-false",
      function(e) {
        // console.log("Menu closed!", e);
      }
    );

    this.editorInputRef.current.addEventListener(
      "trix-attachment-add",
      event => {
        if (event.attachment.file) {
          let cancelToken = "";
          // uploadFileAttachment(event.attachment)
          const props = this.props;
          let attachment = event.attachment;
          let temporaryId = uuidv4();
          this.props.uploadAttachment(
            attachment.file,
            temporaryId,
            setProgress,
            successCallback,
            errorCallback,
            data => {
              cancelToken = data;
              attachment.setAttributes({
                cancelToken: cancelToken
              });
            }
          );

          function setProgress(progress) {
            attachment.setUploadProgress(progress);
          }
          function successCallback({ data, message }) {
            if (data) {
              let responsePayload = {
                id: data.commentMeta.id,
                url: data.commentMeta.url,
                originalname: data.commentMeta.originalname,
                mimetype: data.commentMeta.mimetype,
                size: data.commentMeta.size,
                keyId: data.commentMeta.key
              };

              setContent(
                attachment,
                responsePayload,
                props.commentAttachmentsUrl,
                setCommentAttachmentsUrl
              );
              attachment.setAttributes({ cancelToken: "" });
            }
            function setCommentAttachmentsUrl(sdata) {
              props.setCommentAttachmentsUrl([...sdata]);
            }
            // attachment.setUploadProgress(progress)
          }
          function errorCallback(response) {}
        }

        // }
      }
    );

    this.editorInputRef.current.addEventListener(
      "trix-attachment-remove",
      event => {
        if (
          event.attachment?.attachment?.attributes.values?.cancelToken &&
          event.attachment?.attachment?.attributes.values?.cancelToken !== ""
        ) {
          event.attachment.attachment.attributes.values.cancelToken.cancel();
          event.attachment.setAttributes({ cancelToken: "" });
          this.props.decreseUploadingCountByOne();
        }
        if (
          event.attachment?.attachment?.attributes.values?.attachmentId &&
          event.attachment?.attachment?.attributes.values?.attachmentId !== ""
        ) {
          let id = event.attachment.attachment.attributes.values.attachmentId;
          this.props.deleteAttachment(id, successCallback);
          function successCallback() {}
        }
        // here we get url event.attachment.attachment.attributes.values.href
      }
    );
    this.editorInputRef.current.addEventListener("trix-file-accept", event => {
      const MaxFileSize = 20 * 1024 * 1024; /* 20 mb limit */
      if (event.file.size <= MaxFileSize) {
      } else {
        event.preventDefault();
        this.props.toggleAttachmentLimitExceedError();
      }
    });
    // Enter value on submit press ctrl + enter

    this.editorInputRef.current.addEventListener("keydown", event => {
      const editorContent = this.editorInputRef.current.editor
        .getDocument()
        .toString()
        .trim();

      if (event.ctrlKey && event.keyCode == 13 && editorContent.length > 0) {
        event.preventDefault();
        this.props.doSubmitValueOnkeyPress();
      }
    });
  }

  componentDidUpdate(prevProps, nextprops) {
    const trixInput = this.editorInputRef.current;
    trixInput.focus();
    if (
      prevProps.reduxFileObj !== this.props.reduxFileObj &&
      this.props.reduxFileObj
    ) {
      const coreAttachment = this.props.reduxFileObj.Filetype;

      const trixEditor = this.editorInputRef.current.editor;

      trixEditor.insertFile(coreAttachment);
    }
  }

  componentWillUnmount() {
    // this.tribute.detach(this.editorInputRef.current);
    this.editorInputRef = null;
    this.tribute = null;
  }

  extractUrl(inputText) {
    const cleanedText = inputText.replace(/(\s+|[￼])+/g, "");
    const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
    const matchedUrls = cleanedText.match(urlRegex);

    const urlArray = inputText.split(/\s+/).filter(Boolean);

    if (urlArray) {
      const cleanedUrls = urlArray.map(url => url.replace(/(\s+|[￼])+/g, ""));

      return cleanedUrls;
    } else {
      return "No URLs found in the input text.";
    }
  }

  handlePaste = async url => {
    let htmlAttachment;
    const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
    const editor = this.editorInputRef.current.editor;
    var element = document.querySelector("trix-editor");

    const openGraphData = await this.fetchOpenGraphData(url, urlRegex);

    if (openGraphData?.type == "video.other") {
      const editorValue = element.editor.getDocument().toString();

      const startIndex = editorValue.length - url.length;

      const endIndex = startIndex + url.length;

      element.editor.setSelectedRange([startIndex - 1, endIndex]);

      element.editor.deleteInDirection("forward");

      const videoAttachment = `<div style="position: relative; width: 100%; padding-bottom: 56.25%;">
      <iframe 
        width="100%" 
        height="100%" 
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" 
        src="${openGraphData?.video}" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
      </iframe>
    </div>`;
      htmlAttachment = new Trix.Attachment({
        content: videoAttachment
      });
      return editor.insertAttachment(htmlAttachment);
    } else if (
      !openGraphData ||
      !openGraphData?.title ||
      !openGraphData?.description
    ) {
      const editorValue = element.editor.getDocument().toString();
      const extractedUrl = this.extractUrl(editorValue);

      if (extractedUrl[extractedUrl.length - 1].includes(url)) {
        const startIndex = editorValue.length - url.length;

        const endIndex = startIndex + url.length;

        element.editor.setSelectedRange([startIndex - 1, endIndex]);

        element.editor.activateAttribute("href", url);

        var length = element.editor.getDocument().toString().length;
        editor.setSelectedRange(length - 1);
        element.editor.deactivateAttribute("href");
      } else {
        return url;
      }
    } else {
      const editorValue = element.editor.getDocument().toString();
      const extractedUrl = this.extractUrl(editorValue);

      if (extractedUrl[extractedUrl.length - 1].includes(url)) {
        const startIndex = editorValue.length - url.length;

        const endIndex = startIndex + url.length;

        element.editor.setSelectedRange([startIndex - 1, endIndex]);

        element.editor.activateAttribute("href", url);

        var length = element.editor.getDocument().toString().length;
        editor.setSelectedRange(length - 1);
      }

      const attachmentHTML = `
      <div style="max-width: 665px; text-align: left; border-radius: 1.2rem; background-color: #ecf9fd; padding: 1em; line-height: 1.3em; color: rgb(40,60,70); gap: 2rem; justify-content: space-between; display: flex;">
  <div style="overflow: hidden;">
    <div style="border: 1px solid lightgray; border-radius: 1.2rem; background: white; padding: 15px;">
      <div style="padding: 0 0 0.4rem 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
        <a href="${url}" target="_blank" style="display: block; width:fit-content;overflow: hidden; text-overflow: ellipsis; padding: 11px; font-size: 16px; font-weight: 600; white-space: nowrap;"><b>${
        openGraphData?.title
      }</b></a>
      </div>
      <div style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">${
        openGraphData?.description
      }</div>
    </div>
  </div>
  ${
    openGraphData?.image || openGraphData?.icon
      ? `<div style="max-width: 40%; display: grid; align-items: end;">
  <img src="${
    openGraphData.image ? openGraphData.image : openGraphData?.icon
  }"  width="100%" style="max-width: 100%; height: auto; min-height: 8rem; height: 8rem; overflow: hidden; object-fit: contain;">
</div>`
      : `<div style="max-width: 40%; display:none; align-items: end;">
<img src="${openGraphData.image}"  width="100%" style="max-width: 100%; height: auto; min-height: 8rem; height: 8rem; overflow: hidden; object-fit: contain;">
</div>`
  }
  
</div>
`;

      htmlAttachment = new Trix.Attachment({
        content: attachmentHTML
      });

      return editor.insertAttachment(htmlAttachment);
    }
  };

  fetchImageStatusCode = async imageUrl => {
    try {
      const imageResponse = await fetch(imageUrl);
      return imageResponse.status;
    } catch (error) {
      return 0;
    }
  };

  fetchOpenGraphData = async (url, urlRegex) => {
    try {
      if (url.match(urlRegex)) {
        const response = await getForwardCommentsUrl(url);
        const html = await response.text();
        const metaData = JSON.parse(html);
        if (response?.ok) {
          this.props.setIsDisable(true);
        } else {
          this.props.setIsDisable(false);
        }

        return metaData;
      } else {
        // this.props.setIsDisable(true);
        return url;
      }
    } catch (error) {
      this.props.setIsDisable(true);
      console.log("Error fetching Open Graph data", error);
      return url;
    }
  };

  render() {
    return (
      <>
        <TrixEditorWrapper {...this.props}>
          <div
            style={{
              border: "1px solid #ebebeb",
              borderRadius: 5,
              position: "relative"
            }}
          >
            <input type="hidden" id="trix" value={this.props.value} />
            <trix-editor
              class="trix-content"
              input="trix"
              ref={this.editorInputRef}
              placeholder={InjectPlaceHolderMessage(
                "task-details.typeyourcommenthere",
                this.props?.LanguageSwitcher
              )}
            />
          </div>
        </TrixEditorWrapper>
      </>
    );
  }
}

function setContent(
  attachment,
  data,
  attachmentUrls = [],

  setCommentAttachmentsUrl
) {
  let height = "300px";
  let width = "500px";
  let file = attachment.file;
  let attributes = {
    previewable: false,
    attachmentId: data.id,
    url: data.url,
    href: data.url,
    keyId: data.keyId,
    target: "_blank",
    rel: "noopener noreferrer"
  };
  let attachmentsurlarray = [...attachmentUrls];
  attachmentsurlarray.push({
    keyId: data.keyId,
    url: data.url
  });

  // console.log(attributes);
  setCommentAttachmentsUrl([...attachmentsurlarray]);

  if (!data.mimetype) {
    data.mimetype = "application/file";
  }
  if (data.mimetype.includes("image")) {
    // image

    attributes["content"] = `
    <div style= "display: flex;" class="trix-main" >
    <span class="trix-preview-image trix-main" data-url="${
      data.url
    }" data-name="${data.originalname}" style= "display: block;">
  <img src="${
    data.url
  }" class="trix-preview-image img-fluid trix-main" style="max-width: 100%; height: auto; display: block; margin: 0 auto;">
  <div class="pb-4 trix-main" title="${
    data.originalname
  }" style="font-size: 12px; text-align: center;">
    <span style="color: #808080" class="trix-main">${data.originalname}</span>
    <div style="text-align: center;" class="trix-main">
      <i style="color: #808080; margin-left: 5px; margin-right: 5px; margin-top: 3px; cursor: pointer" class="fa fa-link fs-10"></i>
      <span style="color: #808080">${calc_file_size(
        data.size
      )} <a style="text-decoration: underline; color: #808080" href="${
      data.url
    }" target="_blank" rel="noopener noreferrer"><span>View full-size</span></a></span>
      <span style="text-decoration: underline; color: #6d6d6d; cursor: pointer; margin-left: 4px;" onclick="DownloadImg('${
        data.url
      }','${data.originalname}')">Download</span>
    </div>
  </div>
</span>
</div>
   `;
  } else if (data.mimetype.includes("video")) {
    // video
    attributes.previewable = true;
    attributes["content"] = `
             <span class="trix-preview-video" >
                 <video height='${height}' width='100%' min-width='${width}' max-width='${width}' controls autoplay>
                     <source src="${data.url}" type="${data.mimetype}">
                 </video>
                 <span style=' font-size:12px;padding-top:10px; text-align:center'>
                 <div style=' color: #808080'>${data.originalname} </div >
                <div style='text-align:center'> <i  style='color: #808080; margin-left: 5px; margin-right: 5px;margin-top: 3px;cursor: pointer' class="fa fa-link fs-10"></i>
                <span style=' color: #808080'>${calc_file_size(
                  data.size
                )}  <a style= 'text-decoration:underline ; color:#6d6d6d' href="${
      data.url
    }" target="_blank" rel="noopener noreferrer"><span>View full-size </span>·</a></span>
     <span style='text-decoration:underline; color:#6d6d6d;cursor:pointer;margin-left:4px' onclick="DownloadImg('${
       data.url
     }','${data.originalname}')">Download</span></div>
    </span>`;
  } else if (data.mimetype.includes("html")) {
    attributes["content"] = `
        <span class="trix-preview-file" style='display: flex ;align-items: center;'>
        <img src="${
          publicPaths.htmlIcon
        }"  class="trix-preview-image" height='50px' width='50px' data-url="${
      data.url
    }"  data-name="${data.originalname}"  ></img >
    
    <span style=' color: #808080'>${data.originalname} 
    <br/>
              <span style=' color: #808080'>${calc_file_size(
                data.size
              )} · <span style= 'text-decoration:underline ; color:#6d6d6d' ><a style="color:#808080" href="${
      data.url
    }" target="_blank" rel="noopener noreferrer"><span>Download</span>  </a></span>
         </span>
         </span >
        </span>
    `;
    attachment.setUploadProgress(100);
    attachment.setAttributes(attributes);
  } else if (data.mimetype.includes("text")) {
    attributes["content"] = `
        <span class="trix-preview-file" style='display: flex ;align-items: center;'>
        <img src="${
          publicPaths.txtIcon
        }"  class="trix-preview-image" height='50px' width='50px' data-url="${
      data.url
    }"  data-name="${data.originalname}"  > </img>

              <span >
              <span style=' color: #808080'>${data.originalname}
              <br/>
              <span style=' color: #808080'>${calc_file_size(
                data.size
              )} · <span><a href="${
      data.url
    }" target="_blank" style="color:#808080" rel="noopener noreferrer"><span>Download</span>
        </a></span>
        </span>
        </span>
        </span >
    `;
    attachment.setUploadProgress(100);
    attachment.setAttributes(attributes);
  } else if (data.mimetype.includes("zip")) {
    attributes["content"] = `
        <span class="trix-preview-file" style='display: flex ;align-items: center;'> 
        
        <img src="${
          publicPaths.zip
        }"  class="trix-preview-image" height='50px' width='50px' data-url="${
      data.url
    }"  data-name="${data.originalname}" ></img> 
              <span style=' color: #808080'>${data.originalname} 
              <br/>
              <span style=' color: #808080'>${calc_file_size(
                data.size
              )} <span style="text-decoration:underline;"><a href="${
      data.url
    }" target="_blank" rel="noopener noreferrer" style="color:#808080">· <span>Download</span></a></span>
        </span>
        </span>
        </span >
    `;

    attachment.setUploadProgress(100);
    attachment.setAttributes(attributes);
  } else if (data.mimetype.includes("pdf")) {
    attributes["content"] = `
        <span class="trix-preview-file" style='display: flex ;align-items: center;'>
        <img src="${
          publicPaths.pdf
        }"  class="trix-preview-image" height='50px' width='50px' data-url="${
      data.url
    }"  data-name="${data.originalname}"  ></img >

              <span style=' color: #808080'>${data.originalname} 
              <br/>
              <span style=' color: #808080'>${calc_file_size(data.size)} 
      · <span style= 'text-decoration:underline ;' ><a style='color: #808080' href="${
        data.url
      }" target="_blank" rel="noopener noreferrer"><span>View full-size </span></a></span>
        </span>
        <span style='text-decoration:underline; color:#6d6d6d;cursor:pointer;margin-left:4px' onclick="DownloadImg('${
          data.url
        }','${data.originalname}')">· Download</span>
              </span>
              </span></span >`;
    attachment.setUploadProgress(100);
    attachment.setAttributes(attributes);
  } else {
    // other
    attributes["content"] = `
      <span class="trix-preview-file" style='display: flex ; padding-top:10px;align-items: center; '>
     
      <img src="${
        publicPaths.file
      }"  class="trix-preview-image" height='50px' width='50px' data-url="${
      data.url
    }"  data-name="${data.originalname}"  ></img >
            <div>
            <div style=' color: #808080'>${data.originalname} </div> 
            <div style=' color: #808080'>${calc_file_size(
              data.size
            )} · <span style= 'text-decoration:underline ; color:#6d6d6d' > <a href="${
      data.url
    }" target="_blank" rel="noopener noreferrer" style="color:#808080"><span>Download</span></a></span>
            </div></div>
            
      </span>


  `;

    setCommentAttachmentsUrl([...attachmentsurlarray]);
    attachment.setUploadProgress(100);
    attachment.setAttributes(attributes);
  }
  attachment.setUploadProgress(100);
  attachment.setAttributes(attributes);
}
