import handleResponse, { encryptPayload } from "./handleResponse";
import { BASE_URL, label_filter, task_ } from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";

export function getLabelByID(id, token) {
  const labelid = {
    label_id: id
  };

  const encryptedData = encryptPayload(labelid);
  const encryptObj = { encPayload: encryptedData };
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "POST",
    headers: givenToken,
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + label_filter,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function updateTaskIsCompleted(data, taskId) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + `/${taskId}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function updateLabel(data, id) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + "label" + `/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
