/** @format */

import React, { Fragment, useState, useEffect } from "react";
import RoyTooltip from "components/common/RoyTooltip";
import SidebarWrapper from "./sidebar.style";
import Radium from "radium";
import NavList from "components/sidebar/NavList";
import BusinessModal from "components/business/BusinessListModal";
import AddBusiness from "components/business/CreateWorkspace&InviteTeam";
import { businessSocketRoomPrefix } from "constatnt/index";
import ErrorAlert from "util/ErrorAlert";
import {
  joinRoom,
  leaveRoom,
  disconnect,
  businessInit,
  updateBusiness
} from "services/socket";
import {
  createProject,
  getProject,
  getLables
} from "helper/services/projectServices";
import { getWorkspaceDetailsByid } from "helper/services/businessServices";
import { connect } from "react-redux";
import { getUser } from "helper/services/authServices";
import {
  sidebarData,
  checkforunique,
  updateSidebarProjectToSidebar,
  updateSidebarLabelToSidebar
} from "util/data/sidebar";
import { Scrollbars } from "react-custom-scrollbars";
import InviteCollaborators from "components/collaborators/InviteCollaborators";
import IntlMessages from "util/intlMessages";
import projectActions from "redux/projects/actions";
import alertActions from "redux/globalAlert/actions";
import labelActions from "redux/labels/actions";
import AddProject from "components/projects/AddProject";
import EditProject from "components/projects/EditProject";
import AddLabel from "components/Labels/AddLabel";
import AuthActions from "redux/auth/actions";
import CustomNotification from "util/CustomNotification";
import MoveProjectWorkspaceList from "components/business/MoveProjectWorkspaceList";
import { invalidWorkspace, invalidProject } from "constatnt/index";
import projectModalAction from "redux/projectModal/actions";
import updateProjectActions from "redux/projectAction/actions";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import GlobalFavouritesSelection from "components/Favourites/GlobalFavouritesSelection";
import languageActions from "redux/languageSwitcher/actions";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
const { changeLanguage } = languageActions;

const { open_project_modal, close_project_modal } = projectModalAction;
const { do_update_project, close_update_project } = updateProjectActions;
const { set_projects } = projectActions;
const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;
const { update_User } = AuthActions;
const { set_labels, update_Label } = labelActions;
const { update_Project, update_projects_existing } = projectActions;

const Sidebar = props => {
  const { addToast } = useToasts();

  const [modal, setmodal] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isInviteCollaborators, setInviteCollaborators] = useState({
    status: false,
    project: ""
  });
  const [moveProjectModal, setMoveProjectModal] = useState({
    status: false,
    project: ""
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [addLabelModal, setAddLabelModal] = useState(false);
  const [businessModal, setBusinessModal] = useState(false);
  const [addBusiness, setAddBusiness] = useState(false);
  const [toast, setToast] = useState(false);
  const [isFavouritesSidebar, setIsFavouritesSidebar] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [redirectLink, setRedirectLink] = useState(false);
  const [redirectMessage, setRedirectMessage] = useState(false);
  let listNameStyle;
  let sidebar;
  let sideScrollStyle;
  useEffect(() => {
    setTimeout(() => fetchUser(), 300);

    // businessInit(updateBusinessCB, props.auth.accessToken);
    // joinRoom(`${businessSocketRoomPrefix}${props.auth?.user?.businessId}`);
    props.close_project_modal();
    if (
      !props.auth?.user?.hasOwnProperty("businessName") ||
      props.auth?.user?.businessName == null
    ) {
      // setAddBusiness(true);
      checkBusinessExits();
    } else {
      fetchProject();
      fetchLabels();
    }
    return () => {
      setMoveProjectModal({ status: false, project: "" });
      leaveRoom(`${businessSocketRoomPrefix}${props.auth?.user?.businessId}`);
      props.close_alert();
      props.close_project_modal();
      props.close_update_project();
    };
  }, [props.auth?.user?.businessId]);
  const checkBusinessExits = async () => {
    try {
      const Response = await getUser(props.auth.accessToken);

      if (Response) {
        props.changeLanguage(Response.user.userSetting.language);

        if (Response.error) {
          handleResponse(Response.error.message);
          // setTimeout(() => setBusinessModal(true), [300]);
        }
        if (Response.user) {
          props.update_User(Response.user);

          if (
            !Response.user?.isNewUserBusinessAdded &&
            Response.user?.businessName == null
          ) {
            setAddBusiness(true);
          } else {
            // setBusinessModal(true);
          }
        }
      }
    } catch (e) {
      setTimeout(() => setBusinessModal(true), [300]);
    }
  };
  const updateProjectandLabel = async token => {
    fetchProject(token);
    fetchLabels();
  };
  const updateProjectToRedux = data => {
    props.update_Project(data);
  };

  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const fetchUser = async () => {
    const Response = await getUser(props.auth.accessToken);
    if (Response) {
      props.changeLanguage(Response.user.userSetting.language);

      if (Response.error) {
        handleResponse(Response.error.message);
      }
      if (Response.user) {
        props.update_User(Response.user);
      }
    }
  };
  const handleResponse = message => {
    try {
      if (message == "NOT_WORKSPACE_COLLABORATOR") {
        // status:action.alert.status, message:action.alert.message,action:'open_businesslist'

        props.open_workpsace_alert({
          status: true,
          message: "workspace"
        });
      }
      if (message == "USER_NOT_PROJECT_COLABORATOR") {
        props.open_project_alert({
          status: true,
          message: "project"
        });
      }
    } catch (e) {}
  };
  const updateBusinessCB = async data => {
    updateProjectandLabel();
  };
  const handleBusinessSocketRoom = async (
    prevBusinessId,
    newBusinessId,
    token
  ) => {
    await leaveRoom(`${businessSocketRoomPrefix}${prevBusinessId}`);
    disconnect();
    await businessInit(updateBusinessCB, token);
    await joinRoom(`${businessSocketRoomPrefix}${newBusinessId}`);
  };
  const fetchLabels = async () => {
    try {
      const Response = await getLables(
        props.auth.accessToken || props.history.location.state.token
      );
      if (Response) {
        if (Response.data) {
          props.set_labels({ labels: Response.data });
          updateSidebarLabelToSidebar(Response.data);
          // sidebarData.map((data, i) => {
          //   if (data.name === "sidebar.labels") {
          //     Response.data.map((a, i) => {
          //       if (!checkforunique(data.child, a)) {
          //         data.child.push({
          //           id: a.id,
          //           listname: a.name,
          //           routepath: `/label/${a.id}`,
          //           color: a.color,
          //         });
          //       }
          //     });
          //   }
          // });
        }
        if (Response.error) {
        }
      }
    } catch (e) {}
  };

  const fetchProject = async token => {
    try {
      const Response = await getProject(
        token
          ? token
          : props.auth.accessToken || props.history.location.state.token
      );
      if (Response) {
        if (
          props?.auth?.user?.hasOwnProperty("businessId") &&
          Response?.data[0]?.business?.id != props?.auth?.user?.businessId
        ) {
          let result = await getWorkspaceDetailsByid(
            props?.auth?.user?.businessId
              ? props?.auth?.user?.businessId
              : Response?.data[0]?.business?.id
          );
          if (result) {
            props.set_projects({ projects: result?.data?.project });
            UpdateSidebarProject(result?.data?.project);
          }
        }

        if (Response.data[0].business?.id == props?.auth?.user?.businessId) {
          props.set_projects({ projects: Response.data });
          UpdateSidebarProject(Response.data);
        }
      }
    } catch (e) {}
  };
  const UpdateSidebarProject = ResponseData => {
    updateSidebarProjectToSidebar(ResponseData);
  };

  const {
    mini,
    drawerWidth,
    miniDrawerWidth,
    onMouseEnter,
    onMouseLeave,
    sidebarTheme,
    layoutTheme,
    closeDrawer,
    themeSetting,
    topbarTheme
  } = props;

  if (
    themeSetting.toolbarAlignValue === "above" &&
    themeSetting.footerAlignValue === "above"
  ) {
    sideScrollStyle = {
      zIndex: 5,
      height: "calc(100vh - 190px)"
    };
  } else if (themeSetting.toolbarAlignValue === "above") {
    sideScrollStyle = {
      zIndex: 5,
      // height: "calc(100vh - 145px)"
      height: "calc(100vh - 75px)"
    };
  } else if (themeSetting.footerAlignValue === "above") {
    sideScrollStyle = {
      zIndex: 5,
      height: "calc(100vh - 128px)"
    };
  } else {
    sideScrollStyle = {
      zIndex: 5,
      height: "calc(100vh - 75px)"
    };
  }

  if (themeSetting.sidebarTransParentValue === "on") {
    sidebar = {
      backgroundImage: `linear-gradient(0deg,rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.9)),url(${themeSetting.transparentImage})`,
      backgroundRepeat: "no-repeat, repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      width: mini ? miniDrawerWidth : drawerWidth,
      "@media (max-width: 991.98px)": {
        width: mini ? 0 : drawerWidth
      }
    };
  } else {
    sidebar = {
      width: mini ? miniDrawerWidth : drawerWidth,
      background: sidebarTheme.backgroundColor,
      "@media (max-width: 991.98px)": {
        width: mini ? 0 : drawerWidth
      }
    };
  }

  const closeIcon = {
    "@media (max-width: 991.98px)": {
      display: "block"
    }
  };

  if (mini) {
    listNameStyle = {
      opacity: miniDrawerWidth === drawerWidth ? 1 : 0,
      transform:
        miniDrawerWidth === drawerWidth
          ? "translateZ(0)"
          : "translate3d(-25px,0,0)"
    };
  } else {
    listNameStyle = {
      opacity: !mini ? 1 : 0,
      transform: !mini ? "translateZ(0)" : "translate3d(-25px,0,0)"
    };
  }

  const handleProjectSubmit = async e => {
    e.preventDefault();
    setVisible(true);
    if (e.target.name.value && e.target.viewoption.value) {
      try {
        const Response = await createProject({
          name: e.target.name.value,
          color: e.target.projectcolor.value,
          isFavorite: e.target.addtoFavorite.checked,
          defaultView: e.target.viewoption.value
        });
        if (Response) {
          if (Response.error) {
            setVisible(true);
            setErrorMessage(Response.error.message);
          } else if (Response.errors) {
            setVisible(true);
            setErrorMessage(Response.errors.message);
          } else {
            setVisible(false);

            if (Response.data) {
              sidebarData.map((data, i) => {
                if (data.name === "sidebar.projects") {
                  data.child.push({
                    listname: Response.data.name,
                    routepath: `/project/${data.id}`,
                    shortname: "P1"
                  });
                }
              });
            }
            setmodal(false);
          }
        }
      } catch (e) {}
    }
  };
  return (
    <>
      <SidebarWrapper
        topbarTheme={topbarTheme}
        themeSetting={themeSetting}
        sidebarTheme={sidebarTheme}
        layoutTheme={layoutTheme}
        mini={mini}
        miniDrawerWidth={miniDrawerWidth}
        drawerWidth={drawerWidth}
      >
        {!mini && (
          <div className="sidebar-overlay" onClick={closeDrawer()}></div>
        )}
        <div
          id="sidebar"
          className="sidebar sideBack"
          style={sidebar}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          // style={{backgroundColor:"#fafafa"}}
        >
          <div
            className="sidebar-header"
            style={{
              padding: "5px 0px",
              borderBottom: "1px solid rgba(0,0,0,0.06)"
            }}
          >
            <div className="sidebar-header">
              {/* <div className="simple-text logo-mini">
            <div
              className="logo-img"
              onClick={() => {
                if (props.auth?.user?.businessId)
                  props.history.push(
                    `/business/${props.auth?.user?.businessId}`
                  );
              }}
            >
              <img
                style={{
                  borderRadius: "50%",
                  background:
                    props?.auth?.user?.businessName &&
                    props?.auth?.user?.businessThumbnailImage
                      ? ""
                      : "gray"
                }}
                src={
                  props?.auth?.user?.businessName &&
                  props?.auth?.user?.businessThumbnailImage
                    ? props?.auth?.user?.businessThumbnailImage
                    : businessImage
                }
                alt="Todo"
              />
            </div>
          </div> */}
              <div
                className="logo-text  fs-14 bold-text"
                style={{
                  display: "flex",
                  background: "#f8f8f8",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "22px",
                  borderRadius: "5px"
                }}
              >
                {props.auth?.user?.businessName ? (
                  <>
                    <div>
                      <RoyTooltip
                        id="businessName"
                        title={InjectPlaceHolderMessage(
                          "sidebar.workspacetitle",
                          props?.language?.language?.languageId
                        )}
                      >
                        <div
                          id="businessName"
                          onClick={() => {
                            if (props.auth?.user?.businessId)
                              props.history.push(
                                `/business/${props.auth?.user?.businessId}`
                              );
                          }}
                          style={{
                            display: "inline-block",
                            width: 227,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            // textDecoration: "underline",
                            // color: "#808080"
                            color: "black"
                          }}
                        >
                          <IntlMessages id={"header.workspace"} />
                          {/* {props.auth?.user?.businessName} */}
                        </div>
                      </RoyTooltip>
                      <span onClick={() => setBusinessModal(!businessModal)}>
                        <RoyTooltip
                          id="businessListModal"
                          title={InjectPlaceHolderMessage(
                            "sidebar.manage_workspace",
                            props?.language?.language?.languageId
                          )}
                        >
                          <i
                            id="businessListModal"
                            onClick={() => setBusinessModal(!businessModal)}
                            className="pl-2 fas fa-angle-down  "
                            style={{
                              margin: 0,
                              position: "absolute",
                              top: "47%",
                              transform: "translateY(-50%)",
                              // color: "#808080",
                              color: "black",
                              fontSize: 17,
                              right: "20px"
                            }}
                          />
                        </RoyTooltip>
                      </span>
                    </div>
                  </>
                ) : (
                  <div>
                    <RoyTooltip
                      id="chooseWorkspace"
                      title={InjectPlaceHolderMessage(
                        "sidebar.choose_workspace",
                        props?.language?.language?.languageId
                      )}
                    >
                      <div
                        id="chooseWorkspace"
                        onClick={() => setBusinessModal(!businessModal)}
                        style={{
                          display: "inline-block",
                          width: 140,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textDecoration: "underline"
                        }}
                      >
                        <IntlMessages id={"sidebar.Choose_Workspace"} />
                      </div>
                    </RoyTooltip>
                  </div>
                )}
              </div>
            </div>
            <div className="sidebar-header">
              <div
                className="logo-text  fs-14 bold-text"
                style={{
                  display: "flex",
                  background: "#f8f8f8",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "22px",
                  borderRadius: "5px"
                }}
              >
                <>
                  <RoyTooltip
                    id="favourites"
                    title={InjectPlaceHolderMessage(
                      "sidebar.favourites",
                      props?.language?.language?.languageId
                    )}
                  >
                    <div
                      id="favourites"
                      onClick={() => {
                        setIsFavouritesSidebar(!isFavouritesSidebar);
                      }}
                      style={{
                        display: "inline-block",
                        width: 227,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // textDecoration: "underline",
                        // color: "#808080"
                        color: "black"
                      }}
                    >
                      <IntlMessages id={"sidebar.favourites"} />
                    </div>
                  </RoyTooltip>
                </>
              </div>
            </div>
            <div className="sidebar-header">
              <div
                className="logo-text  fs-14 bold-text"
                style={{
                  display: "flex",
                  background: "#f8f8f8",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "22px",
                  borderRadius: "5px"
                }}
              >
                <>
                  <RoyTooltip
                    id="activity"
                    title={InjectPlaceHolderMessage(
                      "sidebar.activity",
                      props?.language?.language?.languageId
                    )}
                  >
                    <div
                      id="activity"
                      onClick={() => {
                        props.history.push(`/activity`);
                      }}
                      style={{
                        display: "inline-block",
                        width: 227,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // textDecoration: "underline",
                        // color: "#808080"
                        color: "black"
                      }}
                    >
                      <IntlMessages id={"sidebar.activity"} />
                    </div>
                  </RoyTooltip>
                </>
              </div>
            </div>
            <div className="sidebar-header">
              <div
                className="logo-text  fs-14 bold-text"
                style={{
                  display: "flex",
                  background: "#f8f8f8",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "22px",
                  borderRadius: "5px"
                }}
              >
                <>
                  <RoyTooltip
                    id="My_Assignments"
                    title={InjectPlaceHolderMessage(
                      "header.myassignments",
                      props?.language?.language?.languageId
                    )}
                  >
                    <div
                      id="My_Assignments"
                      onClick={() => {
                        props.history.push(`/my-assignments`);
                      }}
                      style={{
                        display: "inline-block",
                        width: 227,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // textDecoration: "underline",
                        // color: "#808080"
                        color: "black"
                      }}
                    >
                      {
                        <IntlMessages
                          id={"globalmyassignment.myassignmentstab"}
                        />
                      }
                    </div>
                  </RoyTooltip>
                </>
              </div>
            </div>
          </div>
          {/* <div
          className="close-drawer-icon"
          style={closeIcon}
          onClick={closeDrawer()}
        >
          <i className="fas fa-times-circle" />
        </div> */}
          <Scrollbars
            autoHide
            style={sideScrollStyle}
            renderThumbVertical={({ style, ...props }) => (
              <div {...props} className="sidebar-scrollbar-style" />
            )}
            renderThumbHorizontal={({ style, ...props }) => <div {...props} />}
            renderTrackVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  zIndex: 5,
                  position: "absolute",
                  width: "6px",
                  right: "2px",
                  bottom: "2px",
                  top: "2px",
                  borderRadius: "3px"
                }}
              />
            )}
          >
            <div className="sidebar-wrapper">
              <ul className="nav">
                {sidebarData.map((list, i) => {
                  return (
                    <Fragment key={i}>
                      {list.type && list.type === "heading" ? (
                        (!mini || miniDrawerWidth === drawerWidth) && (
                          <div className="sidelist-header-name">
                            {
                              <Fragment>
                                <IntlMessages id={list.name} />
                              </Fragment>
                            }
                          </div>
                        )
                      ) : (
                        <NavList
                          openModel={() => setmodal(!modal)}
                          ToggleaddLabel={() =>
                            setAddLabelModal(!addLabelModal)
                          }
                          listNameStyle={listNameStyle}
                          list={list}
                          mini={mini}
                          miniDrawerWidth={miniDrawerWidth}
                          drawerWidth={drawerWidth}
                          update_projects_existing={data =>
                            props.update_projects_existing({ projects: data })
                          }
                          showNotifications={showNotifications}
                          {...props}
                          isInviteCollaborators={isInviteCollaborators}
                          setInviteCollaborators={setInviteCollaborators}
                          moveProjectModal={moveProjectModal}
                          toggleMoveProject={project =>
                            setMoveProjectModal({
                              status: true,
                              project: project
                            })
                          }
                        />
                      )}
                    </Fragment>
                  );
                })}
              </ul>
            </div>
          </Scrollbars>
        </div>
        <AddLabel
          modal={addLabelModal}
          sidebarData={sidebarData}
          setmodal={() => {
            setAddLabelModal(!addLabelModal);
          }}
          updateLabelToRedux={data => {
            props.update_Label(data);
          }}
          language={props.LanguageSwitcher.language.languageId}
        />
        <BusinessModal
          topbarTheme={sidebarTheme}
          modal={businessModal}
          setmodal={() => setBusinessModal(!businessModal)}
          {...props}
          handleBusinessJoinRoom={(prevBusinessId, newBusinessId, token) =>
            handleBusinessSocketRoom(prevBusinessId, newBusinessId, token)
          }
        />

        <AddBusiness
          modal={addBusiness}
          setmodal={() => setAddBusiness(!addBusiness)}
          updateRedux={data => updateProjectToRedux(data)}
          updateProjectandLabel={(token, businessName, businessMeta) => {
            updateProjectandLabel(token);
            setTimeout(() => {
              // Delay this action by one second
            }, 800);
          }}
          history={(route, data) => {
            props.history.push(route, { ...data });
          }}
        />
        <ErrorAlert
          show={
            (props.auth.user && props.auth.user?.newUser
              ? false
              : props.ErrorAlert?.alert?.status) || false
          }
          PRIMARY_COLOR={props.themeChanger.topbarTheme.buttonColor}
          messageType={props.ErrorAlert?.alert?.message || "workspace"}
          onCancel={() => props.close_alert()}
          onConfirm={() => {
            if (props.ErrorAlert?.alert?.action == "open_businesslist") {
              // setBusinessModal(true);
              props.close_alert();
            }
            if (props.ErrorAlert?.alert?.action == "today") {
              props.history.push("/today");
              props.close_alert();
            } else {
              // setBusinessModal(!businessModal);
            }
          }}
        />
        <AddProject
          modal={modal}
          setmodal={() => {
            setmodal(!modal);
          }}
          showNotifications={showNotifications}
          history={route => props.history.push(route)}
          updateRedux={data => {
            if (props.auth.user.businessId === data.business.id) {
              updateProjectToRedux(data);
            }
          }}
          UpdateSidebarProject={data => {
            UpdateSidebarProject(data);
          }}
          checkforunique={(datachild, toCheckValue) =>
            checkforunique(datachild, toCheckValue)
          }
          sidebarData={sidebarData}
          language={props.LanguageSwitcher.language.languageId}
        />
        <EditProject
          modal={props.projectModal?.isOpen || false}
          setmodal={() => props.close_project_modal()}
          project={props.projectModal?.isOpen && props.projectModal?.project}
          setProject={data => {}}
          updateProjectToRedux={data =>
            props.update_projects_existing({ projects: data })
          }
          showNotifications={showNotifications}
          languageName={
            props.projectModal?.isOpen &&
            props?.LanguageSwitcher?.language?.languageId
          }
        />
        <InviteCollaborators
          setmodal={() =>
            setInviteCollaborators({ status: !isInviteCollaborators.status })
          }
          showNotifications={showNotifications}
          modal={isInviteCollaborators.status}
          project={isInviteCollaborators.project}
          type={"singleProject"}
        />
        <MoveProjectWorkspaceList
          modal={moveProjectModal.status}
          setmodal={() => setMoveProjectModal({ status: false, project: "" })}
          project={moveProjectModal?.project}
          {...props}
        />
        <CustomNotification
          show={toast}
          message={toastMessage}
          closeNotification={() => setToast(false)}
          redirectLink={redirectLink}
          redirectMessage={redirectMessage}
        />
        <div className="sidebar-header-globalFavouritesSelection">
          <GlobalFavouritesSelection
            isFavouritesSidebar={isFavouritesSidebar}
            setIsFavouritesSidebar={setIsFavouritesSidebar}
          />
        </div>
      </SidebarWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

// export default Radium(Sidebar);

export default connect(mapStateToProps, {
  set_projects,
  update_Project,
  set_labels,
  update_Label,
  update_User,
  open_workpsace_alert,
  open_project_alert,
  close_alert,
  open_project_modal,
  close_project_modal,
  update_projects_existing,
  do_update_project,
  close_update_project,
  changeLanguage
})(Radium(Sidebar));
