import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { getUser } from "helper/services/authServices";
import AuthActions from "redux/auth/actions";
import Switch from "react-switch";
import { updateUserSettings } from "helper/services/settingsServices";
import CustomNotification from "util/CustomNotification";
import themeActions from "redux/themeChanger/actions.js";
import actions from "redux/themeSettings/actions";
import ThemeSelect from "components/Theme/ThemeSelect";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import chroma from "chroma-js";
import "react-phone-input-2/lib/material.css";
import LanguageSwitcher from "components/themesetting/LanguageSwitcher";
import config from "settings/languageConfig";
import languageActions from "redux/languageSwitcher/actions";
import IntlMessages from "util/intlMessages";

const timezoneData = require("helper/data/timezones.json");

const { update_Settings } = AuthActions;
const { changeTheme } = themeActions;
const { sidebarTransParent } = actions;
const { changeLanguage } = languageActions;

const Settings = props => {
  const { topbarTheme } = props;
  const [timeformat, setTimeFormat] = useState("");
  const [language, setLanguage] = useState();
  const [dateformat, setDateFormat] = useState("");
  const [timezone, setTimezone] = useState("");
  const [theme, setTheme] = useState("light");
  const [isLabelAnalysis, setIsLabelAnalysis] = useState(false);
  const [isPriorityAnalysis, setIsPriorityAnalysis] = useState(false);
  const [isFavorites, setIsFavorites] = useState(false);
  const [isdueTaskTracker, setIsDueTaskTracker] = useState(false);
  const [isAssignedToMe, setIsAssignedToMe] = useState(false);
  const [isAllTask, setIsAllTask] = useState(false);
  const [isAllowedAddPastDate, setIsAllowedAddPastDate] = useState(false);
  const [isEnabledPushNotification, setIsEnabledPushNotification] = useState(
    false
  );

  const [isCounteries, setIsCounteries] = useState("1");
  const [counteriesNumber, setCounteriesNumber] = useState();
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [otpValue, setOtpValue] = useState();
  const [isOtpValueValid, setIsOtpValueValid] = useState(true);

  const [countryListValue, setCountryListValue] = useState({
    value: "+91",
    data: "🇮🇳+91",
    label: "🇮🇳 India (+91)"
  });

  const [selectedId, setSelectedId] = useState("english");
  const [isRecentActiveProjects, setIsRecentActiveProjects] = useState(false);
  const [offset, setOffset] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isFetch, setisFetch] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [isGeneralLoader, setIsGeneralLoader] = useState(false);
  const [appName, setAppName] = useState();

  useEffect(() => {
    fetchUser();
    const storeAppname = JSON.parse(localStorage.getItem("appname"));
    setAppName(storeAppname);

    return () => {
      setIsEnabledPushNotification(false);
      setIsAllowedAddPastDate(false);
    };
  }, []);

  const handleChange = ({ label, value, data }) => {
    // console.log(item, "yyyyyyyyyy");
    // let sort = countryList.filter((data) => {
    //   return data.flag == item.flag;
    // });
    setCountryListValue(data);

    // console.log(sort, "yyyyyyyyyy");
    //  [countryList].map((data) => {
    //   setCountryListValue(
    //     data && data.filter((data) => data.dial_code == item.dial_code)
    //   );
    // });
  };

  const repeatTypeSelectStyle = {
    control: (base, state) => ({
      ...base,
      width: "100px",
      border: "0px solid #e6e6e6 ",
      background: "#f8f8f8",
      // match with the menu
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      // Overwrittes the different states of border
      borderColor: "#ebebeb",
      // Removes weird border around container
      boxShadow: null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "#ebebeb"
      }
    }),
    menu: base => ({
      ...base,
      width: "max-content",
      minWidth: "180px",
      marginTop: "14px",
      // override border radius to match the box
      borderRadius: 5
      // kill the gap
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? topbarTheme.buttonColor
          : isFocused
          ? `${topbarTheme.buttonColor}30`
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast("grey", "white") > 2
            ? "white"
            : "black"
          : "#808080",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6")
        }
      };
    },
    singleValue: styles => ({ ...styles, color: "black" })
  };

  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  // const showNotifications = message => {
  //   addToast(ToastMessage(message), {
  //     appearance: message ? "success" : "error",
  //     autoDismiss: true
  //   });
  // setToast(true);
  // setToastMessage(message ? message : "something went wrong");
  // setTimeout(() => {
  //   setToast(false);
  //   setToastMessage("");
  // }, 2500);
  // };
  const fetchUser = async () => {
    setisFetch(true);
    setIsGeneralLoader(true);
    setIsLoader(true);
    try {
      const Response = await getUser();
      if (Response) {
        if (Response.error) {
          addToast(ToastMessage(Response.error.message), {
            appearance: "error",
            autoDismiss: true
          });
          const settingsData = props.authServices.user?.userSetting;
          if (settingsData) {
            // props.changeLanguage(settingsData?.language);
            setTimeFormat(settingsData.timeFormat);
            setDateFormat(settingsData.dateFormat);
            setTimezone(settingsData.timezone);
            // setLanguage(settingsData.language);
            setTheme(settingsData.theme);
            setIsAllowedAddPastDate(settingsData.AllowAddPreviousDateTask);
            setIsEnabledPushNotification(settingsData.enablePushNotifications);
            setIsGeneralLoader(false);
            setisFetch(false);
            setIsLoader(false);
          }
        } else {
          if (Response.user.userSetting) {
            const settingsData = Response.user.userSetting;
            if (settingsData) {
              setIsAllowedAddPastDate(settingsData.AllowAddPreviousDateTask);
              setIsEnabledPushNotification(
                settingsData.enablePushNotifications
              );
              props.changeLanguage(settingsData?.language);
              setTimeFormat(settingsData.timeFormat);
              setDateFormat(settingsData.dateFormat);
              setTimezone(settingsData.timezone);
              setLanguage(settingsData?.language);
              setTheme(settingsData.theme);
              setIsGeneralLoader(false);
              setisFetch(false);
              setIsLoader(false);
              if (settingsData.dashboardViewOptions) {
                const dashboardViewOptions = settingsData.dashboardViewOptions;
                setIsLabelAnalysis(dashboardViewOptions.labelAnalysis);
                setIsPriorityAnalysis(dashboardViewOptions.priorityAnalysis);
                setIsFavorites(dashboardViewOptions.favorites);
                setIsDueTaskTracker(dashboardViewOptions.dueTaskTracker);
                setIsAssignedToMe(dashboardViewOptions.assignedToMeTask);
                setIsAllTask(dashboardViewOptions.allTask);
                setIsRecentActiveProjects(dashboardViewOptions.recentActive);
              }

              // props.sidebarTransParent(
              //   settingsData.theme && settingsData.theme == "dark"
              //     ? "on"
              //     : "off"
              // );
              props.changeTheme(
                "sidebarTheme",
                settingsData.theme ? settingsData.theme : "light"
              );
              props.changeTheme(
                "layoutTheme",
                settingsData.theme ? settingsData.theme : "light"
              );
              props.changeTheme(
                "footerTheme",
                settingsData.theme ? settingsData.theme : "light"
              );
              props.changeTheme(
                "topbarTheme",
                settingsData.theme ? settingsData.theme : "light"
              );
            }
          } else {
            setIsGeneralLoader(false);
            setisFetch(false);
            setIsLoader(false);
          }
        }
      }
    } catch (e) {
      setIsGeneralLoader(false);
      setisFetch(false);
      setIsLoader(false);
    }
  };

  const { addToast } = useToasts();
  const handleLabelAnalysis = data =>
    handleDashboardUpdate({ labelAnalysis: data });

  const handlePriorityAnalysis = data =>
    handleDashboardUpdate({ priorityAnalysis: data });

  const handleDueTaskTracker = data =>
    handleDashboardUpdate({ dueTaskTracker: data });
  const handleAssignedToMeTask = data =>
    handleDashboardUpdate({ assignedToMeTask: data });
  const handleAllTask = data => handleDashboardUpdate({ allTask: data });
  const handleRecentActive = data =>
    handleDashboardUpdate({ recentActive: data });
  const handleThemeChange = data => {
    props.changeTheme("sidebarTheme", data);
    props.changeTheme("layoutTheme", data);
    props.changeTheme("footerTheme", data);
    props.changeTheme("topbarTheme", data);
    handleGeneralSubmit({
      theme: data,
      language: language,
      dashboardViewOptions: {
        labelAnalysis: isLabelAnalysis,
        priorityAnalysis: isPriorityAnalysis,
        dueTaskTracker: isdueTaskTracker,
        favorites: false,
        assignedToMeTask: isAssignedToMe,
        allTask: isAllTask,
        recentActive: isRecentActiveProjects
      }
    });
  };
  const handleDashboardUpdate = data => {
    handleGeneralSubmit({
      theme: theme,
      language: language,
      dashboardViewOptions: {
        labelAnalysis: isLabelAnalysis,
        priorityAnalysis: isPriorityAnalysis,
        dueTaskTracker: isdueTaskTracker,
        favorites: false,
        assignedToMeTask: isAssignedToMe,
        allTask: isAllTask,
        recentActive: isRecentActiveProjects,
        ...data
      }
    });
  };
  const handleGeneralSubmit = async reqObj => {
    if (language && theme) {
      setIsGeneralLoader(true);
      const Response = await updateUserSettings(reqObj);

      if (Response.errors) {
        addToast(ToastMessage(Response.error.message), {
          appearance: "error",
          autoDismiss: true
        });
        // showNotifications(Response.errors.message);
        setIsGeneralLoader(false);
      }
      if (Response.error) {
        addToast(ToastMessage(Response.error.message), {
          appearance: "error",
          autoDismiss: true
        });
        // showNotifications(Response.errors.message);
        setIsGeneralLoader(false);
      }
      if (Response.user) {
        props.update_Settings(Response.user.userSetting);

        setIsEnabledPushNotification(
          Response.user.userSetting.enablePushNotifications
        );
        setIsAllowedAddPastDate(
          Response.user.userSetting.AllowAddPreviousDateTask
        );
        if (Response.user.userSetting && Response.user.userSetting.theme) {
          const settingsData = Response.user.userSetting;
          props.sidebarTransParent(
            settingsData.theme && settingsData.theme == "dark" ? "on" : "off"
          );
          props.changeTheme(
            "sidebarTheme",
            settingsData.theme ? settingsData.theme : "light"
          );
          props.changeTheme(
            "layoutTheme",
            settingsData.theme ? settingsData.theme : "light"
          );
          props.changeTheme(
            "footerTheme",
            settingsData.theme ? settingsData.theme : "light"
          );
          props.changeTheme(
            "topbarTheme",
            settingsData.theme ? settingsData.theme : "light"
          );
        }

        showNotifications("General settings updated", "success");
        setIsGeneralLoader(false);
      }
    }
  };

  const handleOnChange = (value, data) => {
    setCounteriesNumber(
      value.replace(/[^0-9]+/g, "").slice(data.dialCode.length).length
    );
    if (
      value.replace(/[^0-9]+/g, "").slice(data.dialCode.length).length == 10
    ) {
      setIsDisabledButton(false);
    } else {
      setIsDisabledButton(true);
    }
  };

  const handleOtpValueChange = data => {
    setOtpValue(data);
    if (data.length == 6) {
      setIsOtpValueValid(false);
    } else {
      setIsOtpValueValid(true);
    }
  };

  const handleUpdateSettings = async e => {
    setIsLoader(true);
    e.preventDefault();
    let { values, handleSubmit } = props;

    if (timezone && dateformat && timeformat) {
      setIsLoader(true);

      const Response = await updateUserSettings({
        timezone: timezone,
        offset: parseInt(offset),
        dateFormat: dateformat,
        timeFormat: timeformat
      });

      if (Response.errors) {
        // addToast(ToastMessage(Response.error.message), {
        //   appearance: "error",
        //   autoDismiss: true
        // });
        showNotifications(Response.errors.message, "error");
        setIsLoader(false);
      }
      if (Response.error) {
        // addToast(ToastMessage(Response.error.message,), {
        //   appearance: "error",
        //   autoDismiss: true
        // });
        showNotifications(Response.error.message, "error");
        setIsLoader(false);
      }
      if (Response.user) {
        props.update_Settings(Response.user.userSetting);
        showNotifications("Date and time updated", "success");
        setIsLoader(false);
      }
    } else {
      setIsLoader(false);
      if (!timezone) {
        showNotifications("Please select Time zone", "success");
      }
      if (!dateformat) {
        showNotifications("Please select Date format", "success");
      }
      if (!timeformat) {
        showNotifications("Please select Time format", "success");
      }
    }
  };
  const getOffsetText = text => {
    const data = timezoneData.filter((data, i) => {
      if (data.text == text) {
        return data.offset;
      }
    });
    return data[0].offset;
  };
  const changeLanguageHandler = async id => {
    setSelectedId(id);
    props.changeLanguage(id);
    const payload = {
      language: id
    };
    const responce = await updateUserSettings(payload);

    if (id == "english") {
      showNotifications("General settings updated", "success");
    }

    if (id == "german") {
      showNotifications("Allgemeine Einstellungen aktualisiert", "success");
    }
    if (id == "dutch") {
      showNotifications("Algemene instellingen bijgewerkt", "success");
    }

    return responce;
  };

  return (
    <div className="row">
      <div
        className="col-lg-12 col-md-12 col-sm-62 col-xs-12"
        style={{ borderBottom: "1px solid #ebebeb" }}
      >
        <div className="roe-card-style">
          <div
            className="roe-card-header"
            style={{
              height: "60px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "white"
            }}
          >
            <div
              className="file-manager-toolbar flex-x"
              style={{ width: "100%" }}
            >
              <div
                className="flex-1"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span
                  className="fs-15 demi-bold-text"
                  style={{
                    fontWeight: 600,
                    color: "black",
                    marginLeft: "60px"
                  }}
                >
                  {<IntlMessages id={"setting.settings"} />}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-lg-2 col-md-2 col-sm-0 col-xs-0"
        // style={{ padding: "35px 0px 0px 0px" }}
      />
      <div
        className="col-lg-8 col-md-8 col-sm-62 col-xs-12"
        style={{ padding: "0px 0px 0px 0px", minHeight: "87vh" }}
      >
        <div className="plr-0">
          <div className="mb-0 theme-color">
            {/* <div className="introduction"
              style={{
                height: "70px",
                display: "flex",justifyContent:"center",
                alignItems: "center",
                backgroundColor: "white"
              }}
            >General</div> */}

            <div>
              {/* change class name up  className="col-12 col-sm-62 col-md-12 col-lg-12 col-xl-12 ptb-15 roe-card-style " */}
              {/* <div className="form-group">
                <label className=""> Language</label>
                <select
                  type="text"
                  className="form-control react-form-input selectpicker"
                  value={language}
                  onChange={e => setLanguage(e.target.value)}
                  style={{
                    height: 35,
                    paddding: 0,
                    paddingLeft: 20
                  }}
                >
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                  <option value="gujarati">Gujarati</option>
                </select>
              </div> */}
              {/* <div className="message-card roe-shadow-2 fill-height flex-y"> */}
              {/* classname change up className="form-group  " */}
              {/* <label
                  className="fs-14 pa-15"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}
                >
                  Dashboard View Options
                </label> */}

              {/* <table>
                    <tr>
                      <td>
                        <label>Lablel analysis</label>
                      </td>
                      <td>
                        <div className="pretty p-switch p-fill pa-20 ">
                          <input
                            type="checkbox"
                            name="labelanalysis"
                            id="labelanalysis"
                            checked={isLabelAnalysis}
                            onChange={e => setIsLabelAnalysis(e.target.checked)}
                          />
                          <div className="state">
                            <label></label>
                          </div>
                        </div>
                      </td>

                      <td>
                        <label>Priority analysis </label>
                      </td>
                      <td>
                        <div className="pretty p-switch p-fill pa-20">
                          <input
                            type="checkbox"
                            name="priorityanalysis"
                            id="priorityanalysis"
                            checked={isPriorityAnalysis}
                            onChange={e =>
                              setIsPriorityAnalysis(e.target.checked)
                            }
                          />
                          <div className="state">
                            <label></label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Due task tracker</label>
                      </td>
                      <td>
                        <div className="pretty p-switch p-fill pa-20 ">
                          <input
                            type="checkbox"
                            name="duetasktracker"
                            id="duetasktracker"
                            checked={isdueTaskTracker}
                            onChange={e =>
                              setIsDueTaskTracker(e.target.checked)
                            }
                          />
                          <div className="state">
                            <label></label>
                          </div>
                        </div>
                      </td>
                      <td>
                        {" "}
                        <label>Favourites</label>
                      </td>
                      <td>
                        <div className="pretty p-switch p-fill pa-20">
                          <input
                            type="checkbox"
                            name="favorites"
                            id="favorites"
                            checked={isFavorites}
                            onChange={e => setIsFavorites(e.target.checked)}
                          />
                          <div className="state">
                            <label></label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Assigned to me task</label>
                      </td>
                      <td>
                        <div className="pretty p-switch p-fill pa-20 ">
                          <input
                            type="checkbox"
                            name="assignedtometasks"
                            id="assignedtometasks"
                            checked={isAssignedToMe}
                            onChange={e => setIsAssignedToMe(e.target.checked)}
                          />
                          <div className="state">
                            <label></label>
                          </div>
                        </div>
                      </td>

                      <td>
                        <label>All Task</label>
                      </td>
                      <td>
                        <div className="pretty p-switch p-fill pa-20">
                          <input
                            type="checkbox"
                            name="alltasks"
                            id="alltasks"
                            checked={isAllTask}
                            onChange={e => setIsAllTask(e.target.checked)}
                          />
                          <div className="state">
                            <label></label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                   */}
              <div className={"row ml-1"}>
                {/* <div className={"col-lg-6 col-md-6 col-sm-62 col-xs-12"}>
                    <table>
                      <tr>
                        <td style={{ width: "100%" }}>
                          <label className="pt-2 text-muted">
                            Label Analysis
                          </label>
                        </td>
                        <td>
                          <div className="pretty p-switch p-fill pa-20 ">
                            <input
                              type="checkbox"
                              name="labelanalysis"
                              id="labelanalysis"
                              checked={isLabelAnalysis}
                              onChange={e => {
                                setIsLabelAnalysis(e.target.checked);
                                handleLabelAnalysis(e.target.checked);
                              }}
                            />
                            <div className="state">
                              <label></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className={"col-lg-6 col-md-6 col-sm-62 col-xs-12"}>
                    <table>
                      <tr>
                        <td style={{ width: "100%" }}>
                          <label className="pt-2 text-muted">
                            Priority Analysis{" "}
                          </label>
                        </td>
                        <td>
                          <div className="pretty p-switch p-fill pa-20">
                            <input
                              type="checkbox"
                              name="priorityanalysis"
                              id="priorityanalysis"
                              checked={isPriorityAnalysis}
                              onChange={e => {
                                setIsPriorityAnalysis(e.target.checked);
                                handlePriorityAnalysis(e.target.checked);
                              }}
                            />
                            <div className="state">
                              <label></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div> */}

                {/* <div className={"col-lg-6 col-md-6 col-sm-62 col-xs-12"}>
                      <table>
                        <tr>
                          <td style={{ width: "100%" }}>
                            <label className="pt-2 text-muted">
                              Favourites
                            </label>
                          </td>
                          <td>
                            <div className="pretty p-switch p-fill pa-20">
                              <input
                                type="checkbox"
                                name="favorites"
                                id="favorites"
                                checked={isFavorites}
                                onChange={e => setIsFavorites(e.target.checked)}
                              />
                              <div className="state">
                                <label></label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div> */}
                {/* <div className={"col-lg-6 col-md-6 col-sm-62 col-xs-12"}>
                    <table>
                      <tr>
                        <td style={{ width: "100%" }}>
                          {" "}
                          <label className="pt-2 text-muted">
                            Due Task Tracker
                          </label>
                        </td>
                        <td>
                          <div className="pretty p-switch p-fill pa-20 ">
                            <input
                              type="checkbox"
                              name="duetasktracker"
                              id="duetasktracker"
                              checked={isdueTaskTracker}
                              onChange={e => {
                                setIsDueTaskTracker(e.target.checked);
                                handleDueTaskTracker(e.target.checked);
                              }}
                            />
                            <div className="state">
                              <label></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  */}
                {/* <div className={"  col-lg-6 col-md-6 col-sm-62 col-xs-12"}>
                      <table>
                        <tr>
                          <td style={{ width: "100%" }}>
                            <label className="pt-2 text-muted">
                              {" "}
                              Assigned to me task
                            </label>
                          </td>
                          <td>
                            <div className="pretty p-switch p-fill pa-20 ">
                              <input
                                type="checkbox"
                                name="assignedtometasks"
                                id="assignedtometasks"
                                checked={isAssignedToMe}
                                onChange={e =>
                                  setIsAssignedToMe(e.target.checked)
                                }
                              />
                              <div className="state">
                                <label></label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div> */}
                {/* <div className={"col-lg-6 col-md-6 col-sm-62 col-xs-12"}>
                      <table>
                        <tr>
                          <td style={{ width: "100%" }}>
                            <label className="pt-2 text-muted">All Task</label>
                          </td>
                          <td>
                            <div className="pretty p-switch p-fill pa-20">
                              <input
                                type="checkbox"
                                name="alltasks"
                                id="alltasks"
                                checked={isAllTask}
                                onChange={e => setIsAllTask(e.target.checked)}
                              />
                              <div className="state">
                                <label></label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div> */}
                {/* <div className={"col-lg-6 col-md-6 col-sm-62 col-xs-12"}>
                    <table>
                      <tr>
                        <td style={{ width: "100%" }}>
                          <label className="pt-2 text-muted">
                            Recent Active Projects
                          </label>
                        </td>
                        <td>
                          <div className="pretty p-switch p-fill pa-20">
                            <input
                              type="checkbox"
                              name="recentactiveProjects"
                              id="recentactiveProjects"
                              checked={isRecentActiveProjects}
                              onChange={e => {
                                setIsRecentActiveProjects(e.target.checked);
                                handleRecentActive(e.target.checked);
                              }}
                            />
                            <div className="state">
                              <label></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                 */}
              </div>
              {/* </div> */}

              <div
                className="message-card flex-y"
                style={{
                  marginTop: "40px",
                  background: "#FFFFFF",
                  // marginLeft: "60px",
                  // marginRight: "60px",
                  // height: "556px",
                  borderRadius: "6px"
                }}
              >
                {/* classname change up className="form-group  " */}
                <label
                  className="fs-14 pa-15"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px 10px 0px 10px",
                    borderBottom: "1px solid #EBEBEB"
                  }}
                >
                  {<IntlMessages id={"setting.themes"} />}
                </label>

                <div className="row" style={{ padding: "0px 35px 35px 35px" }}>
                  <div
                    className={" col-sm-6 col-md-4 col-lg-2 col-xl-2 col-6"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "35px 0px 0px 0px"
                    }}
                  >
                    {/* "#ff5722" */}
                    <ThemeSelect
                      selected={theme == "rackley" ? true : false}
                      themeName={"Rackley"}
                      color={"#0073E6"}
                      changeTheme={theme => {
                        setTheme("rackley");
                        handleThemeChange("rackley");
                      }}
                    />
                  </div>
                  <div
                    className={" col-sm-6 col-md-4 col-lg-2 col-xl-2 col-6"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "35px 0px 0px 0px"
                    }}
                  >
                    <ThemeSelect
                      selected={theme == "beer" ? true : false}
                      themeName={"Beer"}
                      color={"#FE8E26"}
                      changeTheme={theme => {
                        setTheme("beer");
                        handleThemeChange("beer");
                      }}
                    />
                  </div>

                  <div
                    className={" col-sm-6 col-md-4 col-lg-2 col-xl-2 col-6"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "35px 0px 0px 0px"
                    }}
                  >
                    <ThemeSelect
                      selected={theme == "tomato" ? true : false}
                      themeName={"Tomato"}
                      color={"#FF6348"}
                      changeTheme={theme => {
                        setTheme("tomato");
                        handleThemeChange("tomato");
                      }}
                    />
                  </div>
                  <div
                    className={" col-sm-6 col-md-4 col-lg-2 col-xl-2 col-6"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "35px 0px 0px 0px"
                    }}
                  >
                    <ThemeSelect
                      selected={theme == "slate_gray" ? true : false}
                      themeName={"Slate Gray"}
                      color={"#747D8C"}
                      changeTheme={theme => {
                        setTheme("slate_gray");
                        handleThemeChange("slate_gray");
                      }}
                    />
                  </div>

                  <div
                    className={" col-sm-6 col-md-4 col-lg-2 col-xl-2 col-6"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "35px 0px 0px 0px"
                    }}
                  >
                    {/* #b388ff */}
                    <ThemeSelect
                      selected={theme == "dark_gunmetal" ? true : false}
                      themeName={"Dark Gunmetal"}
                      color={"#222436"}
                      changeTheme={theme => {
                        setTheme("dark_gunmetal");
                        handleThemeChange("dark_gunmetal");
                      }}
                    />
                  </div>
                  <div
                    className={" col-sm-6 col-md-4 col-lg-2 col-xl-2 col-6"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "35px 0px 0px 0px"
                    }}
                  >
                    <ThemeSelect
                      selected={theme == "royal_purple" ? true : false}
                      themeName={"Royal Purple"}
                      color={"#735D9F"}
                      changeTheme={theme => {
                        setTheme("royal_purple");
                        handleThemeChange("royal_purple");
                      }}
                    />
                  </div>

                  <div
                    className={" col-sm-6 col-md-4 col-lg-2 col-xl-2 col-6"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "35px 0px 0px 0px"
                    }}
                  >
                    {/* "#ff5722" */}
                    <ThemeSelect
                      selected={theme == "deep_puce" ? true : false}
                      themeName={"Deep Puce"}
                      color={"#A75F5F"}
                      changeTheme={theme => {
                        setTheme("deep_puce");
                        handleThemeChange("deep_puce");
                      }}
                    />
                  </div>
                  <div
                    className={" col-sm-6 col-md-4 col-lg-2 col-xl-2 col-6"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "35px 0px 0px 0px"
                    }}
                  >
                    <ThemeSelect
                      selected={theme == "tufts_blue" ? true : false}
                      themeName={"Tufts Blue"}
                      color={"#4394EF"}
                      changeTheme={theme => {
                        setTheme("tufts_blue");
                        handleThemeChange("tufts_blue");
                      }}
                    />
                  </div>

                  <div
                    className={" col-sm-6 col-md-4 col-lg-2 col-xl-2 col-6"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "35px 0px 0px 0px"
                    }}
                  >
                    <ThemeSelect
                      selected={theme == "caribbean" ? true : false}
                      themeName={"Caribbean"}
                      color={"#0EB998"}
                      changeTheme={theme => {
                        setTheme("caribbean");
                        handleThemeChange("caribbean");
                      }}
                    />
                  </div>
                  <div
                    className={" col-sm-6 col-md-4 col-lg-2 col-xl-2 col-6"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "35px 0px 0px 0px"
                    }}
                  >
                    <ThemeSelect
                      selected={theme == "turquoise" ? true : false}
                      themeName={"Turquoise"}
                      color={"#05C0C6"}
                      changeTheme={theme => {
                        setTheme("turquoise");
                        handleThemeChange("turquoise");
                      }}
                    />
                  </div>

                  <div
                    className={" col-sm-6 col-md-4 col-lg-2 col-xl-2 col-6"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "35px 0px 0px 0px"
                    }}
                  >
                    {/* #b388ff */}
                    <ThemeSelect
                      selected={theme == "coral_reef" ? true : false}
                      themeName={"Coral Reef"}
                      color={"#FF7675"}
                      changeTheme={theme => {
                        setTheme("coral_reef");
                        handleThemeChange("coral_reef");
                      }}
                    />
                  </div>
                  <div
                    className={" col-sm-6 col-md-4 col-lg-2 col-xl-2 col-6"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "35px 0px 0px 0px"
                    }}
                  >
                    <ThemeSelect
                      selected={theme == "fuchsia_rose" ? true : false}
                      themeName={"Fuchsia Rose"}
                      color={"#C44569"}
                      changeTheme={theme => {
                        setTheme("fuchsia_rose");
                        handleThemeChange("fuchsia_rose");
                      }}
                    />
                  </div>
                </div>

                {/* 
                  <div
                    className={" col-sm-6 col-md-6 col-lg-6 col-xl-6 xs-12"}
                    style={{ padding: "0px 30px 0px 5px" }}
                  >
                    <ThemeSelect
                      selected={theme == "sapphire" ? true : false}
                      themeName={"Sapphire"}
                      color={"#747D8C"}
                      changeTheme={theme => {
                        setTheme(theme);
                        handleThemeChange(theme);
                      }}
                    />
                  </div>
                  <div
                    className={" col-sm-6 col-md-6 col-lg-6 col-xl-6 xs-12"}
                    style={{ padding: "0px 5px 0px 30px" }}
                  >
                    <ThemeSelect
                      selected={theme == "dark" ? true : false}
                      themeName={"Dark"}
                      color={"#222436"}
                      changeTheme={theme => {
                        setTheme(theme);
                        handleThemeChange(theme);
                      }}
                    />
                  </div>

                  <div
                    className={" col-sm-6 col-md-6 col-lg-6 col-xl-6 xs-12"}
                    style={{ padding: "0px 30px 0px 5px" }}
                  >
                    <ThemeSelect
                      selected={theme == "light" ? true : false}
                      themeName={"light"}
                      color={"#794e6e"}
                      changeTheme={theme => {
                        setTheme(theme);
                        handleThemeChange(theme);
                      }}
                    />
                  </div> */}
              </div>
              {/* </div> */}

              {/* <div
                className="message-card flex-y"
                style={{
                  marginTop: "40px",
                  background: "#FFFFFF",
               
                  borderRadius: "6px"
                }}
              >
                <label
                  className="fs-14 pa-15"
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                    color: "black",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px 10px 0px 10px",
                    borderBottom: "1px solid #EBEBEB"
                  }}
                >
                  Integrations
                </label>

                {isCounteries == "1" ? (
                  <>
                    <div style={{ padding: "20px 40px" }}>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "black",
                          padding: "0px 0px 15px 0px"
                        }}
                      >
                        Get updates of your tasks over WhatsApp by enabling the
                        setting.
                      </div>
                      <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <Switch
                          checked={isCounteriesSwitch}
                          onChange={() => {
                            setIsCounteriesSwitch(false);
                            setIsCounteries("2");
                          }}
                          handleDiameter={13}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={19}
                          width={32}
                          className="react-switch"
                          name="addtoFavorite"
                          id="addtoFavorite"
                          onColor={topbarTheme.buttonColor}
                        />
                        <div
                          style={{
                            fontSize: "14px",
                            color: "black",
                            paddingLeft: "10px"
                          }}
                        >
                          Allow updates in WhatsApp
                        </div>
                      </div>
                    </div>
                  </>
                ) : isCounteries == 2 ? (
                  <>
                    <div style={{ padding: "20px 40px" }}>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "black"
                        }}
                      >
                        To receive updates through WhatsApp, please verify your
                        number first.
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            margin: "20px 0px 0px 0px ",
                            fontSize: "14px",
                            color: "black",
                            borderRadius: "10px",
                            border: "1px solid #e6e6e6 "
                          }}
                        >
                          <PhoneInput
                            country={"in"}
                            enableSearch
                            style={{ width: "100%" }}
                            onlyCountries={["in", "us", "ca", "au", "gb"]}
                            placeholder="+91 12345-67890"
                            onChange={(value, data) => {
                              handleOnChange(value, data);
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                          className="btn "
                          disabled={isDisabledButton}
                          onClick={() => {
                            setIsCounteries("3");
                          }}
                          style={{
                            padding: "8px 10px ",
                            borderRadius: "10px",
                            color: "white",
                            backgroundColor: `${topbarTheme.buttonColor}`,
                            margin: "20px 0px 0px 0px "
                          }}
                        >
                          Send OTP
                        </button>

                        <button
                          className="btn "
                          onClick={() => {
                            setIsCounteries("1");
                            setIsDisabledButton(true);
                          }}
                          style={{
                            padding: "8px 10px ",
                            borderRadius: "10px",
                            color: "black",
                            backgroundColor: `#f4f5f7`,
                            margin: "20px 0px 0px 10px ",
                            minWidth: "75px"
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </>
                ) : isCounteries == 3 ? (
                  <div style={{ padding: "20px 40px" }}>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "black"
                      }}
                    >
                      OTP has been sent to +91 XXXXXXXXXXX number.
                    </div>
                    <div style={{ display: "flex" }}>
                      <OtpInput
                        shouldAutoFocus={true}
                        value={otpValue}
                        onChange={e => {
                          handleOtpValueChange(e);
                        }}
                        numInputs={6}
                        inputStyle={{
                          width: "35px",
                          height: "35px",
                          borderRadius: 10,
                          border: "1px solid #c3c3c3",
                          outline: "none",
                          margin: "20px 0px 0px 0px "
                        }}
                        separator={<span>&nbsp;&nbsp;</span>}
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <button
                        className="btn "
                        onClick={() => {
                          setIsCounteries("1");
                          setIsCounteriesSwitch(true);
                        }}
                        disabled={isOtpValueValid}
                        style={{
                          padding: "8px 10px ",
                          borderRadius: "10px",
                          color: "white",
                          backgroundColor: `${topbarTheme.buttonColor}`,
                          margin: "20px 0px 0px 0px ",
                          minWidth: 75
                        }}
                      >
                        Verify
                      </button>
                      <div
                        onClick={() => {
                          setIsCounteries("2");
                          setIsDisabledButton(true);
                        }}
                        style={{
                          color: " #808080",
                          margin: "20px 0px 0px 10px ",
                          display: "flex",
                          alignItems: "center",
                          fontSize: 14,
                          cursor: "pointer"
                        }}
                      >
                        Change Number
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div> */}

              <div
                className="message-card flex-y"
                style={{
                  marginTop: "40px",
                  background: "#FFFFFF",
                  // marginLeft: "60px",
                  // marginRight: "60px",
                  // height: "556px",
                  borderRadius: "6px"
                }}
              >
                {/* classname change up className="form-group  " */}
                <label
                  className="fs-14 pa-15"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px 10px 0px 10px",
                    borderBottom: "1px solid #EBEBEB"
                  }}
                >
                  {<IntlMessages id={"setting.notifications"} />}
                </label>
                <div style={{ padding: "20px 40px" }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "black",
                      padding: "0px 0px 15px 0px"
                    }}
                  >
                    {<IntlMessages id={"setting.toggledecription1"} />}&nbsp;
                    {appName?.data?.appName}
                  </div>
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <Switch
                      checked={isEnabledPushNotification || false}
                      onChange={() => {
                        if (!isEnabledPushNotification) {
                          Notification.requestPermission().then(function(
                            permission
                          ) {});

                          if (Notification.permission !== "granted") {
                            Notification.requestPermission();
                          }
                        }
                        handleGeneralSubmit({
                          enablePushNotifications: !isEnabledPushNotification
                        });
                      }}
                      // onColor={topbarTheme.buttonColor}
                      // onHandleColor="#2693e6"
                      handleDiameter={13}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={19}
                      width={32}
                      className="react-switch"
                      name="addtoFavorite"
                      id="addtoFavorite"
                      onColor={topbarTheme.buttonColor}
                    />
                    <div
                      style={{
                        fontSize: "14px",
                        color: "black",
                        paddingLeft: "10px"
                      }}
                    >
                      {<IntlMessages id={"setting.toggledecription2"} />}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="message-card flex-y"
                style={{
                  marginTop: "40px",
                  marginBottom: "70px",
                  background: "#FFFFFF",
                  // marginLeft: "60px",
                  // marginRight: "60px",
                  // height: "556px",
                  borderRadius: "6px"
                }}
              >
                {/* classname change up className="form-group  " */}
                <label
                  className="fs-14 pa-15"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px 10px 0px 10px",
                    borderBottom: "1px solid #EBEBEB"
                  }}
                >
                  {<IntlMessages id={"setting.general"} />}
                </label>
                <div style={{ padding: "20px 40px" }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "black",
                      padding: "0px 0px 15px 0px"
                    }}
                  >
                    {<IntlMessages id={"setting.toggledecription3"} />}
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "black",
                      padding: "0px 0px 0px 0px"
                    }}
                  ></div>
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <Switch
                      onColor={topbarTheme.buttonColor}
                      checked={isAllowedAddPastDate}
                      onChange={() => {
                        handleGeneralSubmit({
                          AllowAddPreviousDateTask: !isAllowedAddPastDate
                        });
                        setIsAllowedAddPastDate(!isAllowedAddPastDate);
                      }}
                      handleDiameter={13}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={19}
                      width={32}
                      className="react-switch"
                      name="allowPastDate"
                      id="allowPastDate"
                    />
                    <div
                      style={{
                        fontSize: "14px",
                        color: "black",
                        paddingLeft: "10px"
                      }}
                    >
                      {<IntlMessages id={"setting.toggledecription4"} />}
                    </div>
                  </div>
                </div>
                <LanguageSwitcher
                  config={config}
                  selectedId={selectedId}
                  changeLanguage={changeLanguageHandler}
                  language={language}
                  setLanguage={setLanguage}
                  props={props}
                />
              </div>

              {/* <div
                className="message-card flex-y"
                style={{
                  marginTop: "40px",
                  background: "#FFFFFF",
                  borderRadius: "6px",
                  marginBottom: "70px"
                }}
              >
                
                <label
                  className="fs-14 pa-15"
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                    color: "black",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px 10px 0px 10px",
                    borderBottom: "1px solid #EBEBEB"
                  }}
                >
                  Date & time
                </label>
                <div style={{ padding: "20px 40px" }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "black",
                      padding: "0px 0px 5px 0px"
                    }}
                  >
                    TimeZone
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "black",
                      padding: "0px 0px 0px 0px"
                    }}
                  ></div>
                 
                  <TimeZoneWrapper>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #e6e6e6",
                        borderRadius: "5px",
                        paddingRight: "5px"
                      }}
                    >
                      <select
                        id="timezone"
                        onChange={e => {
                          setTimezone(e.target.value);
                          setOffset(getOffsetText(e.target.value));
                        }}
                        value={timezone}
                        className="form-control react-form-input selectpicker"
                        data-style="btn-new"
                      >
                        <option value={""}>Select time zone</option>
                        {timezoneData.map((e, i) => {
                          return (
                            <option key={i} value={e.text}>
                              {e.text}
                            </option>
                          );
                        })}
                      </select>
                      <img
                        className=""
                        src={SidebarDownArrow}
                        style={{ paddingRight: "8px" }}
                      />
                    </div>
                  </TimeZoneWrapper>
                </div>
              </div> */}

              {/* <div className={"col-lg-6 col-md-6 col-sm-62 col-xs-12"}>
                  <div className={"row"}>
                    <div className={"col-lg-6 col-md-6 col-sm-62 col-xs-12"}>
                      <label>Add to favorite</label>
                    </div>
                    <div className={"col-lg-6 col-md-6 col-sm-62 col-xs-12"}>
                      <div className="pretty p-switch p-fill pa-20">
                        <input
                          type="checkbox"
                          name="addtoFavorite"
                          id="addtoFavorite"
                        />
                        <div className="state">
                          <label></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 */}
              {/* {isGeneralLoader ? (
                  <Loader height="25px" width="20px" />
                ) : (
                  <button type="submit" className="button btn">
                    update
                  </button>
                )} */}
            </div>
          </div>
          {/* <div className="mb-10 theme-color">
            <div className="introduction">Date & Time</div>
            <div className="col-12 col-sm-62 col-md-12 col-lg-4 col-xl-12 ptb-15 roe-card-style">
              <form onSubmit={handleUpdateSettings}>
                <div className="form-group">
                  <label>Time Zone</label>
                  <select
                    id="timezone"
                    onChange={(e) => {
                      setTimezone(e.target.value);
                      setOffset(getOffsetText(e.target.value));
                    }}
                    value={timezone}
                    className="form-control react-form-input selectpicker"
                    data-style="btn-new"
                    style={{
                      height: 35,
                      padddingTop: "0.275rem !important",
                      paddingBottom: "0.275rem !important",
                    }}
                  >
                    <option value={""}>Select time zone</option>
                    {timezoneData.map((e, i) => {
                      return (
                        <option key={i} value={e.text}>
                          {e.text}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <label>Date Format</label>
                  <select
                    className="form-control react-form-input selectpicker"
                    data-style="btn-new"
                    id="dateformat"
                    value={dateformat}
                    onChange={(event) => setDateFormat(event.target.value)}
                    style={{
                      height: 35,
                      padddingTop: "0.275rem",
                      paddingBottom: "0.275rem",
                    }}
                  >
                    <option value={""}>Select date format</option>
                    <option value="DD-MM-YYYY">DD-MM-YYYY</option>
                    <option value="MM-DD-YYYY">MM-DD-YYYY</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Time Format</label>
                  <select
                    id="timeformat"
                    className="form-control react-form-input selectpicker"
                    data-style="btn-new"
                    onChange={(event) => setTimeFormat(event.target.value)}
                    value={timeformat || ""}
                    style={{
                      height: 35,
                      padddingTop: "0.275rem",
                      paddingBottom: "0.275rem",
                    }}
                  >
                    <option value={""}>select time format</option>
                    <option value="12h">12h</option>
                    <option value="24h">24h</option>
                  </select>
                </div>
                {isLoader ? (
                  <Loader height="25px" width="20px" />
                ) : (
                  <button type="submit" className="button btn">
                    update
                  </button>
                )}
              </form>
            </div>
          </div> */}
        </div>
        <CustomNotification
          show={toast}
          message={toastMessage}
          closeNotification={() => setToast(false)}
        />
      </div>
      <div
        className="col-lg-2 col-md-2 col-sm-0 col-xs-0"
        // style={{ padding: "35px 0px 0px 0px" }}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    language: { ...state.LanguageSwitcher }
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    update_Settings,
    changeTheme,
    sidebarTransParent,
    changeLanguage
  })
)(Settings);
