import React from "react";
import LabelListing from "components/Labels/LabelListing";
import {
  commentnew,
  threeDot_Icon,
  datesnewmodal,
  ListGridCheckImage,
  ListGridCircleImage
} from "helper/constant";
import { getPriorityPath, getPriorityValue } from "constatnt";
import moment from "moment";
import profileModelAction from "redux/profileModel/actions";
import { onChangeTaskAssign } from "helper/methods/QuickActions";
import { ReactComponent as RepeatSvgIcon } from "assets/images/recurrenceIcon.svg";
import { repeatTypeOptions } from "util/data/taskDetailsutilData";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import AssigneeDropdown from "components/newTask/AssigneeDropdown";
import DatePickerNoInput from "components/DatePickers/DatePickerNoInput";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
const { open_profile_modal, close_profile_modal } = profileModelAction;
const SingleTaskView = props => {
  const {
    data,
    updateisCompleted,
    index,
    doOpenTaskDetail,
    isQuickActionTask,
    doCloseQuickAction,
    doUpdateTaskScheduleDate,
    doUpdateTaskAssign,
    toggleTaskMenu,
    todoLength
  } = props;

  const getTodoStyle = (id, index, data) => {
    let style = {
      display: "flex",
      alignItems: "flex-start",
      borderTopRightRadius: index == 0 ? "10px" : "0px",
      borderTopLeftRadius: index == 0 ? "10px" : "0px",
      borderBottomRightRadius: data.length - 1 == index ? "10px" : "0px",
      borderBottomLeftRadius: data.length - 1 == index ? "10px" : "0px",
      paddingTop: index == 0 ? "20px" : "0px",
      paddingBottom: todoLength - 1 == index ? "20px" : "0px"
    };
    if (isSingleTask()) {
      style = { ...style, borderRadius: 10 };
    }
    if (id == isQuickActionTask.taskId && isQuickActionTask.status) {
      style = {
        ...style,
        boxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        webkitBoxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        mozBoxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        border: "1px solid lightgray",
        transition: "box-shadow 0.3s ease-in-out"
      };
    }
    return style;
  };
  const isSingleTask = () => todoLength == 1;
  return (
    <div>
      <div
        key={index}
        className="flex-x my-todo align-center plr-30 taskHover "
        style={getTodoStyle(data.id, index, data)}
      >
        <div
          className="flex-1"
          style={{
            cursor: "pointer",
            paddingBottom: todoLength - 1 == index ? "0px" : "10px",
            paddingTop: index == 0 ? "0px" : "10px",
            borderBottom:
              todoLength - 1 == index || todoLength == 1
                ? ""
                : "1px solid #ebebeb"
          }}
        >
          <div style={{ display: "flex" }}>
            <div className="flex-x" style={{ marginTop: 5 }}>
              <img
                alt=""
                onClick={e =>
                  updateisCompleted(
                    data?.project?.id || "",
                    data.id,
                    !data.isCompleted,
                    data
                  )
                }
                src={
                  data.isCompleted ? ListGridCheckImage : ListGridCircleImage
                }
                style={{
                  height: 18,
                  width: 18,
                  marginRight: 10
                }}
              />
            </div>
            <div className="flex-x flex-1 flex-wrap align-center fs-14">
              <span
                title={data.title}
                className="All_Task_Title fs-14"
                style={{
                  maxWidth: "50%",
                  color: "black",
                  fontSize: "14px",
                  margin: "3px 25px  3px 0px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  lineClamp: 1,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  WebkitLineClamp: 1,
                  WebkitFontSmoothing: "antialiased"
                }}
                onClick={() => {
                  doOpenTaskDetail(data);
                }}
              >
                {data.title}
              </span>
              {data && data.priority && data.priority != "no_priority" && (
                <div
                  style={{ margin: "3px 25px  3px 0px" }}
                  // id="PUncontrolledPopover"
                  className={
                    "my-day-label flex-x  fs-14 nevy--text align-center overflow-hidden"
                  }
                  onClick={() => {
                    doOpenTaskDetail(data);
                  }}
                >
                  {data.priority !== "no_priority" && (
                    <>
                      <img
                        src={getPriorityPath(data.priority)}
                        height="17"
                        width="18"
                        className=""
                        alt="Priority"
                        style={{
                          position: "relative"
                          // top: "2px"
                        }}
                      />
                      <span
                        style={{
                          cursor: "pointer",
                          paddingLeft: "3px ",
                          color: "black",
                          position: "relative",
                          top: "-1px"
                        }}
                      >
                        {getPriorityValue(data.priority)}
                      </span>
                    </>
                  )}
                </div>
              )}
              <div
                style={{ margin: "3px 25px  3px 0px" }}
                className={`my-day-label flex-x  fs-14 nevy--text align-center overflow-hidden ${
                  data.labels && data.labels?.length ? "label" : ""
                }`}
                onClick={() => {
                  doOpenTaskDetail(data);
                }}
              >
                <LabelListing labelsArr={data.labels} />
                {/* <index
                          className="fa fa-comment-dots"
                          style={{ color: "grey",border:"1px solid red" }}
                        /> */}

                <img
                  alt=""
                  src={commentnew}
                  style={{
                    height: "14px",
                    width: "14px",
                    marginRight: "0px",
                    marginTop: "0px",
                    textAlign: "center",
                    position: "relative",
                    top: "1px"
                  }}
                ></img>
                <span style={{ color: "grey", paddingLeft: 5 }}>
                  {data.commentCount ? data.commentCount : 0}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "3px 0px  3px 0px"
                }}
                onClick={() => {
                  doOpenTaskDetail(data);
                }}
              >
                {data.scheduledAt && (
                  <img
                    alt=""
                    src={datesnewmodal}
                    height="15px"
                    width="15px"
                    style={{ marginRight: 5 }}
                  />
                )}
                {data.scheduledAt &&
                  data.repeatType !== repeatTypeOptions[0].value && (
                    <RepeatSvgIcon
                      style={{
                        fill: "green",
                        height: "16px",
                        width: "15px",
                        marginRight: 5
                      }}
                    />
                  )}
                {data.scheduledAt && (
                  <div
                    className="due-date label"
                    style={{
                      marginRight: 25
                    }}
                  >
                    <IntlMessages id={"task-details.dueon"} />
                    &nbsp;
                    {moment(new Date(data.scheduledAt)).format("DD MMM YYYY")}
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "contents",
                  alignItems: "center"
                }}
              >
                {data.taskAssign && data.taskAssign.length > 0 && (
                  <>
                    <div
                      style={{
                        display: "contents",
                        alignItems: "center"
                      }}
                    >
                      {data.taskAssign &&
                        data.taskAssign.map(ta => {
                          return (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "3px 10px  3px 0px"
                                }}
                                onClick={() => {
                                  props.open_profile_modal({ profile: ta });
                                }}
                              >
                                {ta.thumbnail ? (
                                  <img
                                    alt=""
                                    title={ta.name}
                                    id="userAssigned"
                                    src={ta.thumbnail ? ta.thumbnail : null}
                                    style={{
                                      height: 20,
                                      width: 20,
                                      borderRadius: "50%",
                                      border: "1px solid white",
                                      objectFit: "cover"
                                    }}
                                  />
                                ) : (
                                  <div
                                    title={ta.name}
                                    style={profileBackground(
                                      ta.name ? ta.name[0] : ta.email[0],
                                      20,
                                      20,
                                      12
                                    )}
                                  >
                                    <div>
                                      {ta.name
                                        ? ta.name[0].toUpperCase()
                                        : ta.email[0].toUpperCase()}
                                    </div>
                                  </div>
                                )}
                                <div
                                  className=" fs-12"
                                  style={{
                                    paddingLeft: 2,
                                    display: "inline-block",
                                    maxWidth: "115px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}
                                >
                                  {ta.name}
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                    {/* </RoyTooltip> */}
                  </>
                )}
              </div>
            </div>{" "}
            <div style={{ height: 1, width: "3%" }}>
              <div>
                {/*     
                                    shadow for edit quick actions task
                                    box-shadow: 0px 3px 6px 1px rgb(0 0 0 / 54%);
    -webkit-box-shadow: 0px 4px 3px 0px rgb(0 0 0 / 54%);
    -moz-box-shadow: 0px 3px 6px 1px rgb(0 0 0 / */}
                <div id={`taskmenu-labels-${data.id}`} />
                <div id={`taskmenu-priority-${data.id}`} />

                {isQuickActionTask &&
                  isQuickActionTask.status &&
                  isQuickActionTask.action == "assign" &&
                  isQuickActionTask.target == `taskmenu-assign-${data.id}` && (
                    <>
                      <AssigneeDropdown
                        isDisableDropDownIcon
                        TargetId="assing-"
                        setDropdownOpen={() => {
                          doCloseQuickAction();
                        }}
                        businessId={props.auth.user.businessId}
                        projectId={
                          (isQuickActionTask.status &&
                            isQuickActionTask.data.project?.id) ||
                          ""
                        }
                        dropdownOpen={
                          isQuickActionTask && isQuickActionTask.status
                        }
                        currentLoggedIn={props.auth?.user?.id}
                        projectCollaborators={props.collaboratorList || []}
                        userAssigned={
                          isQuickActionTask.selectedData
                            ? isQuickActionTask.selectedData
                            : ""
                        }
                        setUserAssigned={data => {
                          onChangeTaskAssign(data, doUpdateTaskAssign);
                          // onChangeTaskAssign(data);
                        }}
                      />
                    </>
                  )}
                {isQuickActionTask &&
                  isQuickActionTask.status &&
                  isQuickActionTask.action == "schedule" &&
                  isQuickActionTask.target ==
                    `taskmenu-schedule-${data.id}` && (
                    <div style={{ position: "relative" }}>
                      <DatePickerNoInput
                        AllowAddPreviousDateTask={
                          props.auth.user?.userSetting?.AllowAddPreviousDateTask
                        }
                        dueDate={
                          isQuickActionTask.selectedData
                            ? isQuickActionTask.selectedData
                            : ""
                        }
                        onUpdateDate={doUpdateTaskScheduleDate}
                        toggle={doCloseQuickAction}
                        isOpen={isQuickActionTask.status}
                        PRIMARY_COLOR={props.PRIMARY_COLOR}
                      />
                    </div>
                  )}
              </div>
              <div
                id={`taskmenu-${data.id}`}
                onClick={() => {
                  toggleTaskMenu(`taskmenu-${data.id}`, data);
                }}
                className="today-list cursor-pointer taskOptions"
                style={{
                  textAlign: "end",
                  height: "35px",
                  width: "40px"
                }}
              >
                <img
                  alt=""
                  src={threeDot_Icon}
                  style={{
                    height: 6,
                    width: 22,
                    marginBottom: -9,
                    marginRight: 8
                  }}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { open_profile_modal })(SingleTaskView);
