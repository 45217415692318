import handleResponse, { encryptPayload } from "./handleResponse";
import {
  BASE_URL,
  date_filter,
  task_,
  overdue,
  hasUpcommingTask
} from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";
import moment from "moment";

export function getToday(token, date) {
  const tokenpayload = {
    isFuture: false,
    date: moment(new Date(date)).utc(),
    timezone: moment().format("Z")
  };
  const encryptedData = encryptPayload(tokenpayload);
  const encryptObj = { encPayload: encryptedData };
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "POST",
    headers: givenToken,
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + date_filter,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function getHasUpcommingTaskinWeek(startDate, endDate, token) {
  const startendpayload = {
    startDate: startDate,
    endDate: endDate
  };
  const encryptedData = encryptPayload(startendpayload);
  const encryptObj = { encPayload: encryptedData };
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "POST",
    headers: givenToken,
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + hasUpcommingTask,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function getOverdue(token, date, limit, page) {
  const datepayload = {
    date: moment(new Date(date)).utc()
  };
  const encryptedData = encryptPayload(datepayload);
  const encryptObj = { encPayload: encryptedData };

  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "POST",
    headers: givenToken,
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT +
      task_ +
      overdue +
      `?limit=${limit ? limit : 5}&page=${page}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function updateTaskIsCompleted(data, taskId) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + `/${taskId}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
