import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { editPencil, maxDescriptionLength } from "helper/constant";
import Shimmer from "components/shimmer/workspcaeShimmerHeader";
import ShimmerAvatar from "components/shimmer/workspaceAvatar";
import Loader from "components/loader/Loader";
import moment from "moment";
import { connect } from "react-redux";
import { getColorCode } from "components/projects/utils/color";
import IntlMessages from "util/intlMessages";
import { truncateDescription } from "helper/methods/utilFunctions";
const ProjectHeader = props => {
  const {
    tabs,
    activeTab,
    toggletab,
    projectDetails,
    toggleEditProject,
    isLoader,
    archiveProject,
    isArchived,
    isArchiveLoader,
    isOwner
  } = props;
  const [showMore, setShowMore] = useState(false);
  const [
    currentMaxDescriptionLength,
    setCurrentMaxDescriptionLength
  ] = useState(maxDescriptionLength);

  const toggleShowMore = () => {
    setShowMore(!showMore);
    setCurrentMaxDescriptionLength(showMore ? maxDescriptionLength : 300);
  };

  const projectDescription =
    projectDetails && projectDetails?.description
      ? projectDetails?.description
      : "";

  const history = useHistory();

  return (
    <div>
      <div className="workspace-details-header">
        <div>
          {projectDetails && isArchived && (
            <div className="workspace-archived">
              <div className="workspace-archived-border flex-x fs-14">
                <IntlMessages id="archived.archived" />
                &nbsp;
                {isOwner && (
                  <>
                    <IntlMessages id="projectdetails.clickhereto" />
                    &nbsp;
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        display: "flex"
                      }}
                      onClick={archiveProject}
                    >
                      <b>
                        <IntlMessages id="projectdetails.unarchive" />
                      </b>
                      {isArchiveLoader && <Loader height="20px" width="16px" />}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 16,
              color: "black",
              marginTop: 10
            }}
          >
            {/* breadcrumb */}
            {/* <div
              style={{
                width: "30%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex"
              }}
            >
              {projectDetails ? (
                <>
                  <span
                    onClick={() => history.goBack()}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      display: "inline-block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "180px",
                      fontWeight: "bold"
                    }}
                  >
                    {projectDetails && projectDetails?.name}
                  </span>
                  <span style={{ color: "black" }}>span
                    <span style={{ textDecoration: "underline" }}>
                      Project-Details
                    </span>
                  </span>
                </>
              ) : (
                ""
              )}
            </div> */}
          </div>
          <div className="workspace-details-wrapper" style={{ paddingTop: 15 }}>
            {isLoader ? (
              <div className="flex-x">
                <ShimmerAvatar />
                <Shimmer />
              </div>
            ) : (
              <>
                <div style={{ display: "flex" }}>
                  <div className="workspace-Avatar">
                    {projectDetails && (
                      <div
                        className=" workspace-placeholder"
                        style={{
                          // background: getColorCode(projectDetails?.color)
                          background: props.topbarTheme.buttonColor
                        }}
                      >
                        {projectDetails?.name[0]?.toUpperCase()}
                      </div>
                    )}
                  </div>

                  <div
                    className="workspace-details-contents"
                    style={{ width: "470px" }}
                  >
                    <div
                      className="workspace-title"
                      style={{
                        color: "black",
                        width: "100%",
                        overflowWrap: "break-word",
                        lineHeight: "1"
                      }}
                    >
                      {projectDetails && projectDetails?.name}
                    </div>
                    <div className="workspace-dates ">
                      {/* {projectDetails?.startDate
                        ? moment(projectDetails?.startDate).format(
                          "DD MMM YYYY"
                        )
                        : "N/A "} - {projectDetails?.endDate
                          ? moment(projectDetails?.endDate).format(
                            "DD MMM YYYY"
                          )
                          : "N/A "} */}

                      {projectDetails?.startDate || projectDetails?.endDate ? (
                        <>
                          <div
                            className="ProjectGridStartAndEndDate"
                            style={{
                              fontSize: 12,
                              color: "#808080",
                              marginTop: 3,
                              display: "flex"
                            }}
                          >
                            <span>
                              {<IntlMessages id={"project.starts"} />}:{" "}
                              {projectDetails?.startDate
                                ? moment(projectDetails?.startDate).format(
                                    "DD MMM YYYY"
                                  )
                                : "N/A "}
                            </span>
                            <span
                              className="ml-1"
                              style={{
                                display: projectDetails?.endDate
                                  ? "flex "
                                  : "none"
                              }}
                            >
                              {" "}
                              - &nbsp;
                              {<IntlMessages id={"project.ends"} />}:{" "}
                              {moment(projectDetails?.endDate).format(
                                "DD MMM YYYY"
                              )}
                            </span>
                            {/* <span style={{ color: "red"}} >{getOverDueProjectDate(project?.endDate)}</span> */}
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* {moment(projectDetails?.startDate).format("DD MMM YYYY")}{" "}
                      - {moment(projectDetails?.endDate).format("DD MMM YYYY")} */}
                    </div>
                    <div>
                      <div
                        style={{
                          width: "100%",
                          overflowWrap: "break-word",
                          lineHeight: "1",
                          overflow: "hidden",
                          WebkitLineClamp:
                            !showMore &&
                            projectDescription.length > maxDescriptionLength
                              ? 5
                              : "unset"
                        }}
                        className={`workspace-description ${
                          showMore ? "" : "project-description-showmore"
                        }`}
                        dangerouslySetInnerHTML={{
                          __html: showMore
                            ? projectDescription
                            : truncateDescription(
                                projectDescription,
                                maxDescriptionLength
                              )
                        }}
                      ></div>
                      {projectDescription.length > maxDescriptionLength && (
                        <a
                          style={{
                            fontSize: "12px",
                            display: "flex",
                            flexFlow: "row wrap",
                            paddingBottom: "10px",
                            lineHeight: "0.7",
                            width: "fit-content",
                            fontFamily:
                              "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Notosans,Ubuntu,Droid sans,Helvetica Neue,sans-serif"
                          }}
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            setShowMore(!showMore);
                          }}
                        >
                          {!showMore ? (
                            <IntlMessages id="Show-more" />
                          ) : (
                            <IntlMessages id="Show-less" />
                          )}
                        </a>
                      )}
                    </div>

                    <div
                      className="edit-workspace workspace-bg"
                      style={{ width: "fit-content" }}
                      onClick={toggleEditProject}
                    >
                      <img
                        alt=""
                        src={editPencil}
                        style={{ paddingRight: 10 }}
                      />

                      <IntlMessages id="projectdetails.editproject_details" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className="workspace-action-nav"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div style={{ width: "650px" }}>
          <ul className="ul-no-margin">
            {/* <li
            className={`workspace-action-nav-items ${
              activeTab == tabs[0] ? "tab-active " : ""
            }`}
            onClick={() => toggletab(tabs[0])}
          >
            <div>Projects </div>
          </li>
           */}
            <li
              className={`workspace-action-nav-items ${
                activeTab == tabs[0] ? "tab-active " : ""
              }`}
              onClick={() => toggletab(tabs[0])}
            >
              <span style={{ fontWeight: 600 }}>
                <div>
                  <IntlMessages id="projectdetails.members" />
                </div>
              </span>
            </li>
            <li
              className={`workspace-action-nav-items ${
                activeTab == tabs[1] ? "tab-active " : ""
              }`}
              onClick={() => toggletab(tabs[1])}
            >
              <span style={{ fontWeight: 600 }}>
                <div>
                  <IntlMessages id="projectdetails.settings" />
                </div>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, null)(ProjectHeader);
