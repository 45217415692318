import handleResponse, { encryptPayload } from "./handleResponse";
import handleResponseAxios from "./handleResponseAxios";
import {
  BASE_URL,
  label_filter,
  task_,
  task_complete_uncomplete,
  comment_,
  attachments_,
  activity
} from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";
import axios from "axios";

export const getTaskDetails = (id, token) => {
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "GET",
    headers: givenToken
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + +`${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
};

export function getTaskcomments(id, token) {
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "GET",
    headers: givenToken
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + comment_ + `${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function deleteComment(data, id) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + comment_ + `delete/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function addAttachmentcomment(
  data,
  updateProgress,
  cancelToken,
  setCancelToken
) {
  const form = new FormData();

  if (data.attachment !== "" && data.attachment) {
    form.append("attachment", data.attachment);
  }

  form.append("comment", data.comment);
  form.append("project_id", data.projectId);
  form.append("task_id", data.taskId);

  const requestOptions = {
    method: "POST",
    headers: getHeaderForProfile(),
    body: form
  };
  const cancelTokenSource = axios.CancelToken.source();

  setCancelToken(cancelTokenSource);

  return axios({
    url: process.env.REACT_APP_API_ENDPOINT + task_ + `comment`,
    method: "POST",
    headers: getHeaderForProfile(),
    data: form,
    onUploadProgress: progressEvent => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = parseInt(percentComplete * 100);
      updateProgress(percentComplete);
    },
    onDownloadProgress: function(progressEvent) {},
    cancelToken: cancelTokenSource.token
  })
    .then(response => response.data)
    .catch(error => {
      if (error?.response?.data) {
        handleResponseAxios(error.response);
        return error.response.data;
      }
    });
}
export function deleteAttachmentComment(attachmentId) {
  // comment/delete-attachment/31
  // attachmentId
  const requestOptions = {
    method: "POST",
    headers: getHeader()
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT +
      task_ +
      `comment/delete-attachment/` +
      attachmentId,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function addcomment(data) {
  const form = new FormData();

  if (data.attachment !== "" && data.attachment) {
    form.append("attachment", data.attachment);
  }

  form.append("comment", data.comment);
  form.append("project_id", data.projectId);
  form.append("task_id", data.taskId);

  const requestOptions = {
    method: "POST",
    headers: getHeaderForProfile(),
    body: form
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + `comment`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function getCommentsAttachment(taskId, limit = 5, page = 1) {
  var givenToken = null;

  givenToken = getHeader();

  const requestOptions = {
    method: "GET",
    headers: givenToken
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT +
      task_ +
      comment_ +
      attachments_ +
      `${taskId}?limit=${limit}&page=${page}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function addReactions(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),

    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + `commentemoji`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function deleteReactions(id) {
  const requestOptions = {
    method: "DELETE",
    headers: getHeader()
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + `commentemoji/delete/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function getTaskActivity(id, type) {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT +
      task_ +
      activity +
      `${id}` +
      `?type=${type ? type : 0}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function getForwardCommentsUrl(url) {
  const requestOptions = {
    method: "GET"
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + `forward?url=${url}`,
    requestOptions
  ).then(data => {
    return data;
  });
}

export function updateTaskActivity(id, data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + `${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function toggleTaskStatus(id) {
  const requestOptions = {
    method: "PUT",
    headers: getHeader()
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT +
      task_ +
      task_complete_uncomplete +
      `${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

// Task Activity
