import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  todayIcon,
  project_clndr,
  dotIcon,
  someoneassignmentsImg
} from "helper/constant";

import { ProjectColors, getColorCode } from "components/projects/utils/color";

const SidbarListComponment = props => {
  const {
    childList,
    project,
    updateisFavoriteProject,
    i,
    ToggleshowDeleteLabelAlert,
    updateArchiveProject,
    moreProjectOptionsID,
    setMoreProjectOptionsId,
    isProjectHover,
    ProjectMoreOptions,
    setSectionCardFlag,
    addSectionFlag,
    ToggleshowDeleteProjectAlert,
    setIsLeaveProject,
    data
  } = props;

  const [moreProjectOptions, setMoreProjectOptions] = useState({
    status: false,
    id: "",
    project: ""
  });

  return (
    <>
      {/* <p> */}
      <NavLink to={`/project/${childList.id}`}>
        <span className="span-shortname">
          <i
            className="fa fa-circle"
            style={{
              color: getColorCode(childList.color),
              fontSize: 10
            }}
          >
            {/* {childList.color} */}
          </i>
          {/* {childList.color} */}
        </span>

        <span className="span-list">{childList.name}</span>
      </NavLink>
      <span className="sidebar-hovericon">
        <img
          id={
            data == "project-menu"
              ? `project-menu-${i}`
              : `project-menu-fav-${i}`
          }
          src={dotIcon}
          className="sidebar-doticon"
          style={
            isProjectHover.status && isProjectHover.id == i
              ? {
                  display: "inline-block"
                }
              : {
                  display: "none"
                }
          }
          alt=""
          // onClick={() => setMoreProjectOptions(!moreProjectOptions)}
          onClick={() => {
            setMoreProjectOptionsId(childList.id);
            if (moreProjectOptions.status) {
              setMoreProjectOptions({
                status: !moreProjectOptions.status,
                id: "",
                project: ""
              });
            } else {
              setMoreProjectOptions({
                status: moreProjectOptions,
                id:
                  data == "project-menu"
                    ? `project-menu-${i}`
                    : `project-menu-fav-${i}`,
                project: childList
              });
            }
          }}
        />
      </span>
      <ProjectMoreOptions
        isOpen={moreProjectOptions.status}
        popoverTarget={moreProjectOptions.id}
        projectData={
          moreProjectOptions?.project ? moreProjectOptions?.project : childList
        }
        setSectionCardFlag={() => {
          setSectionCardFlag(!addSectionFlag);
        }}
        isInviteCollaborators={props.isInviteCollaborators}
        setInviteCollaborators={data => props.setInviteCollaborators(data)}
        isOwner={
          moreProjectOptions?.project
            ? moreProjectOptions?.project?.isProjectOwner
            : childList.isProjectOwner
        }
        isArchived={
          moreProjectOptions?.project
            ? moreProjectOptions?.project?.isArchived
            : childList.isArchived
        }
        updateArchiveProject={() =>
          updateArchiveProject(
            moreProjectOptions?.project
              ? moreProjectOptions?.project
              : childList
          )
        }
        isFavorite={
          moreProjectOptions?.project
            ? moreProjectOptions?.project.isFavorite
            : childList.isFavorite
        }
        updateisFavoriteProject={() =>
          updateisFavoriteProject(
            moreProjectOptions?.project
              ? moreProjectOptions?.project
              : childList
          )
        }
        toggle={() =>
          setMoreProjectOptions({
            status: !moreProjectOptions.status,
            id: "",
            project: ""
          })
        }
        toggleEditProject={data => {
          props.toggleEditProject(data);
          // props.open_project_modal({
          //   project: data ? data : childList
          // });
        }}
        deleteProject={() => {
          setMoreProjectOptions({
            status: false,
            id: "",
            project: ""
          });
          ToggleshowDeleteProjectAlert(true);
        }}
        leaveProject={() => {
          setIsLeaveProject(true);
          ToggleshowDeleteLabelAlert(true);
        }}
        // updateViewOptions={() => updateViewOptions()}
        toggleMoveProject={() =>
          props.toggleMoveProject(
            moreProjectOptions?.project
              ? moreProjectOptions?.project
              : childList
          )
        }
        // business count
        workspaceCount={props?.auth?.user?.businessCount}
      />
      {/* </p> */}
    </>
  );
};

// export default connect(null, { open_profile_modal })(SidbarListComponment);

export default SidbarListComponment;
