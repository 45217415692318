import handleResponse, { encryptPayload } from "./handleResponse";
import { BASE_URL, task_, priorityfilter } from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";

export function getPriority(priority) {
  const prioritypayload = { priority: `priority_${priority}` };
  const encryptedData = encryptPayload(prioritypayload);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + priorityfilter,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
