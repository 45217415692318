import { assign, project, task_ } from "./apiConstants";
import { getHeader } from "./getHeaders";
import handleResponse, { encryptPayload } from "./handleResponse";

export function assignTaskToUser(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "POST",
    headers: givenToken,
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + assign,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function removeAssignTask(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }

  const requestOptions = {
    method: "PUT",
    headers: givenToken,
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + assign + "remove",
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function getCollaboratorsList(projectId) {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + project + "/collaborator/" + projectId,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
