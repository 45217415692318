import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import AuthActions from "redux/auth/actions";
import enhancer from "./enhancer/resetPasswordEnhancer";
import Loader from "components/loader/Loader";
import { resetPassword as resetpassword } from "helper/services/authServices";
import Notifications, { notify } from "react-notify-toast";

const { login } = AuthActions;

const ResetPassword = props => {
  const getTokenFromUrl = () => {
    const token = props.history.location.pathname.slice(
      props.history.location.pathname.lastIndexOf("/") + 1,
      props.history.location.pathname.length
    );

    return token;
  };
  const [isLoader, setIsLoader] = useState(false);
  const [appName, setAppName] = useState();

  React.useEffect(() => {
    const storeAppname = JSON.parse(localStorage.getItem("appname"));
    setAppName(storeAppname);
  }, []);
  const isBothPasswordValid = (confirmPassword, newPassword) => {
    let status = {
      status: false,
      message:
        "New password and confirm new password must be at least 8 characters long"
    };

    if (confirmPassword && newPassword) {
      if (confirmPassword === newPassword) {
        status = { status: true, message: "ok" };
      } else {
        status = {
          status: false,
          message:
            "New password and confirm new password must be same to change password "
        };
      }
    }
    return status;
  };
  const handleLogin = async e => {
    e.preventDefault();
    let { values, handleSubmit } = props;
    try {
      let isPasswordValidstatus = isBothPasswordValid(
        values.confirmPassword,
        values.newPassword
      );
      if (isPasswordValidstatus.status) {
        setIsLoader(true);
        const loginUser = await resetpassword(
          {
            new_password: values.confirmPassword
          },
          getTokenFromUrl()
        );

        if (loginUser.error) {
          notify.show(loginUser.error.message, "error");
          setIsLoader(false);
        }
        if (loginUser.errors) {
          notify.show(loginUser.errors.message, "error");
          setIsLoader(false);
        }
        if (loginUser.message) {
          setIsLoader(false);

          setTimeout(() => {
            notify.show(
              loginUser.message + " Please login with your new password",
              "success"
            );
          }, 2500);
          props.history.push("/login");
        }
      } else {
        if (!isPasswordValidstatus.status) {
          notify.show(isPasswordValidstatus.message, "error");
        }
      }
    } catch (e) {
      if (e.response) {
        notify.show(e.response.error.message, "error");
      }
    }
    handleSubmit();
  };

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;

  const loginContainer = {
    backgroundColor: "rgb(250,250,250)",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };

  const ErrorComponment = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <Notifications />
      <div className="form-container">
        <div
          className="login-icon"
          style={{
            paddingTop: 60,
            paddingBottom: 10
          }}
        >
          <img src={appName?.data?.icon} alt="icon" height="55px" />
        </div>
        <div
          className="login-title"
          style={{ fontSize: 20, color: "black", fontWeight: "600" }}
        >
          Reset Your Password
        </div>
        <form className="pt-30 pb-60 plr-60" onSubmit={handleLogin}>
          <div className="form-group" style={{ marginBottom: "30px" }}>
            <label style={{ fontSize: 14, color: "black", fontWeight: "600" }}>
              New Password
            </label>
            <input
              type="password"
              className="form-control react-form-input"
              id="newPassword"
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="New password"
            />
            <ErrorComponment field="newPassword" />
          </div>
          <div className="form-group" style={{ marginBottom: "50px" }}>
            <label style={{ fontSize: 14, color: "black", fontWeight: "600" }}>
              Confirm New Password
            </label>
            <input
              type="password"
              className="form-control react-form-input"
              id="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Confirm new password"
            />
            <ErrorComponment field="confirmPassword" />
          </div>

          <button
            type="submit"
            className="btn form-button  "
            style={{
              borderRadius: "5px",
              height: "40px",
              fontSize: "14px",
              color: "white",
              fontWeight: "600",
              background: "#0eb998"
            }}
          >
            {isLoader ? <Loader height={30} width={28} /> : "Reset password"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  enhancer,
  connect(null, { login })
)(ResetPassword);
