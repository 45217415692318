import handleResponse from "./handleResponse";
import handleResponseAxios from "./handleResponseAxios";
import { activity } from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";

export const WorkspaceActivity = (
  limit = 10,
  page = 0,
  businessId = null,
  hasProjectId,
  projectId = null
) => {
  let requestOptions = {
    method: "GET",
    headers: getHeader()
  };
  let Url =
    process.env.REACT_APP_API_ENDPOINT +
    activity +
    `?limit=${limit}&page=${page}&b_Id=${businessId}`;

  return fetch(Url, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
};
