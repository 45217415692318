/** @format */

import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Shimmer from "components/shimmer/Shimmer";
import {
  getOverdue,
  getToday,
  getHasUpcommingTaskinWeek
} from "helper/services/todayServices";
import {
  isNewDateFuture,
  isNewDateToday,
  isNewDatePast,
  isNewDateOverDue,
  doPopFromArray,
  doUpdate_Or_PushToArray,
  doUpdateTodoArr,
  doPushToArray,
  findTodo
} from "helper/methods/dateFilterFunctions";
import UpcommingTodoListView from "components/TodoListview/UpcommingTodoListView";
import OverdueListView from "components/TodoListview/OverdueListView";
import Loader from "components/loader/Loader";
import CustomNotification from "util/CustomNotification";

import WeekDatePicker from "util/WeekDatePicker";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import ApiCalendar from "react-google-calendar-api";
import ToastMessage from "components/notifications/ToastMessage";
import { upcomming_data_not_found } from "helper/constant";
import {
  disconnect,
  joinRoom,
  leaveRoom,
  upcommingInit,
  updateTask,
  addTask
} from "services/socket";
import { upcommingSocketRoomPrefix } from "constatnt";

import alertActions from "redux/globalAlert/actions";
import { checkIsArchivedMessage } from "helper/methods/workspacestatus";
import authActions from "redux/auth/actions";
import { toggleArchive } from "helper/services/businessServices";
import NewTaskModal from "components/newTask/NewTaskModal";
import IntlMessages from "util/intlMessages";

const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;
const { update_business_archive } = authActions;

const Upcoming = props => {
  const { AllowAddPreviousDateTask } = props.auth.user.userSetting;
  const { addToast } = useToasts();
  const [upcomingTodo, setUpcomingTodo] = useState([]);
  const [isLoader, SetIsLoader] = useState(false);
  const [isOverDuueLoader, SetIsOverDueLoader] = useState(false);
  const [isArchiveLoader, setIsArchiveloader] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [overdueTasks, setOverdueTasks] = useState([]);
  const [weekDatesHasTask, setWeekDatesHasTask] = useState([]);
  const [overDuePages, setOverDuePages] = useState({ current: 1, total: 1 });
  const [isViewMoreLoader, setIsViewMoreLoader] = useState(false);
  const [toast, setToast] = useState(false);
  const [isnewTask, setIsNewTask] = useState(false);
  const [selectedUpcomingDate, setSelectedUpcomingDate] = useState(new Date());
  // week date componet state
  const [activeDay, setActiveDay] = useState();
  // week date componet state
  const [activeMonth, setActiveMonth] = useState(
    moment()
      .lang(props?.LanguageSwitcher?.language?.locale)
      .format("MMM YYYY")
  );
  const [weekDates, setWeekDates] = useState([]);
  const [SearchLabelTodoQueryResult, setSearchLabelTodoQueryResult] = useState(
    null
  );
  const [isErrorAlert, setIsErrorAlert] = useState({
    status: false,
    messageType: ""
  });
  const [isEditTask, setIsEditTask] = useState({
    status: false,
    data: "",
    action: ""
  });
  const [isQuickActionTask, setIsQuickActionTask] = useState({
    status: false,
    data: "",
    selectedData: "",
    action: "",
    target: ""
  });
  const upcommingRef = React.useRef(upcomingTodo);
  const selectedUpcomingDateRef = React.useRef(selectedUpcomingDate);
  const upcommingOverdueRef = React.useRef(overdueTasks);
  const OverdueListViewRef = useRef(null);
  function WithoutTime(dateTime) {
    var date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;
  }
  const checkIsOpnen = () => {
    let selectedDate = WithoutTime(new Date(selectedUpcomingDateRef.current));
    let todayDate = WithoutTime(new Date());
    // check if flag is enable
    if (!AllowAddPreviousDateTask) {
      // if date past, show
      if (selectedDate < todayDate) {
        return false;
      } else {
        // if date past, hide
        return true;
      }
    }

    return true;
  };
  const scrollToTop = () => {
    OverdueListViewRef.current.scrollIntoView({
      block: "center",
      inline: "nearest"
    });
  };

  React.useEffect(() => {
    upcommingRef.current = upcomingTodo;
    upcommingOverdueRef.current = overdueTasks;
  });
  React.useEffect(() => {
    if (
      selectedUpcomingDate != "" &&
      selectedUpcomingDate != " " &&
      selectedUpcomingDate
    ) {
      selectedUpcomingDateRef.current = selectedUpcomingDate;
    }
  }, [selectedUpcomingDate]);
  useEffect(() => {
    scrollToTop();
    fetchUpcomingTask();
    fetchOverdueTask();
    upcommingInit(
      props.auth.accessToken,
      addTaskDateFilterCB,
      updateTaskDateFilterCB,
      refreshUpcommingDateFilterCB
    );
    handleSocketRoom();
    return () => {
      setOverdueTasks([]);
      setIsViewMoreLoader(false);
      setOverDuePages({ current: 1, total: 1 });
      leaveRoom(`${upcommingSocketRoomPrefix}${props.auth.user.businessId}`);
      disconnect();
      setIsErrorAlert({ status: false, messageType: "" });
    };
  }, []);
  const handleSocketRoom = () => {
    // Join room

    joinRoom(`${upcommingSocketRoomPrefix}${props.auth.user.businessId}`);
  };
  const updateTaskDateFilterCB = async data => {
    if (data && data.id) {
      handleUpdateTodo(data);
    }
    if (
      data &&
      data.id &&
      (data.isDeleted === true || data.isArchived == true)
    ) {
      let todoArr = upcommingRef.current;
      let todoOverdueArr = selectedUpcomingDateRef.current;
      let updatedTodoArr = removeTodoFromList(todoArr, data);
      let updatedTodoOverdueArr = removeTodoFromList(todoOverdueArr, data);
      updatedTodoArr && setUpcomingTodo([...updatedTodoArr]);
      updatedTodoOverdueArr && setOverdueTasks([...updatedTodoOverdueArr]);
    }
  };

  const addTaskDateFilterCB = async data => {
    if (data) {
      addNewTodoList(data);
    }
  };
  const refreshUpcommingDateFilterCB = async data => {
    if (data) {
      handleUpdateTodo(data);
    }
  };
  const handleSearch = query => {
    /* fetching the input and checking if exist in customerlistarray */
    let filteredTodos;
    if (!query) {
      // setSearchContact(false);
      setSearchLabelTodoQueryResult(null);
    } else {
      filteredTodos =
        upcomingTodo &&
        upcomingTodo.filter(customer => {
          return (
            customer.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
          );
        });
      console.log(filteredTodos);
      setSearchLabelTodoQueryResult(filteredTodos);
    }
  };

  const handleResponse = message => {
    try {
      if (message == "NOT_WORKSPACE_COLLABORATOR") {
        // status:action.alert.status, message:action.alert.message,action:'open_businesslist'

        props.open_workpsace_alert({
          status: true,
          message: "workspace"
        });
      }
      if (message == "USER_NOT_PROJECT_COLABORATOR") {
        props.open_project_alert({
          status: true,
          message: "project"
        });
      }
    } catch (e) {}
  };
  // const showNotifications = message => {
  //   setToast(true);
  //   setToastMessage(message ? message : "something went wrong");
  //   setTimeout(() => {
  //     setToast(false);
  //     setToastMessage("");
  //   }, 2500);
  // };

  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const removeOverDueTaskFromList = taskId => {
    const overDueArr = upcommingOverdueRef.current;
    const TodoArr = upcommingOverdueRef.current;

    overDueArr.map((data, i) => {
      if (data.id == taskId) {
        TodoArr.splice(i, 1);
      }
    });
    setOverdueTasks([...TodoArr]);
  };
  const doGetHasUpcommingTaskinWeek = async (startDate, endDate) => {
    if (startDate && endDate) {
      try {
        const response = await getHasUpcommingTaskinWeek(startDate, endDate);
        if (response) {
          if (response.error) {
          }
          if (response.data) {
            setWeekDatesHasTask([...response.data]);
          }
        }
      } catch (e) {}
    }
  };
  const fetchUpcomingTask = async (selectedDate, isLoading = true) => {
    try {
      SetIsLoader(true);
      if (selectedDate) selectedUpcomingDateRef.current = selectedDate;
      setSelectedUpcomingDate(selectedDate);

      let formattedDate = moment(selectedDate || selectedUpcomingDate).format(
        "YYYY-MM-DD"
      );
      const Response = await getToday(null, formattedDate);
      if (Response) {
        if (Response.error) {
          SetIsLoader(false);
          handleResponse(Response.error.message);
        }
        if (Response.data) {
          setUpcomingTodo(Response.data);
          SetIsLoader(false);
        }
      }
    } catch (e) {
      SetIsLoader(false);
    }
  };
  const handleViewMoreOverdueTasks = () => {
    if (overDuePages.current < overDuePages.total) {
      fetchOverdueTask(null, true);
    }
  };

  const fetchOverdueTask = async (selectedDate, viewMore = false) => {
    try {
      SetIsOverDueLoader(!viewMore && true);
      viewMore && setIsViewMoreLoader(true);
      let formattedDate = moment(selectedDate || selectedUpcomingDate).format(
        "YYYY-MM-DD"
      );

      const Response = await getOverdue(
        props.auth.accessToken || props.history.location.state.token,
        formattedDate,
        10,
        viewMore ? overDuePages.current + 1 : 1
      );
      if (Response) {
        SetIsOverDueLoader(false);
        setIsViewMoreLoader(false);
        if (Response.error) {
          handleResponse(Response.error.message);
        }

        if (Response.data) {
          setOverDuePages({
            current: parseInt(Response.currentPage),
            total: parseInt(Response.totalPages)

            // current: parseInt(Response.currentPage ? Response.currentPage : overDuePages.current),
            // total: parseInt(Response.totalPages ? Response.totalPages : overDuePages.total)
          });
          if (viewMore) {
            setOverdueTasks([...overdueTasks, ...Response.data]);
          } else {
            setOverdueTasks([...Response.data]);
          }
        }
      }
    } catch (e) {
      SetIsOverDueLoader(false);
      setIsViewMoreLoader(false);
    }
  };
  const updateIsCompleted = async (projectId, todoId, isCompleted, data) => {
    try {
      let payload = {
        project_id: projectId.toString(),
        isCompleted: isCompleted,
        isDeleted: false,
        task_id: todoId.toString()
      };
      if (data.scheduledAt) {
        payload = { ...payload, scheduledAt: data.scheduledAt };
      }
      if (data.reminderAt) {
        payload = { ...payload, reminderAt: data.reminderAt };
      }
      updateTaskHandler(payload, false);

      // const Response = await updateTaskIsCompleted(
      //   {
      //     project_id: projectId.toString(),
      //     isCompleted: isCompleted,
      //     isDeleted: false,
      //     scheduledAt: new Date()
      //   },
      //   todoId
      // );
      // if (Response) {
      //   if (Response.data) {
      //     let task = upcomingTodo;
      //     await Promise.all(
      //       task.map((data, i) => {
      //         if (data.id == todoId) {
      //           task[i].isCompleted = isCompleted;
      //         }
      //       })
      //     );
      //     setUpcomingTodo([...task]);
      //   }
      //   if (Response.error) {
      //     handleResponse(Response.error.message);
      //     showNotifications();
      //   }
      // }
    } catch (e) {}
  };
  const showNotificationsActionable = (payloadDate, message, actionMessage) => {
    if (payloadDate) {
      addToast(
        ToastMessage(message, payloadDate, actionMessage, date => {
          selectedUpcomingDateRef.current = date;
          setSelectedUpcomingDate(payloadDate);
          setOverDuePages({ current: 1, total: 1 });
          handleActiveDateAndMonth(payloadDate);
          fetchUpcomingTask(payloadDate, true);
          fetchOverdueTask(payloadDate, false);
        }),
        {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 8000
        }
      );
    }
  };
  const addTaskHandler = async (payloadInput, syncGoogle) => {
    try {
      let manupletadObj = {
        tempId: payloadInput?.tempId,
        title: payloadInput?.title,
        description: payloadInput?.description,
        label_ids: payloadInput?.label_ids,
        reminderAt: payloadInput?.reminderAt,
        priority: payloadInput?.priority,
        user_id: payloadInput?.user_id,
        scheduledAt: payloadInput?.scheduledAt,
        section_id: payloadInput?.section_id,
        project_id: payloadInput?.project_id
      };
      if (manupletadObj && manupletadObj.title) {
        let payload = {
          ...manupletadObj,
          roomname: `${upcommingSocketRoomPrefix}${props.auth.user.businessId}`
        };

        addTask(payload, cb => {
          if (cb.status == "Ok") {
            getCurrentWeek();
            // check if task added date is not today than give toast
            if (selectedUpcomingDateRef.current !== manupletadObj.scheduledAt) {
              showNotificationsActionable(
                manupletadObj.scheduledAt,
                "Task added.",
                "Go to Task"
              );
            }
            if (
              manupletadObj?.scheduledAt &&
              manupletadObj?.scheduledAt != null &&
              syncGoogle
            ) {
              // SyncTasktoGoogle(payload);
            }
          } else {
            if (cb.status || cb.message) {
              handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
            showNotifications();
          }
        });
      }
    } catch (e) {}
  };

  const updateTaskHandler = async (payloadInput, syncGoogle) => {
    try {
      let manupletadObj = {
        title: payloadInput?.title,
        description: payloadInput?.description,
        label_ids: payloadInput?.label_ids,
        reminderAt: payloadInput?.reminderAt,
        priority: payloadInput?.priority,
        user_id: payloadInput?.user_id,
        scheduledAt: payloadInput?.scheduledAt,
        section_id: payloadInput?.section_id,
        project_id: payloadInput?.project_id,
        isDeleted: payloadInput.isDeleted,
        isCompleted: payloadInput.isCompleted,
        task_id: payloadInput?.task_id.toString(),
        isArchived: payloadInput?.isArchived
      };
      if (payloadInput.hasOwnProperty("isDeleted")) {
        manupletadObj = {
          ...manupletadObj,
          task_id: payloadInput?.task_id.toString()
        };
      }
      if (manupletadObj && manupletadObj.project_id) {
        let payload = {
          ...manupletadObj,
          roomname: `${upcommingSocketRoomPrefix}${props.auth.user.businessId}`
        };
        if (manupletadObj?.title && isEditTask?.data?.id) {
          payload = {
            ...payload,
            task_id: isEditTask?.data.id.toString()
          };
        }

        updateTask(payload, cb => {
          if (cb.status == "Ok") {
            if (
              payloadInput.hasOwnProperty("isDeleted") &&
              payloadInput.isDeleted == true
            ) {
              let manuplatedArray = upcomingTodo.filter((data, key) => {
                return data.id != payload.task_id;
              });
              setUpcomingTodo(manuplatedArray);
            }
            setIsEditTask({ status: false, data: "" });
            if (
              payload?.scheduledAt &&
              payload?.scheduledAt != null &&
              syncGoogle
            ) {
              SyncTasktoGoogle(payload);
            }
          } else {
            if (cb.status || cb.message) {
              handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            showNotifications();
          }
        });
      }
    } catch (e) {}
  };

  const SyncTasktoGoogle = async taskData => {
    if (taskData) console.log(moment(new Date(taskData.scheduledAt)));
    var eventFromNow = {
      summary: taskData.title.toString(),
      description: taskData.description.toString(),
      start: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      end: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    };

    ApiCalendar.createEvent(eventFromNow)
      .then(result => {
        if (result.error) {
          addToast(ToastMessage(result.error.message), {
            appearance: "error",
            autoDismiss: true
          });
        }
        addToast(ToastMessage("Sync to Google"), {
          appearance: "success",
          autoDismiss: true
        });
        return true;
      })
      .catch(error => {
        addToast(
          ToastMessage(
            <IntlMessages id={"task-details.something_went_wrong"} />
          ),
          {
            appearance: "error",
            autoDismiss: true
          }
        );
      });
  };
  const getCurrentWeek = (
    userSelectedDate = selectedUpcomingDateRef.current
  ) => {
    var weekStart = moment(new Date(userSelectedDate))
      .clone()
      .startOf("isoWeek");
    var days = [];
    for (var i = 0; i <= 6; i++) {
      days.push(
        moment(weekStart)
          .add(i, "days")
          .format()
      );
    }
    setWeekDates(days);
    doGetHasUpcommingTaskinWeek(
      moment(days[0]).format("YYYY-MM-DD"),
      moment(days[6]).format("YYYY-MM-DD")
    );
  };
  const refreshTodoList = newTodo => {
    let todoArr = upcommingRef.current;
    let userSelectedDate = moment(selectedUpcomingDateRef.current).format(
      "DD-MM-YYYY"
    );
    let scheduleDate = moment(newTodo.scheduledAt).format("DD-MM-YYYY");

    if (scheduleDate == userSelectedDate) {
      let newTodoToarr = [newTodo];
      let isDataFound = false;
      todoArr.map((item, index) => {
        if (item.id == newTodoToarr[0].id) {
          isDataFound = true;
          if (
            newTodo.scheduledAt !==
            moment(item.scheduledAt).format("DD-MM-YYYY")
          ) {
            getCurrentWeek(userSelectedDate);
          }
          todoArr[index] = { ...todoArr[index], ...newTodoToarr[0] };
        }
      });

      if (!isDataFound) {
        addNewTodoList(newTodo);
      }
      setUpcomingTodo([...todoArr]);
    } else {
      getCurrentWeek(userSelectedDate);
      let updatedTodoArr = removeTodoFromList(todoArr, newTodo);
      updatedTodoArr && setUpcomingTodo([...updatedTodoArr]);
    }
  };
  const removeTodoFromList = (existingList, removeTodoData) => {
    // if (existingList && existingList?.length && removeTodoData?.id) {
    //   return existingList.filter(data => data.id !== removeTodoData?.id);
    // }
    if (Array.isArray(existingList) && removeTodoData?.id) {
      return existingList.filter(data => data.id !== removeTodoData?.id);
    }
    return false;
  };
  const doCloseQuickAction = () => {
    setIsQuickActionTask({
      status: false,
      data: "",
      target: "",
      selectedData: ""
    });
  };
  const doUpdateTaskPriority = async (updatedPriority, taskDetails) => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };
    if (updatedPriority) {
      let payload = {
        priority: updatedPriority,
        task_id: isQuickActionTask?.data.id.toString(),
        project_id: isQuickActionTask.data.project.id.toString(),
        section_id: isQuickActionTask.data.section.id
          ? isQuickActionTask.data.section.id.toString()
          : ""
      };

      doUpdateQuickActionTask(payload, handleResponse);
    }
  };
  const doUpdateTaskScheduleDate = async (updatedDueDate, taskDetails) => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };
    let payload = {
      scheduledAt: new Date(updatedDueDate),
      task_id: isQuickActionTask?.data.id.toString(),
      project_id: isQuickActionTask.data.project.id.toString(),
      section_id: isQuickActionTask.data.section.id
        ? isQuickActionTask.data.section.id.toString()
        : ""
    };
    doUpdateQuickActionTask(payload, handleResponse);
  };
  const doUpdateTaskAssign = async udpatedAssignee => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };

    let payload = {
      user_id: udpatedAssignee,
      task_id: isQuickActionTask?.data.id.toString(),
      project_id: isQuickActionTask.data.project.id.toString(),
      section_id: isQuickActionTask.data.section.id
        ? isQuickActionTask.data.section.id.toString()
        : ""
    };

    doUpdateQuickActionTask(payload, handleResponse);
  };
  const doUpdateQuickActionTask = (payloadInput, handleResponse) => {
    if (payloadInput) {
      try {
        let payload = {
          ...payloadInput,
          roomname: `${upcommingSocketRoomPrefix}${props.auth.user.businessId}`
        };

        if (!payloadInput.reminderAt && isQuickActionTask.data.reminderAt) {
          payload = {
            ...payload,
            reminderAt: isQuickActionTask.data.reminderAt
          };
        }

        if (
          !payloadInput.hasOwnProperty("scheduledAt") &&
          isQuickActionTask.data.scheduledAt
        ) {
          payload = {
            ...payload,
            scheduledAt: isQuickActionTask.data.scheduledAt
          };
        }

        updateTask(payload, cb => {
          if (cb.status == "Ok") {
            if (handleResponse) {
              handleResponse({
                status: cb.status
              });
            }
            setIsEditTask({ status: false, data: "" });
            // if (
            //   payload?.scheduledAt &&
            //   payload?.scheduledAt != null &&
            //   syncGoogle
            // ) {
            //   SyncTasktoGoogle(payload);
            // }
            // setTaskinput("");
          } else {
            if (cb.status || cb.message) {
              handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            showNotifications();
          }
        });
      } catch (e) {}
    }
  };
  const refreshOverDueTodoList = newTodo => {
    if (newTodo) {
      handleUpdateTodo(newTodo);
    }
    // let todoArr = upcommingOverdueRef.current;
    // let userSelectedDate = moment(selectedUpcomingDateRef.current).format(
    //   "DD-MM-YYYY"
    // );
    // let scheduleDate = moment(newTodo.scheduledAt).format("DD-MM-YYYY");

    // // if date is today than push to today
    // if (scheduleDate === moment().format("DD-MM-YYYY")) {
    //   addNewTodoList(newTodo);

    //   let updatedTodoArr = removeTodoFromList(todoArr, newTodo);
    //   updatedTodoArr && setOverdueTasks([...updatedTodoArr]);
    //   if (updatedTodoArr?.length < 1 || updatedTodoArr?.length == 0) {
    //     fetchOverdueTask();
    //   }
    //   return 0;
    // }
    // if (scheduleDate === userSelectedDate) {
    //   let newTodoToarr = [newTodo];
    //   todoArr.map((item, index) => {
    //     if (item.id == newTodoToarr[0].id) {
    //       if (
    //         newTodo.scheduledAt !==
    //         moment(item.scheduledAt).format("DD-MM-YYYY")
    //       ) {
    //         getCurrentWeek(userSelectedDate);
    //       }
    //       todoArr[index] = { ...todoArr[index], ...newTodoToarr[0] };
    //     }
    //   });
    //   setOverdueTasks([...todoArr]);
    // } else {
    //   let updatedTodoArr = removeTodoFromList(todoArr, newTodo);
    //   if (updatedTodoArr?.length < 1 || updatedTodoArr?.length == 0) {
    //     fetchOverdueTask();
    //   }
    //   updatedTodoArr && setOverdueTasks([...updatedTodoArr]);
    // }
  };

  const handleUpdateTodo = newTodo => {
    let todoArr = upcommingRef.current;
    let todoOverdueArr = upcommingOverdueRef.current;
    const userSelectedDate = new Date(selectedUpcomingDateRef.current);
    const isTodoExistInSelectedDate = findTodo(newTodo, todoArr);
    const isTodoExistInOverdue = findTodo(newTodo, todoOverdueArr);

    if (isTodoExistInSelectedDate && isTodoExistInOverdue) {
      // check if its future date than pop from both array
      if (isTodoExistInSelectedDate[0].scheduledAt && newTodo.scheduledAt) {
        if (isNewDateFuture(userSelectedDate, newTodo?.scheduledAt)) {
          let data = doPopFromArray(newTodo, todoArr);
          setUpcomingTodo([...data]);

          let overDueData = doPopFromArray(newTodo, todoOverdueArr);
          setOverdueTasks([...overDueData]);
        }

        // if its past than than update to overdue and pop from today
        if (isNewDateOverDue(userSelectedDate, newTodo?.scheduledAt)) {
          let data = doPopFromArray(newTodo, todoArr);
          setUpcomingTodo([...data]);

          let updatedOverDueData = doUpdate_Or_PushToArray(
            newTodo,
            todoOverdueArr
          );
          setOverdueTasks([...updatedOverDueData]);
        }

        // if its today than update to today and pop from overdue
        if (isNewDateToday(userSelectedDate, newTodo?.scheduledAt)) {
          let updateData = doUpdateTodoArr(newTodo, todoArr);
          setUpcomingTodo([...updateData]);
          let overDueData = doPopFromArray(newTodo, todoOverdueArr);
          setOverdueTasks([...overDueData]);
        }
      }
    }
    if (isTodoExistInSelectedDate && isTodoExistInSelectedDate.length == 1) {
      if ((isTodoExistInSelectedDate[0]?.scheduledAt, newTodo?.scheduledAt)) {
        if (isNewDateFuture(userSelectedDate, newTodo?.scheduledAt)) {
          let data = doPopFromArray(newTodo, todoArr);
          setUpcomingTodo([...data]);
        }
        if (isNewDateToday(userSelectedDate, newTodo?.scheduledAt)) {
          let updateData = doUpdateTodoArr(newTodo, todoArr);
          setUpcomingTodo([...updateData]);
        }
        if (isNewDatePast(userSelectedDate, newTodo.scheduledAt)) {
          // pop and push to overdue
          // pop from today
          let updatedDataAfterPop = doPopFromArray(newTodo, todoArr);
          setUpcomingTodo([...updatedDataAfterPop]);
          // push to overdue
          let updatedOverDueData = doUpdate_Or_PushToArray(
            newTodo,
            todoOverdueArr
          );
          setOverdueTasks([...updatedOverDueData]);
        }
      }
    }
    if (isTodoExistInOverdue) {
      // check data is past date than update
      if (isNewDateOverDue(userSelectedDate, newTodo?.scheduledAt)) {
        let updatedOverDueData = doUpdate_Or_PushToArray(
          newTodo,
          todoOverdueArr
        );

        setOverdueTasks([...updatedOverDueData]);
      }
      // if its today  than push
      // if its today than update to today and pop from overdue

      if (isNewDateToday(userSelectedDate, newTodo?.scheduledAt)) {
        let updateData = doUpdate_Or_PushToArray(newTodo, todoArr);
        setUpcomingTodo([...updateData]);
        let overDueData = doPopFromArray(newTodo, todoOverdueArr);
        setOverdueTasks([...overDueData]);
      }
      //  else pop it
      // if ((userSelectedDate, newTodo?.scheduledAt)) {
      if (isNewDateFuture(userSelectedDate, newTodo?.scheduledAt)) {
        let data = doPopFromArray(newTodo, todoOverdueArr);
        setOverdueTasks([...data]);
      }
    }

    if (!isTodoExistInSelectedDate && !isTodoExistInOverdue) {
      if (isNewDateFuture(userSelectedDate, newTodo?.scheduledAt)) {
      }
      if (isNewDateToday(userSelectedDate, newTodo.scheduledAt)) {
        let data = doPushToArray(newTodo, todoArr);
        setUpcomingTodo([...data]);
      }
      if (isNewDatePast(userSelectedDate, newTodo.scheduledAt)) {
        let data = doPushToArray(newTodo, todoOverdueArr);
        setOverdueTasks([...data]);
      }
    }
  };
  const handleActiveDateAndMonth = date => {
    getCurrentWeek(date);
    setActiveDay(`day-${moment(date).format("DD")}`);
    setActiveMonth(moment(date).format("MMM YYYY"));
  };
  const addNewTodoList = newTodo => {
    let todoArr = upcommingRef.current;
    let status = false;
    let newTodoToarr = [newTodo];
    todoArr.map((item, index) => {
      if (item.id == newTodoToarr[0].id) {
        status = true;
        todoArr[index] = { ...todoArr[index], ...newTodoToarr[0] };
      }
    });
    let userSelectedDate = moment(selectedUpcomingDateRef.current).format(
      "DD-MM-YYYY"
    );
    let scheduleDate = moment(newTodo.scheduledAt).format("DD-MM-YYYY");
    // console.log(userSelectedDate == scheduleDate);

    if (!status && userSelectedDate == scheduleDate) {
      todoArr = [...todoArr, newTodo];
    }
    setUpcomingTodo([...todoArr]);
  };

  const handleArchiveWorkpsace = async () => {
    if (props?.auth?.user?.businessId) {
      try {
        setIsArchiveloader(true);
        const response = await toggleArchive(props?.auth?.user?.businessId);
        if (response) {
          console.log(response);
          if (response.message) {
            setIsArchiveloader(false);

            if (!checkIsArchivedMessage(response.message)) {
              props.update_business_archive(false);
            }
          }
          if (response.error) {
            handleResponse(response.error.message);
            setIsArchiveloader(false);
            showNotifications();
          }
        }
      } catch (e) {
        setIsArchiveloader(false);
        showNotifications();
      }
    }
  };
  const checkArchive = () => {
    if (props?.auth?.user?.isArchived) {
      return (
        <>
          <div className="workspace-archived">
            <div className="workspace-archived-border flex-x fs-14">
              This workspace has been&nbsp;archived.&nbsp;{""}
              {props?.auth?.user?.businessId &&
                props?.auth?.user?.isBusinessOwner && (
                  <>
                    Click here to&nbsp;
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        display: "flex"
                      }}
                      onClick={handleArchiveWorkpsace}
                    >
                      <b>Unarchive</b>{" "}
                      {isArchiveLoader && <Loader height="18px" width="15px" />}
                    </div>{" "}
                  </>
                )}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="row" style={{ backgroundColor: "white" }}>
        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
        <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
          {" "}
          {checkArchive()}
        </div>
        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
      </div>

      <div className="row" style={{ marginBottom: 50 }}>
        <div
          className="col-lg-1 col-md-1 col-sm-0 col-xs-0"
          style={{ padding: 0 }}
        >
          <div
            className="lable"
            style={{
              backgroundColor: "white",
              height: 60,
              borderBottom: "1px solid #EBEBEB"
            }}
          ></div>
        </div>
        <div
          ref={OverdueListViewRef}
          className="col-lg-10 col-md-10 col-sm-12 col-xs-12"
          style={{ padding: 0 }}
        >
          <div className="roe-card-style">
            <div className="roe-card-body" style={{ padding: 0 }}>
              <div
                className=""
                style={{
                  float: "right",
                  backgroundColor: "white",
                  height: 60,
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {/* {isSearch && ( */}

                {/* // )} */}
                {/* {!isSearch && (
                <div className="flex center">
                  <div className="mr-1">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSearchInput(null);
                        setIsSearch(!isSearch);
                      }}
                      className="search-contant "
                    >
                      <i
                        className="fas fa-search fs-14 text-muted"
                        style={{ marginTop: "10px" }}
                      ></i>
                    </span>
                  </div>
                </div>
              )} */}
              </div>

              <WeekDatePicker
                weekDates={weekDates}
                setWeekDates={setWeekDates}
                weekDatesHasTask={weekDatesHasTask}
                setWeekDatesHasTask={setWeekDatesHasTask}
                doGetHasUpcommingTaskinWeek={doGetHasUpcommingTaskinWeek}
                fetchUpcomingTask={(date, isLoading) => {
                  fetchUpcomingTask(date, isLoading);
                  fetchOverdueTask(date, isLoading);
                }}
                selectedUpcomingDate={selectedUpcomingDate}
                updateSelectedDate={date => {
                  selectedUpcomingDateRef.current = date;
                  setSelectedUpcomingDate(date);
                  // fetchUpcomingTask(date);
                }}
                setUpcomingTodo={tasks => setUpcomingTodo([...tasks])}
                startLoad={() => SetIsLoader(true)}
                stopLoad={() => SetIsLoader(false)}
                activeDay={activeDay}
                setActiveDay={setActiveDay}
                activeMonth={activeMonth}
                setActiveMonth={setActiveMonth}
              />
            </div>
          </div>
        </div>

        <div
          className="col-lg-1 col-md-1 col-sm-0 col-xs-0"
          style={{ padding: 0 }}
        >
          <div
            className="lable"
            style={{
              backgroundColor: "white",
              height: 60,
              borderBottom: "1px solid #EBEBEB"
            }}
          ></div>
        </div>
        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0" />

        <div
          className="col-lg-10 col-md-10 col-sm-12 col-xs-12"
          style={{ padding: "0px", minHeight: "85vh" }}
        >
          <div>
            {isLoader &&
            isOverDuueLoader &&
            overdueTasks?.length == 0 &&
            upcomingTodo?.length == 0 ? (
              <div className="roe-card-style mtb-15 todo-board">
                <Shimmer />
              </div>
            ) : (
              <>
                {overdueTasks?.length || upcomingTodo?.length ? (
                  <>
                    <OverdueListView
                      doCloseQuickAction={doCloseQuickAction}
                      doUpdateTaskPriority={doUpdateTaskPriority}
                      doUpdateTaskScheduleDate={doUpdateTaskScheduleDate}
                      doUpdateTaskAssign={doUpdateTaskAssign}
                      isQuickActionTask={isQuickActionTask}
                      setIsQuickActionTask={setIsQuickActionTask}
                      history={{ ...props.history }}
                      taskType={"date_filter_upcomming"}
                      isEditTask={isEditTask}
                      setIsEditTask={setIsEditTask}
                      updateTaskHandler={updateTaskHandler}
                      addTaskHandler={addTaskHandler}
                      handleViewMoreOverdueTasks={handleViewMoreOverdueTasks}
                      isViewMoreLoader={isViewMoreLoader}
                      overDuePages={overDuePages}
                      token={props.auth.accessToken}
                      businessId={props.auth.user.businessId}
                      PRIMARY_COLOR={props.topbarTheme.buttonColor}
                      updateIsCompleted={(
                        projectId,
                        todoId,
                        isCompleted,
                        data
                      ) =>
                        updateIsCompleted(projectId, todoId, isCompleted, data)
                      }
                      removeTaskFromList={removeOverDueTaskFromList}
                      refreshTodoList={data => refreshTodoList(data)}
                      todoList={overdueTasks}
                      collaboratorList={[]}
                      dummyData={false}
                      showNotifications={showNotifications}
                      // for common component for today and priority to check if data is available or not by checking isDeleted==false
                      for={"Today"}
                      auth={{ ...props.auth }}
                    />
                    <UpcommingTodoListView
                      doCloseQuickAction={doCloseQuickAction}
                      doUpdateTaskPriority={doUpdateTaskPriority}
                      doUpdateTaskScheduleDate={doUpdateTaskScheduleDate}
                      doUpdateTaskAssign={doUpdateTaskAssign}
                      isQuickActionTask={isQuickActionTask}
                      setIsQuickActionTask={setIsQuickActionTask}
                      history={{ ...props.history }}
                      AllowAddPreviousDateTask={AllowAddPreviousDateTask}
                      isEditTask={isEditTask}
                      setIsEditTask={setIsEditTask}
                      updateTaskHandler={updateTaskHandler}
                      addTaskHandler={addTaskHandler}
                      overdueTasksLength={overdueTasks?.length}
                      selectedUpcomingDate={selectedUpcomingDateRef.current}
                      PRIMARY_COLOR={props.topbarTheme.buttonColor}
                      updateIsCompleted={(
                        projectId,
                        todoId,
                        isCompleted,
                        data
                      ) => {
                        updateIsCompleted(projectId, todoId, isCompleted, data);
                      }}
                      businessId={props.auth.user.businessId}
                      refreshTodoList={data => refreshTodoList(data)}
                      todoList={upcomingTodo}
                      showNotifications={showNotifications}
                      collaboratorList={[]}
                      dummyData={false}
                      auth={{ ...props.auth }}
                      language={props?.LanguageSwitcher?.language?.languageId}
                    />
                  </>
                ) : (
                  <>
                    {!isLoader && !isOverDuueLoader && (
                      <div>
                        <div
                          className="text-muted"
                          style={{
                            paddingTop: 10,
                            fontSize: 14,
                            display: "flex",
                            justifyContent: "center"
                            // marginTop: "15px"
                          }}
                        >
                          <img
                            alt=""
                            src={upcomming_data_not_found}
                            height="300"
                            width="350"
                          />
                          <br />
                        </div>
                        <div
                          className=""
                          style={{
                            fontSize: 14,
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: 16,
                              color: "black"
                            }}
                          >
                            {moment(selectedUpcomingDateRef.current).format(
                              "DD-MM-YYYY"
                            ) >= moment().format("DD-MM-YYYY") ? (
                              <IntlMessages
                                id={"today.get_a_clear_view_of_the_day_ahead"}
                              />
                            ) : (
                              "No Task  "
                            )}
                          </span>
                        </div>
                        <div
                          className="text-muted"
                          style={{
                            fontSize: 14,
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <span style={{ fontSize: 13, fontWeight: "500" }}>
                            <>
                              <IntlMessages
                                id={
                                  "today.all_your_tasks_that_are_due_today_will_show_up_here"
                                }
                              />
                            </>
                          </span>
                        </div>
                        <div
                          className="text-muted"
                          style={{
                            paddingTop: 20,
                            fontSize: 14,
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          {checkIsOpnen() && (
                            <button
                              type="submit"
                              className="btn btn-lg"
                              style={{
                                backgroundColor: props.topbarTheme.buttonColor,
                                border: "none",
                                color: "#FFF"
                              }}
                              onClick={() => setIsNewTask(!isnewTask)}
                            >
                              <IntlMessages id={"today.addtask"} />
                            </button>
                          )}
                          {/* <button
                  className="btn button"
                  onClick={() => setIsNewTask(!isnewTask)}
                >
                  Add Task
                </button> */}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <NewTaskModal
            setmodal={() => setIsNewTask(!isnewTask)}
            modal={isnewTask}
            showNotifications={showNotifications}
            // passing for upcomming so we can give go to task in toast
            forUpcomming
            showNotificationsActionable={showNotificationsActionable}
            refreshTodoList={() => {
              getCurrentWeek();
              // no need to call api as it is in socket
              // fetchUpcomingTask(selectedUpcomingDateRef.current, false);
            }}
            defaultUpcommingDate={selectedUpcomingDateRef.current}
          />
          <CustomNotification
            show={toast}
            message={toastMessage}
            closeNotification={() => setToast(false)}
          />
        </div>
        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0" />
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    auth: state.auth,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    LanguageSwitcher: { ...state.LanguageSwitcher }
  };
};

export default connect(mapStateToProps, {
  open_workpsace_alert,
  open_project_alert,
  close_alert,
  update_business_archive
})(Upcoming);
