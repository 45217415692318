import handleAuthServiceResponse, {
  encryptPayload
} from "./handleAuthServiceResponse";
import {
  BASE_URL,
  siginwithEmail,
  sigupwithEmail,
  signinwithGoogle,
  ME,
  users,
  forgotpassword,
  changepassword,
  resetpassword,
  resentEmailVerification,
  emailVerification,
  _logout,
  _updateDeviceInformation
} from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";

export function loginWithEmail(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(encryptObj)
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + siginwithEmail,
    requestOptions
  )
    .then(handleAuthServiceResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return user;
    });
}
export function logoutWithEmail(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  var header = null;
  if (token) {
    header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    header = getHeader();
  }
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(encryptObj)
  };

  return fetch(process.env.REACT_APP_API_ENDPOINT + _logout, requestOptions)
    .then(handleAuthServiceResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return user;
    });
}
export function updateDeviceInformation(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  var header = null;
  if (token) {
    header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    header = getHeader();
  }
  const requestOptions = {
    method: "PUT",
    headers: header,
    body: JSON.stringify(encryptObj)
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + _updateDeviceInformation,
    requestOptions
  )
    .then(handleAuthServiceResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return user;
    });
}

export function loginWithGoogle(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(encryptObj)
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + signinwithGoogle,
    requestOptions
  )
    .then(handleAuthServiceResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return user;
    });
}

export function signUpwithEmail(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(encryptObj)
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + sigupwithEmail,
    requestOptions
  )
    .then(handleAuthServiceResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return user;
    });
}

export function forgotPassword(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(encryptObj)
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + forgotpassword,
    requestOptions
  )
    .then(handleAuthServiceResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return user;
    });
}

export function getUser(token) {
  var header = null;
  if (token) {
    header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    header = getHeader();
  }

  const requestOptions = {
    method: "GET",
    headers: header
  };
  return fetch(process.env.REACT_APP_API_ENDPOINT + ME, requestOptions)
    .then(handleAuthServiceResponse)
    .then(data => {
      return data;
    });
}

export function updareUserProfile(data) {
  const form = new FormData();

  if (data.profilePic !== "") {
    form.append("profilePic", data.profilePic);
  }
  form.append("name", data.name);
  form.append("designation", data.designation);
  form.append("isDeleteProfilePic", data.isDeleteProfilePic);
  const requestOptions = {
    method: "PUT",
    headers: getHeaderForProfile(),
    body: form
  };
  return fetch(process.env.REACT_APP_API_ENDPOINT + users, requestOptions)
    .then(handleAuthServiceResponse)
    .then(data => {
      return data;
    });
}

export function updatePassword(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + changepassword,
    requestOptions
  )
    .then(handleAuthServiceResponse)
    .then(user => {
      return user;
    });
}

export function resetPassword(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + resetpassword,
    requestOptions
  )
    .then(handleAuthServiceResponse)
    .then(data => {
      return data;
    });
}

export function resendEmailVerification(data, token) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + resentEmailVerification,
    requestOptions
  )
    .then(handleAuthServiceResponse)
    .then(data => {
      return data;
    });
}
export function verifyEmail(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + emailVerification + token,
    requestOptions
  )
    .then(handleAuthServiceResponse)
    .then(data => {
      return data;
    });
}
