import React, { useEffect, useState, useRef } from "react";
import ImageUploading from "react-images-uploading";
import { getUserCharColor } from "helper/methods/PlaceholderColor";
import { Button, Modal, ModalBody } from "reactstrap";
import { Alert } from "reactstrap";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import DescriptionEditor from "components/business/DescriptionEdiotr";
import CreateWorkSpace from "./CreateWorkSpace.style";
import {
  AddWorkspaceImage,
  crossIcon,
  EditIconModal,
  TreshImageCreateWorkSpace
} from "helper/constant";
import { TabContent, TabPane, Row, Col } from "reactstrap";

import { addBusiness } from "helper/services/businessServices";
import { ProjectColors } from "../projects/utils/color";
import { createProject } from "helper/services/projectServices";
import AuthActions from "redux/auth/actions";
import InviteTeamModalForm from "components/projects/InviteTeamModalForm";
import { inviteCollaborators } from "helper/services/businessCollaboratorsServices";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
const { logout, update_token, update_business, update_user_type } = AuthActions;

const CreateWorkspaceInviteTeam = props => {
  const {
    modal,
    setmodal,
    topbarTheme,
    updateProjectandLabel,
    history,
    updateRedux,
    isExistingUser,
    sidebarEvent
  } = props;

  const iref = useRef(null);

  const [isLoader, setIsLoader] = useState(false);

  const [isVisibleAlert, setIsVisibleAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState(null);
  const [imageFlage, setImageFlag] = useState(false);
  const tabType = ["addBusiness", "addCollaborator"];
  const [activeTab, setActiveTab] = useState(tabType[0]);
  const [isBusinessNameError, seisBusinessNameError] = useState(false);
  const [selectedColor, setSelectedColor] = useState(ProjectColors[0].value);
  const [token, setToken] = useState(null);
  const { addToast } = useToasts();
  const [business, setBusiness] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [addParticipant, setAddParticipant] = useState([]);
  const inputref = useRef(addParticipant);

  useEffect(() => {
    if (inputref.current) inputref.current = addParticipant;
  }, [inputref]);
  useEffect(() => {
    if (modal) {
      {
        setBusinessName("");
        setProjectName("");
        setBusinessDescription("");
        setAddParticipant([]);
        setBusiness([]);
      }
    }
    return () => {
      setBusiness([]);
      setBusinessName("");
      setProjectName("");
      setBusinessDescription("");
      setIsLoader(false);
      setIsVisibleAlert(false);
      setActiveTab(tabType[0]);
      setIsDisabled(true);
      setAddParticipant([]);
    };
  }, [modal]);

  const onImageChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
    setSelectedImage(imageList[0]);
    setImageFlag(true);
  };
  const handleFormSubmit = async e => {
    e.preventDefault();
    setIsVisibleAlert(false);
    try {
      if (activeTab == tabType[0]) {
        // set state
        // await handleBusinessData(e);

        await handleAddBusiness(e);
      }
      if (activeTab == tabType[1]) {
        // await handleAddParticipant();
      }
    } catch (e) {}
  };
  const handleBusinessData = e => {
    if (e.target.businessName.value) {
      setIsLoader(true);
      let userBusinessImage;
      if (selectedImage && imageFlage) {
        userBusinessImage = { selected: selectedImage.file };
      } else {
        userBusinessImage = { selected: "" };
      }
      setBusiness({
        businessName: e.target.businessName.value,
        businessMeta: userBusinessImage.selected,
        businessDescription: e.target.businessDescription.value
      });
      // setIsLoader(false);
      handleAddBusiness();
    }
  };
  const handleSwitchBusiness = givenToken => {
    if (business && token) {
      props.update_token(token ? token : givenToken);
      updateProjectandLabel(token ? token : givenToken);
      props.update_business(business);
    }
  };

  const handleAddBusiness = async e => {
    if (e.target.businessName.value) {
      setIsLoader(true);
      let userBusinessImage;
      if (selectedImage && imageFlage) {
        userBusinessImage = { selected: selectedImage.file };
      } else {
        userBusinessImage = { selected: "" };
      }

      const Response = await addBusiness({
        title: e.target.businessName.value,
        businessImage: userBusinessImage.selected,
        description: e.target.businessDescription?.value
          ? e.target.businessDescription.value
          : businessDescription
      });
      if (Response) {
        if (Response.error) {
          addToast(ToastMessage(Response.error.message), {
            appearance: "error",
            autoDismiss: true
          });
          setIsLoader(false);
          setErrorMessage(Response.error.message);
          setIsVisibleAlert(true);
        }
        if (Response.data) {
          addToast(ToastMessage(Response.message), {
            appearance: "success",
            autoDismiss: true
          });
          //   pushNewBusiness(Response.data.business);
          setToken(Response.data.token);
          props.update_token(Response.data.token);
          props.update_user_type(false);
          updateProjectandLabel(Response.data.token);
          setBusiness({
            businessId: Response.data.business.id,
            businessName: Response.data.business.title,
            businessMeta: Response.data.business.businessMeta || null,
            businessDescription: Response.data?.business.description,
            ...Response.data.business
          });
          props.update_business({
            businessId: Response.data.business.id,
            businessName: Response.data.business.title,
            businessMeta: Response.data.business.businessMeta
          });

          // handleProjectSubmit(Response.data.token, {
          //   businessName: Response.data.business.title,
          //   businessMeta: Response.data.business.businessMeta,
          //   businessDescription: Response.data?.business?.description,
          // });
          setSelectedImage(null);
          setIsLoader(false);
          if (sidebarEvent !== undefined) {
            setmodal(false);
            history(`/business/${Response.data.business.id}`, {
              business: {
                title: Response.data.business.title,
                businessMeta: Response.data.business.businessMeta || null
              },
              isDefault: true
            });
            setActiveTab(tabType[1]);
          } else {
            toggleTab();
          }
          // toggleTab();
        }
      }
    }
  };
  const handleAddParticipant = async e => {
    if (addParticipant && addParticipant.length > 0) {
      setIsLoader(true);
      if (business && business.businessId) {
        try {
          const response = await inviteCollaborators({
            business_id: business.businessId,
            recipient_email: addParticipant
          });
          if (response) {
            if (response.error) {
              addToast(ToastMessage(response.error.message), {
                appearance: "error",
                autoDismiss: true
              });
              setErrorMessage(Response.error.message);
              // showNotifications();
            }
            if (response.message) {
              setErrorMessage("Invitation Sent");
              setAddParticipant([]);
              addToast(ToastMessage(response.message), {
                appearance: "success",
                autoDismiss: true
              });
              setIsLoader(false);
              setmodal(!modal);
              history(`/business/${business.businessId}`, {
                business: {
                  title: business.businessName,
                  businessMeta: business.businessMeta
                },
                isDefault: true
              });
              // setIsAddParticipant(false);
              // showNotifications(response.message);
            }
          }
        } catch (e) {}
      }
    }
  };
  const toggleTab = () => {
    if (activeTab == tabType[0]) {
      setIsVisibleAlert(false);
      setAddParticipant([]);
      // add settime out in useref cursor blink
      // setTimeout(() => {
      //   if (iref.current) iref.current.focus();
      // }, 1);
      setIsDisabled(true);
      setActiveTab(tabType[1]);
    }
  };
  const handleProjectSubmit = async (businessToken, businessData) => {
    if (projectName && selectedColor) {
      setIsLoader(true);

      try {
        const Response = await createProject(
          {
            name: projectName,
            color: selectedColor,
            isFavorite: false,
            defaultView: "list"
          },
          businessToken
        );
        if (Response) {
          if (Response.error) {
            addToast(ToastMessage(Response.error.message), {
              appearance: "error",
              autoDismiss: false
            });
            setErrorMessage(Response.error.message);
          } else if (Response.errors) {
            addToast(ToastMessage(Response.error.message), {
              appearance: "error",
              autoDismiss: true
            });
            setErrorMessage(Response.errors.message);
          } else {
            if (Response.data) {
              // add project to redux
              updateRedux(Response.data);
              addToast(ToastMessage(Response.message), {
                appearance: "success",
                autoDismiss: true
              });
              // passing data as parameter to handleaddproject from handleaddbusiness and setting to redux
              props.update_token(businessToken);
              props.update_business({
                businessId: businessData.id,
                businessName: businessData.businessName,
                businessMeta: businessData.businessMeta
              });
            }
            setmodal(!modal);
            setIsLoader(false);
            history(`/project/${Response.data.id}`);
          }
        }
      } catch (e) {
        setIsLoader(false);
      }
    }
  };
  // validate business name space
  const handleBusinessNameValidatiton = e => {
    const validateName = () => {
      const validate = e.target.value.match(/^\S+\w{0,50}\S{0,}/g);

      if (
        e.target.value.length > 1 &&
        e.target.value.length < 51 &&
        validate !== null &&
        validate
      ) {
        seisBusinessNameError({ status: false, message: null });
        return true;
      }
      if (e.target.value.length > 50) {
        seisBusinessNameError({
          status: true,
          message: "Maximum 50 characters are allowed"
        });
        return false;
      }
      if (e.target.value.length <= 0)
        seisBusinessNameError({
          status: true,
          message: <IntlMessages id={"workspace.nameisrequired"} />
        });
      return false;
    };
    setBusinessName(e.target.value);
    validateName() ? setIsDisabled(false) : setIsDisabled(true);
  };
  const checkKeyDown = e => {
    // if key down then prevent it to get form submitted as chipinput chips are added on enter press
    if (e.key === "Enter" && activeTab == tabType[1]) e.preventDefault();
  };
  return (
    <Modal
      isOpen={modal}
      size="lg"
      centered
      autoFocus={false}
      // toggle={() => setmodal(!modal)}
    >
      <form onSubmit={handleFormSubmit} onKeyDown={checkKeyDown}>
        {/* <ModalHeader style={{borderBottom:"0px",padding:"0px",display: "contents",textAlign:"end"}}> */}
        {/* <span style={{ fontSize: 18 }}>
            {activeTab == tabType[0]
              ? "Add Workspace"
              : "Invite your team to workspace "}
          </span> */}
        {/* {activeTab == tabType[0]
            ? "Add Workspace"
            : "Invite your team to workspace "} */}
        {/* <img
              src={cross}
                  style={{
                    color: "black",
                    height: "12px",
                    paddingRight:"5px"
                  }}
                ></img>
        </ModalHeader> */}
        <Alert
          className="c-"
          style={{ backgroundColor: "RED ", color: "white" }}
          isOpen={isVisibleAlert}
          toggle={() => setIsVisibleAlert(false)}
        >
          {isVisibleAlert ? errorMessage : ""}
        </Alert>
        <ModalBody
        //  toggle={() =>  setmodal(!modal)}
        >
          <div style={{ position: "relative" }} className="container">
            <Row
              style={{
                marginRight: "-30px",
                marginTop: "-15px",
                marginBottom: "-15px"
              }}
            >
              <Col
                className="col-lg-6 col-md-12 col-sm-12 col-xs-12"
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  paddingLeft: "0px"
                }}
              >
                <div style={{ minHeight: 220 }}>
                  <div
                    style={{
                      maxWidth: "460px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      paddingBottom: "20px"
                    }}
                  >
                    <span
                      style={{
                        fontSize: 20,
                        fontWeight: "600",
                        color: "black"
                      }}
                    >
                      {activeTab == tabType[0] ? (
                        <IntlMessages id={"globalworkspacelist.addworkspace"} />
                      ) : (
                        <IntlMessages
                          id={"globalworkspacelist.inviteworkspaceheader"}
                        />
                      )}
                    </span>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={tabType[0]}>
                        <div
                          className="container list-container "
                          // style={{
                          //   overflowX: " scroll",
                          //   height: " 320px"
                          // }}
                          /* key={i} */
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="text-center iconDemo-image"
                            style={{ paddingTop: "30px", height: "130px" }}
                          >
                            <ImageUploading
                              value={images}
                              onChange={onImageChange}
                              dataURLKey="data_url"
                            >
                              {({
                                imageList,
                                onImageUpload,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps
                              }) => (
                                // write your building UI
                                <div
                                  className="upload__image-wrapper text-center iconDemo-image"
                                  style={{ display: "inline-block" }}
                                >
                                  {/* <img
                                    src={
                                      selectedImage && selectedImage.data_url
                                        ? selectedImage.data_url
                                        : businessImage
                                    }
                                    onClick={onImageUpload}
                                    id="iconDemoPicture"
                                    alt="icon"
                                    height="100px"
                                    width="100px"
                                    style={{
                                      objectFit: "cover",
                                      // borderRadius: "50%"
                                    }}
                                  /> */}
                                  {selectedImage && selectedImage ? (
                                    <img
                                      src={selectedImage.data_url}
                                      onClick={onImageUpload}
                                      id="iconDemoPicture"
                                      alt="icon"
                                      height="100px"
                                      width="100px"
                                      style={{
                                        borderRadius: "8px",
                                        objectFit: "cover"
                                        // borderRadius: "50%"
                                      }}
                                    />
                                  ) : (
                                    <div
                                      className="workspace-placeholder-input-value"
                                      style={{
                                        // backgroundColor: topbarTheme.buttonColor
                                        background: getUserCharColor(
                                          businessName.charAt(0)
                                        )
                                      }}
                                    >
                                      {businessName &&
                                      businessName.length > 0 ? (
                                        businessName[0].toUpperCase()
                                      ) : (
                                        <div
                                          className="workspace-placeholder-input-value-change"
                                          style={{
                                            // backgroundColor: topbarTheme.buttonColor
                                            background: getUserCharColor("W")
                                          }}
                                        >
                                          W
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {imageList.length > 0 ? (
                                    imageList.map((image, index) => (
                                      <div key={index} className="image-item">
                                        <div className="image-item__btn-wrapper">
                                          <a
                                            onClick={() => onImageUpdate(index)}
                                            style={{
                                              position: "relative",
                                              top: "-30px",
                                              left: "35px"
                                            }}
                                          >
                                            <img
                                              className="close-icon-modal"
                                              src={EditIconModal}
                                              style={{ height: 20 }}
                                            ></img>
                                            {/* <i className="fa fa-edit" /> */}
                                          </a>
                                          <a
                                            onClick={() => onImageRemove(index)}
                                            style={{
                                              position: "relative",
                                              top: "-30px",
                                              left: "-55px"
                                            }}
                                          >
                                            <img
                                              className="close-icon-modal"
                                              src={TreshImageCreateWorkSpace}
                                              style={{ height: 20 }}
                                            ></img>
                                            {/* <i className="fa fa-trash"  style={{color: "#1088e7",fontSize:"11px"}}/> */}
                                          </a>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <div key={1} className="image-item">
                                      <div className="image-item__btn-wrapper">
                                        <a
                                          onClick={onImageUpload}
                                          style={{
                                            position: "relative",
                                            top: "-30px",
                                            left: "35px"
                                          }}
                                        >
                                          <img
                                            alt=""
                                            className="close-icon-modal"
                                            src={EditIconModal}
                                            style={{ height: 20 }}
                                          ></img>
                                          {/* <i className="fa fa-edit" style={{ color: "#6998cd", fontSize: "11px" }} /> */}
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </ImageUploading>
                          </div>
                          <div className="form-group mt-30">
                            {/* <label>Name</label> */}
                            <span style={{ fontSize: 14, fontWeight: "600" }}>
                              <label>
                                <IntlMessages id={"globalworkspacelist.name"} />
                              </label>
                            </span>
                            <CreateWorkSpace>
                              <input
                                type="text"
                                className="form-control react-form-input"
                                id="businessName"
                                placeholder={InjectPlaceHolderMessage(
                                  "globalworkspacelist.workspacename",
                                  props?.LanguageSwitcher?.language?.languageId
                                )}
                                defaultValue={businessName}
                                autoFocus
                                onChange={handleBusinessNameValidatiton}
                              />
                            </CreateWorkSpace>

                            {isBusinessNameError.status && (
                              <span
                                style={{ color: "red", paddingLeft: 3 }}
                                className="fs-14"
                              >
                                {isBusinessNameError.message}
                              </span>
                            )}
                          </div>
                          <div className="form-group mb-30">
                            {/* <label>Description (optional)</label> */}
                            <span style={{ fontSize: 14, fontWeight: "600" }}>
                              <IntlMessages
                                id={"globalworkspacelist.description"}
                              />
                            </span>
                            {/* <textarea
                      id="businessDescription"
                      className="form-control react-form-input"
                      rows="5"
                      placeholder="Our team organizes everything here."
                    /> */}
                            <CreateWorkSpace>
                              <DescriptionEditor
                                inputValue={businessDescription}
                                onChange={data => setBusinessDescription(data)}
                                id="businessDescription"
                                className="form-control react-form-input"
                                rows="5"
                                placeholder={InjectPlaceHolderMessage(
                                  "globalworkspacelist.addworkspaceplaceholder",
                                  props?.LanguageSwitcher?.language?.languageId
                                )}
                              />
                            </CreateWorkSpace>
                            {/* {isBusinessNameError.status && (
                    <span
                      style={{ color: "red", paddingLeft: 3 }}
                      className="fs-14"
                    >
                      {isBusinessNameError.message}
                    </span>
                  )} */}
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId={tabType[1]}>
                        {/* <>                    Invite your team to workspace</> */}
                        <div>
                          <InviteTeamModalForm
                            setIsDisabled={flag => setIsDisabled(flag)}
                            currentLoggedInUser={props.auth.user}
                            addParticipant={addParticipant}
                            setAddParticipant={data => setAddParticipant(data)}
                            language={
                              props?.LanguageSwitcher?.language?.languageId
                            }
                          />
                        </div>
                        {/* <div>
                <ProjectModalForm
                  setIsDisabled={(flag) => setIsDisabled(flag)}
                  selectedColor={selectedColor}
                  setSelectedColor={(data) => setSelectedColor(data)}
                  projectName={projectName}
                  setProjectName={(data) => setProjectName(data)}
                  autoFocus={false}
                  inputref={iref}
                />
              </div>
             */}
                      </TabPane>
                    </TabContent>
                    {isLoader ? (
                      <Loader height="24px" width="20px" />
                    ) : (
                      <>
                        <Button
                          type="submit"
                          className="btn button fs-14"
                          // disabled={ () => {if(activeTab == tabType[1])
                          //   return true
                          //   handleAddParticipant.length > 0
                          //  isDisabled }}
                          // disabled={isDisabled}
                          // disabled={activeTab == tabType[1] && addParticipant.length > 0? "" : isDisabled}
                          disabled={isDisabled}
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            padding: "10px",
                            borderRadius: "5px",
                            width: "100%",
                            cursor: isDisabled ? "not-allowed" : "",
                            backgroundColor: topbarTheme.buttonColor,
                            border: "none"
                          }}
                          onClick={() => {
                            if (activeTab == tabType[1]) {
                              handleAddParticipant();
                            }
                          }}
                        >
                          {activeTab == tabType[0] ? (
                            <IntlMessages
                              id={"globalworkspacelist.continuebutton"}
                            />
                          ) : (
                            <IntlMessages
                              id={
                                "globalworkspacelist.inviteworkspaceinvitetoworkspace"
                              }
                            />
                          )}
                        </Button>

                        {isExistingUser ? (
                          <>
                            {activeTab == tabType[0] && (
                              // <Button
                              //   className="button btn fs-14"
                              //   style={{ background: "#FFF", border: 0, color: "#000" }}
                              //   onClick={() => setmodal(!modal)}
                              // >
                              //   Cancel
                              // </Button>
                              <></>
                            )}
                            <>
                              {activeTab == tabType[1] && (
                                <Button
                                  type="button"
                                  className="btn button  fs-14"
                                  style={{
                                    background: "#FFF",
                                    border: 0,
                                    color: "#808080",
                                    marginTop: "10px",
                                    borderRadius: "5px",
                                    width: "100%",
                                    marginBottom: "15px",
                                    textDecoration: "underline"
                                  }}
                                  onClick={() => {
                                    history(
                                      `/business/${business.businessId}`,
                                      {
                                        business: {
                                          title: business.businessName,
                                          businessMeta: business.businessMeta,
                                          ...business
                                        },
                                        isDefault: true
                                      }
                                    );
                                    setmodal(!modal);
                                  }}
                                >
                                  <IntlMessages
                                    id={
                                      "globalworkspacelist.inviteworkspaceilldothislate"
                                    }
                                  />
                                </Button>
                              )}
                            </>
                          </>
                        ) : (
                          <>
                            {activeTab == tabType[1] && (
                              <Button
                                type="button"
                                className="btn button  fs-14"
                                style={{
                                  background: "#FFF",
                                  border: 0,
                                  color: "#808080",
                                  marginTop: "10px",
                                  borderRadius: "5px",
                                  width: "100%",
                                  marginBottom: "15px",
                                  textDecoration: "underline"
                                }}
                                onClick={() => {
                                  history(`/business/${business.businessId}`, {
                                    business: {
                                      title: business.businessName,
                                      businessMeta: business.businessMeta,
                                      ...business
                                    },
                                    isDefault: true
                                  });
                                  setmodal(!modal);
                                }}
                              >
                                <IntlMessages
                                  id={
                                    "globalworkspacelist.inviteworkspaceilldothislate"
                                  }
                                />
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Col>

              <div>
                {props.auth.user &&
                  props.auth?.user?.isNewUserBusinessAdded == true && (
                    <img
                      className="close-icon-modal"
                      onClick={() => setmodal(!modal)}
                      src={crossIcon}
                      style={{
                        color: "black",
                        height: "15px",
                        position: "absolute",
                        left: "98%",
                        zIndex: 5,
                        top: 20,
                        cursor: "pointer"
                      }}
                    ></img>
                  )}
              </div>

              <Col
                className="ImgeNone col-lg-6 col-md-12 col-sm-12 col-xs-12"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <div
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    height: "100%",
                    backgroundColor: "#F9F9F9",
                    display: " flex",
                    justifyContent: "center",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderBottomLeftRadius: "7px"
                  }}
                >
                  <img
                    className="ImageName"
                    src={AddWorkspaceImage}
                    style={{
                      color: "black",
                      height: "192px"
                    }}
                  ></img>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
        {/* <ModalFooter>
          {isLoader ? (
            <Loader height="24px" width="20px" />
          ) : (
            <>
              {isExistingUser ? (
                <>
                  {activeTab == tabType[0] && (
                    <Button
                      className="button btn fs-14"
                      style={{ background: "#FFF", border: 0, color: "#000" }}
                      onClick={() => setmodal(!modal)}
                    >
                      Cancel
                    </Button>
                  )}
                  <>
                    {activeTab == tabType[1] && (
                      <Button
                        type="button"
                        className="btn button  fs-14"
                        style={{ background: "#FFF", border: 0, color: "#000" }}
                        onClick={() => {
                          history(`/business/${business.businessId}`, {
                            business: {
                              title: business.businessName,
                              businessMeta: business.businessMeta,
                              ...business
                            },
                            isDefault: true
                          });
                          setmodal(!modal);
                        }}
                      >
                        I'll do this later
                      </Button>
                    )}
                  </>
                </>
              ) : (
                <>
                  {activeTab == tabType[1] && (
                    <Button
                      type="button"
                      className="btn button  fs-14"
                      style={{ background: "#FFF", border: 0, color: "#000" }}
                      onClick={() => {
                        history(`/business/${business.businessId}`, {
                          business: {
                            title: business.businessName,
                            businessMeta: business.businessMeta,
                            ...business
                          },
                          isDefault: true
                        });
                        setmodal(!modal);
                      }}
                    >
                      I'll do this later
                    </Button>
                  )}
                </>
              )}
              <Button
                type="submit"
                className="btn button fs-14"
                // disabled={ () => {if(activeTab == tabType[1])
                //   return true
                //   handleAddParticipant.length > 0
                //  isDisabled }}
                // disabled={isDisabled}
                // disabled={activeTab == tabType[1] && addParticipant.length > 0? "" : isDisabled}
                disabled={isDisabled}
                style={{
                  cursor: isDisabled ? "not-allowed" : "",
                  border: "none",
                  backgroundColor: isDisabled
                    ? "gray"
                    : topbarTheme.buttonColor,
                  border: "none"
                }}
                onClick={() => {
                  if (activeTab == tabType[1]) {
                    handleAddParticipant();
                  }
                }}
              >
                {activeTab == tabType[0] ? "Continue" : "Invite to workspace"}
              </Button>
            </>
          )}
        </ModalFooter> */}
      </form>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {
  logout,
  update_token,
  update_business,
  update_user_type
})(CreateWorkspaceInviteTeam);
