import React, { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Collapse, CardBody, Card } from "reactstrap";
import NavSecondChild from "components/sidebar/NavSecondChild";
import ProjectArchivedSidebarList from "components/sidebar/ProjectArchivedSidebarList";
import IntlMessages from "util/intlMessages";
import classNames from "classnames";
import { ProjectColors, getColorCode } from "components/projects/utils/color";
import { sidebarData } from "util/data/sidebar";
import { todays } from "helper/constant";
import TaskMenu from "components/projects/Menus/TaskMenu";
import { todayIcon, project_clndr, dotIcon } from "helper/constant";

import { updateProject } from "helper/services/projectServices";
import ProjectMoreOptions from "components/sidebar/ProjectMoreOptions.jsx";
import IconTint from "react-icon-tint";
import { addnew } from "helper/constant";
import { iconTag, SidebarDownArrow, SidebarUpArrow } from "helper/constant";
import moment from "moment";
import SidbarListComponment from "./SidbarListComponment";
const NavList = props => {
  const [collapse, setCollapse] = useState(false);
  const [moreProjectOptions, setMoreProjectOptions] = useState({
    status: false,
    id: "",
    project: ""
  });
  const [isProjectHover, setIsProjectHover] = useState({
    status: false,
    id: ""
  });
  const [archivedMoreProjectOptions, setArchivedMoreProjectOptions] = useState({
    status: false,
    id: "",
    project: ""
  });
  const [addSectionFlag, setSectionCardFlag] = useState(false);
  const [editProjectModal, setEditProjectModal] = useState(false);
  const [showDeleteProjectAlert, ToggleshowDeleteProjectAlert] = useState(
    false
  );

  const [isLeaveProject, setIsLeaveProject] = useState(false);
  const [project, setProject] = useState(null);
  const [showDeleteLabelAlert, ToggleshowDeleteLabelAlert] = useState(false);
  const [moreProjectOptionsID, setMoreProjectOptionsId] = useState("");

  useEffect(() => {
    if (props.list.child) {
      const finalList = props.list.child.filter(
        a => !a.hasOwnProperty("child")
      );
      const threeList = props.list.child.filter(a => a.hasOwnProperty("child"));
      const finalThreelevel = [];
      if (threeList.length) {
        threeList.forEach(element => {
          element.child.forEach(ele => {
            finalThreelevel.push(ele.routepath);
          });
        });
      }
      if (
        (finalList.length &&
          finalList.map(a => a.routepath).includes(props.location.pathname)) ||
        (finalThreelevel && finalThreelevel.includes(props.location.pathname))
      ) {
        setCollapse(true);
      }
    }
  }, [props.list, props.list.child, props.location.pathname]);

  const toggle = e => {
    e.preventDefault();
    setCollapse(!collapse);
  };
  const handleProjectListMouseEnter = id => {
    setIsProjectHover({
      id: id,
      status: true
    });
  };
  const handleProjectMouseLeave = () => {
    setIsProjectHover({
      id: "",
      status: false
    });
  };
  let dynamicList;
  const {
    listNameStyle,
    list,
    mini,
    miniDrawerWidth,
    drawerWidth,
    location
  } = props;
  const CurrentRoute = location.pathname;
  const isFavoriteProjectsAvailable = projects => {
    let isFav = [];
    isFav =
      projects &&
      projects.filter(
        project =>
          project.isFavorite && !project.isArchived && !project.isDeleted
      );
    if (isFav?.length) {
      return true;
    }
    return false;
  };
  const updateArchiveProject = async projectA => {
    try {
      if (projectA && projectA.id) {
        const Response = await updateProject(
          {
            isArchived: !projectA.isArchived
          },
          projectA.id
        );
        if (Response) {
          if (Response.error) {
            handleResponse(Response.error.message);
            props.showNotifications();
          }

          if (Response.data) {
            // check if this page has current project and id than get only id from url  ,check id is same as in route
            let uriProjectId = parseInt(
              CurrentRoute.replace(/[^0-9\.]/g, ""),
              10
            );
            if (
              CurrentRoute.includes("/project/") &&
              uriProjectId == projectA.id
            ) {
              props.do_update_project();
            }
            if (
              CurrentRoute.includes("/project-details/") &&
              uriProjectId == projectA.id
            ) {
              props.do_update_project();
            }
            props.update_projects_existing({ projects: Response.data });
            props.showNotifications(
              <>
                {" "}
                <IntlMessages id={"Project"} />
                &nbsp;
                {Response?.data?.isArchived ? (
                  <IntlMessages id={"project.archived"} />
                ) : (
                  <IntlMessages id={"project.unarchived"} />
                )}
                &nbsp;
                <IntlMessages id={"task-details.successfully"} />
              </>,
              "success"
            );
          }
        }
      }
    } catch (e) {}
  };
  const updateisFavoriteProject = async projectA => {
    try {
      if (projectA && projectA.id) {
        const Response = await updateProject(
          {
            isFavorite: !projectA.isFavorite
          },
          projectA.id
        );
        if (Response) {
          if (Response.error) {
            handleResponse(Response.error.message);
            props.showNotifications();
          }

          if (Response.data) {
            props.update_projects_existing({ projects: Response.data });
            props.showNotifications(
              <>
                {" "}
                <IntlMessages id={"Project"} />
                &nbsp;
                {Response?.data?.isFavorite ? (
                  <IntlMessages id={"project.addedtofavourite"} />
                ) : (
                  <IntlMessages id={"project.removedfromfavourite"} />
                )}
              </>,
              "success"
            );
          }
        }
      }
    } catch (e) {}
  };
  const handleResponse = message => {
    try {
      if (message == "NOT_WORKSPACE_COLLABORATOR") {
        // status:action.alert.status, message:action.alert.message,action:'open_businesslist'

        props.open_workpsace_alert({
          status: true,
          message: "workspace"
        });
      }
      if (message == "USER_NOT_PROJECT_COLABORATOR") {
        props.open_project_alert({
          status: true,
          message: "project"
        });
        // props.remove_Project(id);
      }
      if (
        message.toLocaleLowerCase() == "Project not found".toLocaleLowerCase()
      ) {
        props.open_project_alert({
          status: true,
          message: "project"
        });
        // props.remove_Project(id);
      }
    } catch (e) {}
  };

  if (list.child) {
    const finalList = list.child.filter(a => !a.hasOwnProperty("child"));
    const threeList = list.child.filter(a => a.hasOwnProperty("child"));
    const finalThreelevel = [];
    if (threeList.length) {
      threeList.forEach(element => {
        element.child.forEach(ele => {
          finalThreelevel.push(ele.routepath);
        });
      });
    }
    dynamicList = (
      <>
        {list && list.name == "sidebar.favourites" ? (
          <>
            {props.projects?.projects &&
            props.projects.projects.length > 0 &&
            isFavoriteProjectsAvailable(props.projects?.projects) ? (
              <li
                className={classNames(
                  "pos-relative",
                  collapse && "sidebar-whole-list"
                )}
              >
                {!mini || miniDrawerWidth === drawerWidth ? (
                  collapse ? (
                    <img
                      style={{ marginRight: 26, marginTop: 13 }}
                      className={classNames(
                        ((finalList.length &&
                          finalList
                            .map(a => a.routepath)
                            .includes(CurrentRoute)) ||
                          (finalThreelevel &&
                            finalThreelevel.includes(CurrentRoute))) &&
                          "active-arrows",
                        "fas fa-caret-up",
                        "arrow-sidebar"
                      )}
                      onClick={toggle}
                      src={SidebarUpArrow}
                    ></img>
                  ) : (
                    // <i
                    //   className={classNames(
                    //     ((finalList.length &&
                    //       finalList
                    //         .map(a => a.routepath)
                    //         .includes(CurrentRoute)) ||
                    //       (finalThreelevel &&
                    //         finalThreelevel.includes(CurrentRoute))) &&
                    //     "active-arrows",
                    //     "fas fa-caret-up",
                    //     "arrow-sidebar"
                    //   )}
                    //   onClick={toggle}
                    // />
                    <img
                      style={{ marginRight: 26, marginTop: 13 }}
                      className={classNames(
                        ((finalList.length &&
                          finalList
                            .map(a => a.routepath)
                            .includes(CurrentRoute)) ||
                          (finalThreelevel &&
                            finalThreelevel.includes(CurrentRoute))) &&
                          "active-arrows",
                        "fas fa-caret-down",
                        "arrow-sidebar"
                      )}
                      onClick={toggle}
                      src={SidebarDownArrow}
                    ></img>
                    // <i
                    //   className={classNames(
                    //     ((finalList.length &&
                    //       finalList
                    //         .map(a => a.routepath)
                    //         .includes(CurrentRoute)) ||
                    //       (finalThreelevel &&
                    //         finalThreelevel.includes(CurrentRoute))) &&
                    //     "active-arrows",
                    //     "fas fa-caret-down",
                    //     "arrow-sidebar"
                    //   )}
                    //   onClick={toggle}
                    // />
                  )
                ) : (
                  ""
                )}
                {list.name == "sidebar.favourites" ? (
                  <>
                    {props.projects?.projects &&
                    props.projects.projects.length > 0 &&
                    isFavoriteProjectsAvailable(props.projects?.projects) ? (
                      <NavLink
                        style={{
                          // list.name == "sidebar.today" ? "flex" : "flex"
                          display: "flex"
                        }}
                        to={"/todo-list"}
                        onClick={toggle}
                        className={classNames(
                          ((finalList.length &&
                            finalList
                              .map(a => a.routepath)
                              .includes(CurrentRoute)) ||
                            (finalThreelevel &&
                              finalThreelevel.includes(CurrentRoute))) &&
                            "active",
                          "nav-link",
                          "main-list"
                        )}
                      >
                        {/* <i className={list.iconClass} /> */}
                        <span className="mr-15 ">
                          <img
                            src={list.src}
                            style={{
                              height:
                                list.name == "sidebar.labels" ? "20px" : "17px",
                              width:
                                list.name == "sidebar.labels" ? "20px" : "17px",
                              marginLeft:
                                list.name == "sidebar.labels" ? "6px" : "8px"
                            }}
                          ></img>
                        </span>

                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "black"
                          }}
                        >
                          {<IntlMessages id={list.name} />}
                          {list.hasOwnProperty("isNew") && list["isNew"] && (
                            <span
                              style={{ right: "23px" }}
                              className="new-update-tag fs-13 bold-text"
                            >
                              <IntlMessages id={"sidebar.new"} />
                            </span>
                          )}
                        </p>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <NavLink
                    style={{
                      // display: list.name == "sidebar.today" ? "flex" : "flex"
                      display: "flex"
                    }}
                    to={"/todo-list"}
                    onClick={toggle}
                    className={classNames(
                      ((finalList.length &&
                        finalList
                          .map(a => a.routepath)
                          .includes(CurrentRoute)) ||
                        (finalThreelevel &&
                          finalThreelevel.includes(CurrentRoute))) &&
                        "active",
                      "nav-link",
                      "main-list"
                    )}
                  >
                    {/* <i className={list.iconClass} /> */}
                    <span className="mr-15 ">
                      <img
                        src={list.src}
                        style={{
                          height:
                            list.name == "sidebar.labels"
                              ? "20px"
                              : list.name == "sidebar.filters"
                              ? "16px"
                              : list.name == "sidebar.projects"
                              ? "18px"
                              : "",
                          width: "20px",
                          marginLeft:
                            list.name == "sidebar.projects"
                              ? "6px"
                              : list.name == "sidebar.labels"
                              ? "6px"
                              : list.name == "sidebar.filters"
                              ? "7px"
                              : ""
                        }}
                      ></img>
                    </span>

                    <p style={{ fontSize: "14px" }}>
                      {<IntlMessages id={list.name} />}
                      {list.hasOwnProperty("isNew") && list["isNew"] && (
                        <span
                          style={{ right: "23px" }}
                          className="new-update-tag fs-13 bold-text"
                        >
                          <IntlMessages id={"sidebar.new"} />
                        </span>
                      )}
                    </p>
                  </NavLink>
                )}

                <Collapse isOpen={collapse}>
                  <Card className="background-transparent border-none">
                    <CardBody
                      className={classNames(
                        "pa-0",
                        props.themeSetting.activeLinkStyle === "style4" &&
                          "active-sidebar-type-class"
                      )}
                    >
                      {list &&
                        list.name == "sidebar.projects" &&
                        props.projects?.projects &&
                        props.projects.projects.length > 0 && (
                          <>
                            {props.projects.projects.map((childList, i) => {
                              if (!childList.isArchived) {
                                return (
                                  <>
                                    {childList.name !== "Inbox" ? (
                                      <div
                                        className="nav-link child-list"
                                        style={{
                                          paddingTop: 0,
                                          paddingBottom: 0,
                                          color: "#000"
                                        }}
                                        key={i}
                                        onMouseEnter={() =>
                                          handleProjectListMouseEnter(i)
                                        }
                                        onMouseLeave={handleProjectMouseLeave}
                                      >
                                        <p>
                                          <NavLink
                                            to={`/project/${childList.id}`}
                                          >
                                            <span className="span-shortname">
                                              <i
                                                className="fa fa-circle"
                                                style={{
                                                  color: getColorCode(
                                                    childList.color
                                                  ),
                                                  fontSize: 10
                                                }}
                                              >
                                                {/* {childList.color} */}
                                              </i>
                                              {/* {childList.color} */}
                                            </span>

                                            <span className="span-list">
                                              {childList.name}
                                            </span>
                                          </NavLink>
                                          <img
                                            id={`project-menu-${i}`}
                                            // className="fas fa-ellipsis-h"
                                            src={dotIcon}
                                            style={
                                              isProjectHover.status &&
                                              isProjectHover.id == i
                                                ? {
                                                    height: "15px",
                                                    width: "15px",
                                                    position: "relative",
                                                    bottom: "10px",
                                                    marginLeft: "23px",
                                                    cursor: "pointer",
                                                    display: "inline-block"
                                                  }
                                                : {
                                                    height: "15px",
                                                    width: "15px",
                                                    position: "relative",
                                                    bottom: "10px",
                                                    marginLeft: "23px",
                                                    cursor: "pointer",
                                                    display: "none"
                                                  }
                                            }
                                            // onClick={() => setMoreProjectOptions(!moreProjectOptions)}
                                            onClick={() => {
                                              if (moreProjectOptions.status) {
                                                setMoreProjectOptions({
                                                  status: !moreProjectOptions.status,
                                                  id: "",
                                                  project: ""
                                                });
                                              } else {
                                                setMoreProjectOptions({
                                                  status: moreProjectOptions,
                                                  id: `project-menu-${i}`,
                                                  project: childList
                                                });
                                              }
                                            }}
                                          />
                                          <ProjectMoreOptions
                                            isOpen={moreProjectOptions.status}
                                            popoverTarget={
                                              moreProjectOptions.id
                                            }
                                            projectData={
                                              moreProjectOptions?.project
                                                ? moreProjectOptions?.project
                                                : childList
                                            }
                                            setSectionCardFlag={() => {
                                              setSectionCardFlag(
                                                !addSectionFlag
                                              );
                                            }}
                                            isInviteCollaborators={
                                              props.isInviteCollaborators
                                            }
                                            setInviteCollaborators={data =>
                                              props.setInviteCollaborators({
                                                ...data,
                                                project: moreProjectOptions?.project
                                                  ? moreProjectOptions?.project
                                                  : childList
                                              })
                                            }
                                            isOwner={
                                              moreProjectOptions?.project
                                                ? moreProjectOptions?.project
                                                    ?.isProjectOwner
                                                : childList.isProjectOwner
                                            }
                                            isArchived={
                                              moreProjectOptions?.project
                                                ? moreProjectOptions?.project
                                                    ?.isArchived
                                                : childList.isArchived
                                            }
                                            updateArchiveProject={() =>
                                              updateArchiveProject(
                                                moreProjectOptions?.project
                                                  ? moreProjectOptions?.project
                                                  : childList
                                              )
                                            }
                                            isFavorite={
                                              moreProjectOptions?.project
                                                ? moreProjectOptions?.project
                                                    .isFavorite
                                                : childList.isFavorite
                                            }
                                            updateisFavoriteProject={() =>
                                              updateisFavoriteProject(
                                                moreProjectOptions?.project
                                                  ? moreProjectOptions?.project
                                                  : childList
                                              )
                                            }
                                            toggle={() =>
                                              setMoreProjectOptions({
                                                status: !moreProjectOptions.status,
                                                id: "",
                                                project: ""
                                              })
                                            }
                                            toggleEditProject={data => {
                                              props.open_project_modal({
                                                project: data ? data : childList
                                              });
                                            }}
                                            deleteProject={() => {
                                              setMoreProjectOptions({
                                                status: false,
                                                id: "",
                                                project: ""
                                              });
                                              ToggleshowDeleteProjectAlert(
                                                true
                                              );
                                            }}
                                            leaveProject={() => {
                                              setIsLeaveProject(true);
                                              ToggleshowDeleteLabelAlert(true);
                                            }}
                                            // updateViewOptions={() => updateViewOptions()}
                                            toggleMoveProject={() =>
                                              props.toggleMoveProject(
                                                moreProjectOptions?.project
                                                  ? moreProjectOptions?.project
                                                  : childList
                                              )
                                            }
                                            // business count
                                            workspaceCount={
                                              props?.auth?.user?.businessCount
                                            }
                                          />
                                        </p>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              }
                            })}
                          </>
                        )}
                      {list &&
                        list.name == "sidebar.favourites" &&
                        props.projects?.projects &&
                        props.projects.projects.length > 0 &&
                        isFavoriteProjectsAvailable(
                          props.projects?.projects
                        ) && (
                          <>
                            {props.projects.projects.map((childList, i) => {
                              if (
                                !childList.isArchived &&
                                childList.isFavorite
                              ) {
                                return (
                                  <>
                                    {childList.name !== "Inbox" ? (
                                      <div
                                        className="nav-link child-list"
                                        style={{
                                          paddingTop: 0,
                                          paddingBottom: 0,
                                          color: "#000"
                                        }}
                                        key={i}
                                        onMouseEnter={() =>
                                          handleProjectListMouseEnter(i)
                                        }
                                        onMouseLeave={handleProjectMouseLeave}
                                      >
                                        <SidbarListComponment
                                          childList={childList}
                                          project={props.projects.projects}
                                          moreProjectOptions={
                                            moreProjectOptions
                                          }
                                          setMoreProjectOptions={
                                            setMoreProjectOptions
                                          }
                                          i={i}
                                          data={"project-menu-fav"}
                                          moreProjectOptionsID={
                                            moreProjectOptionsID
                                          }
                                          setMoreProjectOptionsId={
                                            setMoreProjectOptionsId
                                          }
                                          updateArchiveProject={
                                            updateArchiveProject
                                          }
                                          updateisFavoriteProject={
                                            updateisFavoriteProject
                                          }
                                          ToggleshowDeleteLabelAlert={
                                            ToggleshowDeleteLabelAlert
                                          }
                                          isProjectHover={isProjectHover}
                                          ProjectMoreOptions={
                                            ProjectMoreOptions
                                          }
                                          setSectionCardFlag={
                                            setSectionCardFlag
                                          }
                                          addSectionFlag={addSectionFlag}
                                          ToggleshowDeleteProjectAlert={
                                            ToggleshowDeleteProjectAlert
                                          }
                                          setIsLeaveProject={setIsLeaveProject}
                                          toggleEditProject={data => {
                                            props.open_project_modal({
                                              project: data ? data : childList
                                            });
                                          }}
                                          isInviteCollaborators={
                                            props.isInviteCollaborators
                                          }
                                          setInviteCollaborators={data =>
                                            props.setInviteCollaborators({
                                              ...data,
                                              project: moreProjectOptions?.project
                                                ? moreProjectOptions?.project
                                                : childList
                                            })
                                          }
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              }
                            })}
                          </>
                        )}
                      {list &&
                        list.name == "sidebar.labels" &&
                        props.labels?.labels &&
                        props.labels.labels.length > 0 &&
                        props.labels.labels.map((childList, i) => {
                          return (
                            <>
                              <NavLink
                                to={`/label/${childList.id}`}
                                className="nav-link child-list"
                                style={{
                                  paddingTop: 0,
                                  paddingBottom: 0
                                }}
                                key={i}
                              >
                                <span className="span-shortname">
                                  <i
                                    className="fa fa-tag"
                                    style={{
                                      color: getColorCode(childList.color),
                                      fontSize: 12
                                    }}
                                  >
                                    {/* {childList.color} */}
                                  </i>
                                </span>

                                <p style={listNameStyle}>
                                  <div>
                                    <span className="span-list">
                                      {childList.name}
                                    </span>
                                  </div>

                                  {childList.hasOwnProperty("isNew") &&
                                    true(
                                      <span className="new-update-tag fs-13 bold-text">
                                        <IntlMessages id={"sidebar.new"} />
                                      </span>
                                    )}
                                </p>
                              </NavLink>
                            </>
                          );
                        })}

                      {list && list.name == "sidebar.filters" && list.child
                        ? list.child.map((childList, i) => {
                            return (
                              <>
                                <NavLink
                                  to={childList.routepath}
                                  className="nav-link child-list"
                                  style={{
                                    paddingTop: 0,
                                    paddingBottom: 0
                                  }}
                                  key={i}
                                >
                                  <span className="span-shortname">
                                    <i
                                      className="fa fa-filter"
                                      style={{
                                        color: getColorCode(childList.color),
                                        fontSize: 12
                                      }}
                                    >
                                      {/* {childList.color} */}
                                    </i>
                                  </span>

                                  <p style={listNameStyle}>
                                    <span className="span-list">
                                      {childList.listname}
                                    </span>

                                    {childList.hasOwnProperty("isNew") &&
                                      true(
                                        <span className="new-update-tag fs-13 bold-text">
                                          <IntlMessages id={"sidebar.new"} />
                                        </span>
                                      )}
                                  </p>
                                </NavLink>
                              </>
                            );
                          })
                        : ""}

                      {list.name !== "sidebar.labels" &&
                      list.name !== "sidebar.projects" &&
                      list.name !== "sidebar.favourites" &&
                      list.name !== "sidebar.filters" &&
                      list.child
                        ? list.child.map((childList, i) => {
                            return (
                              <Fragment key={i}>
                                {childList.child ? (
                                  <NavSecondChild
                                    CurrentRoute={CurrentRoute}
                                    listNameStyle={listNameStyle}
                                    childList={childList}
                                    mini={mini}
                                    miniDrawerWichdth={miniDrawerWidth}
                                    drawerWidth={drawerWidth}
                                  />
                                ) : (
                                  <NavLink
                                    to={childList.routepath}
                                    className="nav-link child-list"
                                    key={i}
                                  >
                                    <i
                                      style={{
                                        color: getColorCode(childList.color)
                                      }}
                                      className="fa fa-tag"
                                    ></i>

                                    <p style={listNameStyle}>
                                      {childList.listname}

                                      {childList.hasOwnProperty("isNew") &&
                                        true(
                                          <span className="new-update-tag fs-13 bold-text">
                                            <IntlMessages id={"sidebar.new"} />
                                          </span>
                                        )}
                                    </p>
                                  </NavLink>
                                )}
                              </Fragment>
                            );
                          })
                        : ""}

                      {list.name == "sidebar.projects" && (
                        <a
                          onClick={() => props.openModel()}
                          className="nav-link child-list"
                          style={{
                            cursor: "pointer"
                          }}
                        >
                          <i
                            style={{
                              color: "grey",
                              fontSize: 15
                            }}
                            // className="fas fa-plus"
                          >
                            <img
                              src={addnew}
                              style={{
                                height: "15px",
                                width: "15px"
                              }}
                            ></img>
                          </i>
                          <div style={listNameStyle}>
                            <p style={{ color: "grey" }}>
                              <IntlMessages id={"new_project"} />
                            </p>
                          </div>
                        </a>
                      )}
                      {list.name === "sidebar.projects" &&
                      props.projects.projects ? (
                        <>
                          <Fragment>
                            <ProjectArchivedSidebarList
                              listname={"Archived Projects"}
                              CurrentRoute={CurrentRoute}
                              listNameStyle={listNameStyle}
                              childList={
                                props.projects.projects &&
                                props.projects.projects
                              }
                              mini={mini}
                              miniDrawerWichdth={miniDrawerWidth}
                              drawerWidth={drawerWidth}
                              setArchivedMoreProjectOptions={
                                setArchivedMoreProjectOptions
                              }
                              archivedMoreProjectOptions={
                                archivedMoreProjectOptions
                              }
                              updateArchiveProject={updateArchiveProject}
                            />
                          </Fragment>
                        </>
                      ) : (
                        ""
                      )}
                      {list.name == "sidebar.labels" && (
                        <a
                          onClick={() => props.ToggleaddLabel()}
                          className="nav-link child-list"
                          style={{
                            cursor: "pointer"
                          }}
                        >
                          <i
                            style={{
                              color: "grey",
                              fontSize: 15
                            }}
                            // className="fas fa-plus"
                          >
                            <img
                              src={addnew}
                              style={{
                                height: "15px",
                                width: "15px"
                              }}
                            ></img>
                          </i>
                          <div style={listNameStyle}>
                            <p style={{ color: "grey" }}>
                              <IntlMessages id={"labels.newlabel"} />
                            </p>
                          </div>
                        </a>
                      )}
                    </CardBody>
                  </Card>
                </Collapse>
              </li>
            ) : (
              ""
            )}
          </>
        ) : (
          <li
            className={classNames(
              "pos-relative",
              collapse && "sidebar-whole-list"
            )}
          >
            {list && list.name != "sidebar.activity" ? (
              <>
                {!mini || miniDrawerWidth === drawerWidth ? (
                  collapse ? (
                    <img
                      style={{ marginRight: 26, marginTop: 13 }}
                      className={classNames(
                        ((finalList.length &&
                          finalList
                            .map(a => a.routepath)
                            .includes(CurrentRoute)) ||
                          (finalThreelevel &&
                            finalThreelevel.includes(CurrentRoute))) &&
                          "active-arrows",
                        "fas fa-caret-up",
                        "arrow-sidebar"
                      )}
                      onClick={toggle}
                      src={SidebarUpArrow}
                    ></img>
                  ) : (
                    // <i
                    //   className={classNames(
                    //     ((finalList.length &&
                    //       finalList.map(a => a.routepath).includes(CurrentRoute)) ||
                    //       (finalThreelevel &&
                    //         finalThreelevel.includes(CurrentRoute))) &&
                    //       "active-arrows",
                    //     "fas fa-caret-up",
                    //     "arrow-sidebar"
                    //   )}
                    //   onClick={toggle}
                    // />
                    <img
                      style={{ marginRight: 26, marginTop: 13 }}
                      className={classNames(
                        ((finalList.length &&
                          finalList
                            .map(a => a.routepath)
                            .includes(CurrentRoute)) ||
                          (finalThreelevel &&
                            finalThreelevel.includes(CurrentRoute))) &&
                          "active-arrows",
                        "fas fa-caret-down",
                        "arrow-sidebar"
                      )}
                      onClick={toggle}
                      src={SidebarDownArrow}
                    ></img>
                    // <i
                    //   className={classNames(
                    //     ((finalList.length &&
                    //       finalList.map(a => a.routepath).includes(CurrentRoute)) ||
                    //       (finalThreelevel &&
                    //         finalThreelevel.includes(CurrentRoute))) &&
                    //       "active-arrows",
                    //     "fas fa-caret-down",
                    //     "arrow-sidebar"
                    //   )}
                    //   onClick={toggle}
                    // />
                  )
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {list.name == "sidebar.favourites" ? (
              <>
                {props.projects?.projects &&
                props.projects.projects.length > 0 &&
                isFavoriteProjectsAvailable(props.projects?.projects) ? (
                  <NavLink
                    style={{
                      // display: list.name == "sidebar.today" ? "flex" : "flex"
                      display: "flex"
                    }}
                    to={"/todo-list"}
                    onClick={toggle}
                    className={classNames(
                      ((finalList.length &&
                        finalList
                          .map(a => a.routepath)
                          .includes(CurrentRoute)) ||
                        (finalThreelevel &&
                          finalThreelevel.includes(CurrentRoute))) &&
                        "active",
                      "nav-link",
                      "main-list"
                    )}
                  >
                    {/* <i className={list.iconClass} /> */}
                    <span className="mr-15 ">
                      <img
                        src={list.src}
                        style={{
                          height:
                            list.name == "sidebar.labels" ? "20px" : "17px",
                          width:
                            list.name == "sidebar.labels" ? "20px" : "17px",
                          marginLeft:
                            list.name == "sidebar.labels" ? "6px" : "8px"
                        }}
                      ></img>
                    </span>

                    <p style={{ fontSize: "14px" }}>
                      {<IntlMessages id={list.name} />}
                      {list.hasOwnProperty("isNew") && list["isNew"] && (
                        <span
                          style={{ right: "23px" }}
                          className="new-update-tag fs-13 bold-text"
                        >
                          <IntlMessages id={"sidebar.new"} />
                        </span>
                      )}
                    </p>
                  </NavLink>
                ) : (
                  ""
                )}
              </>
            ) : (
              <NavLink
                style={{
                  // display: list.name == "sidebar.today" ? "flex" : "flex"
                  display: "flex"
                }}
                to={"/todo-list"}
                onClick={toggle}
                className={classNames(
                  ((finalList.length &&
                    finalList.map(a => a.routepath).includes(CurrentRoute)) ||
                    (finalThreelevel &&
                      finalThreelevel.includes(CurrentRoute))) &&
                    "active",
                  "nav-link",
                  "main-list"
                )}
              >
                {/* <i className={list.iconClass} /> */}
                <span className="mr-15 ">
                  <img
                    src={list.src}
                    style={{
                      height:
                        list.name == "sidebar.labels"
                          ? "20px"
                          : list.name == "sidebar.filters"
                          ? "16px"
                          : list.name == "sidebar.projects"
                          ? "18px"
                          : list.name == "sidebar.activity"
                          ? "16px"
                          : "",
                      width: "20px",
                      marginLeft:
                        list.name == "sidebar.projects"
                          ? "6px"
                          : list.name == "sidebar.labels"
                          ? "6px"
                          : list.name == "sidebar.filters"
                          ? "7px"
                          : list.name == "sidebar.activity"
                          ? "6px"
                          : ""
                    }}
                  ></img>
                </span>

                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "black"
                  }}
                >
                  {<IntlMessages id={list.name} />}
                  {list.hasOwnProperty("isNew") && list["isNew"] && (
                    <span
                      style={{ right: "23px" }}
                      className="new-update-tag fs-13 bold-text"
                    >
                      <IntlMessages id={"sidebar.new"} />
                    </span>
                  )}
                </p>
              </NavLink>
            )}

            <Collapse isOpen={collapse}>
              <Card className="background-transparent border-none">
                <CardBody
                  className={classNames(
                    "pa-0",
                    props.themeSetting.activeLinkStyle === "style4" &&
                      "active-sidebar-type-class"
                  )}
                >
                  {list &&
                    list.name == "sidebar.projects" &&
                    props.projects?.projects &&
                    props.projects.projects.length > 0 && (
                      <>
                        {props.projects.projects.map((childList, i) => {
                          if (!childList.isArchived) {
                            return (
                              <>
                                {childList.name !== "Inbox" ? (
                                  <div
                                    className="nav-link child-list"
                                    style={{
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                      color: "#000"
                                    }}
                                    key={i}
                                    onMouseEnter={() =>
                                      handleProjectListMouseEnter(i)
                                    }
                                    onMouseLeave={handleProjectMouseLeave}
                                  >
                                    <SidbarListComponment
                                      childList={childList}
                                      project={props.projects.projects}
                                      moreProjectOptions={moreProjectOptions}
                                      setMoreProjectOptions={
                                        setMoreProjectOptions
                                      }
                                      i={i}
                                      data={"project-menu"}
                                      moreProjectOptionsID={
                                        moreProjectOptionsID
                                      }
                                      setMoreProjectOptionsId={
                                        setMoreProjectOptionsId
                                      }
                                      updateArchiveProject={
                                        updateArchiveProject
                                      }
                                      updateisFavoriteProject={
                                        updateisFavoriteProject
                                      }
                                      ToggleshowDeleteLabelAlert={
                                        ToggleshowDeleteLabelAlert
                                      }
                                      isProjectHover={isProjectHover}
                                      ProjectMoreOptions={ProjectMoreOptions}
                                      setSectionCardFlag={setSectionCardFlag}
                                      addSectionFlag={addSectionFlag}
                                      ToggleshowDeleteProjectAlert={
                                        ToggleshowDeleteProjectAlert
                                      }
                                      setIsLeaveProject={setIsLeaveProject}
                                      toggleEditProject={data => {
                                        props.open_project_modal({
                                          project: data ? data : childList
                                        });
                                      }}
                                      isInviteCollaborators={
                                        props.isInviteCollaborators
                                      }
                                      setInviteCollaborators={data =>
                                        props.setInviteCollaborators({
                                          ...data,
                                          project: moreProjectOptions?.project
                                            ? moreProjectOptions?.project
                                            : childList
                                        })
                                      }
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          }
                        })}
                      </>
                    )}
                  {list &&
                    list.name == "sidebar.favourites" &&
                    props.projects?.projects &&
                    props.projects.projects.length > 0 &&
                    isFavoriteProjectsAvailable(props.projects?.projects) && (
                      <>
                        {props.projects.projects.map((childList, i) => {
                          if (!childList.isArchived && childList.isFavorite) {
                            return (
                              <>
                                {childList.name !== "Inbox" ? (
                                  <div
                                    className="nav-link child-list"
                                    style={{
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                      color: "#000"
                                    }}
                                    key={i}
                                    onMouseEnter={() =>
                                      handleProjectListMouseEnter(i)
                                    }
                                    onMouseLeave={handleProjectMouseLeave}
                                  >
                                    <p>
                                      <NavLink to={`/project/${childList.id}`}>
                                        <span className="span-shortname">
                                          <i
                                            className="fa fa-circle"
                                            style={{
                                              color: getColorCode(
                                                childList.color
                                              ),
                                              fontSize: 10
                                            }}
                                          >
                                            {/* {childList.color} */}
                                          </i>
                                          {/* {childList.color} */}
                                        </span>

                                        <span className="span-list">
                                          {childList.name}
                                        </span>
                                      </NavLink>
                                      <img
                                        id={`project-menu-fav-${i}`}
                                        // className="fas fa-ellipsis-h"
                                        src={dotIcon}
                                        alt="img"
                                        style={
                                          isProjectHover.status &&
                                          isProjectHover.id == i
                                            ? {
                                                height: "15px",
                                                width: "15px",
                                                position: "relative",
                                                bottom: "10px",
                                                marginLeft: "23px",
                                                cursor: "pointer",
                                                display: "inline-block"
                                              }
                                            : {
                                                height: "15px",
                                                width: "15px",
                                                position: "relative",
                                                bottom: "10px",
                                                marginLeft: "23px",
                                                cursor: "pointer",
                                                display: "none"
                                              }
                                        }
                                        // onClick={() => setMoreProjectOptions(!moreProjectOptions)}
                                        onClick={() => {
                                          if (moreProjectOptions.status) {
                                            setMoreProjectOptions({
                                              status: !moreProjectOptions.status,
                                              id: "",
                                              project: ""
                                            });
                                          } else {
                                            setMoreProjectOptions({
                                              status: moreProjectOptions,
                                              id: `project-menu-fav-${i}`,
                                              project: childList
                                            });
                                          }
                                        }}
                                      />
                                      <ProjectMoreOptions
                                        isOpen={moreProjectOptions.status}
                                        popoverTarget={moreProjectOptions.id}
                                        projectData={
                                          moreProjectOptions?.project
                                            ? moreProjectOptions?.project
                                            : childList
                                        }
                                        setSectionCardFlag={() => {
                                          setSectionCardFlag(!addSectionFlag);
                                        }}
                                        isInviteCollaborators={
                                          props.isInviteCollaborators
                                        }
                                        setInviteCollaborators={data =>
                                          props.setInviteCollaborators({
                                            ...data,
                                            project: moreProjectOptions?.project
                                              ? moreProjectOptions?.project
                                              : childList
                                          })
                                        }
                                        isOwner={
                                          moreProjectOptions?.project
                                            ? moreProjectOptions?.project
                                                ?.isProjectOwner
                                            : childList.isProjectOwner
                                        }
                                        isArchived={
                                          moreProjectOptions?.project
                                            ? moreProjectOptions?.project
                                                ?.isArchived
                                            : childList.isArchived
                                        }
                                        updateArchiveProject={() =>
                                          updateArchiveProject(
                                            moreProjectOptions?.project
                                              ? moreProjectOptions?.project
                                              : childList
                                          )
                                        }
                                        isFavorite={
                                          moreProjectOptions?.project
                                            ? moreProjectOptions?.project
                                                .isFavorite
                                            : childList.isFavorite
                                        }
                                        updateisFavoriteProject={() =>
                                          updateisFavoriteProject(
                                            moreProjectOptions?.project
                                              ? moreProjectOptions?.project
                                              : childList
                                          )
                                        }
                                        toggle={() =>
                                          setMoreProjectOptions({
                                            status: !moreProjectOptions.status,
                                            id: "",
                                            project: ""
                                          })
                                        }
                                        toggleEditProject={data => {
                                          props.open_project_modal({
                                            project: data ? data : childList
                                          });
                                        }}
                                        deleteProject={() => {
                                          setMoreProjectOptions({
                                            status: false,
                                            id: "",
                                            project: ""
                                          });
                                          ToggleshowDeleteProjectAlert(true);
                                        }}
                                        leaveProject={() => {
                                          setIsLeaveProject(true);
                                          ToggleshowDeleteLabelAlert(true);
                                        }}
                                        // updateViewOptions={() => updateViewOptions()}
                                        toggleMoveProject={() =>
                                          props.toggleMoveProject(
                                            moreProjectOptions?.project
                                              ? moreProjectOptions?.project
                                              : childList
                                          )
                                        }
                                        // business count
                                        workspaceCount={
                                          props?.auth?.user?.businessCount
                                        }
                                      />
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          }
                        })}
                      </>
                    )}
                  {list &&
                    list.name == "sidebar.labels" &&
                    props.labels?.labels &&
                    props.labels.labels.length > 0 &&
                    props.labels.labels.map((childList, i) => {
                      return (
                        <>
                          <NavLink
                            to={`/label/${childList.id}`}
                            className="nav-link child-list"
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              display: "flex",
                              alignItems: "center"
                            }}
                            key={i}
                          >
                            <span className="span-shortname">
                              <div style={{ height: 18 }}>
                                <IconTint
                                  maxHeight="20"
                                  maxWidth="20"
                                  src={iconTag}
                                  style={{
                                    position: "relative",
                                    top: -1,
                                    height: "18",
                                    width: "18"
                                  }}
                                  color={getColorCode(childList.color)}
                                />

                                {/* <i
                                    className="fa fa-tag"
                                    style={{
                                      color: getColorCode(label.color)
                                    }}
                                  /> */}
                              </div>
                              {/* <i
                                className="fa fa-tag"
                                style={{
                                  color: getColorCode(childList.color),
                                  fontSize: 12
                                }}
                              >
                                {childList.color}
                              </i> */}
                            </span>

                            <p style={listNameStyle}>
                              <div style={{ display: "flex" }}>
                                <span className="span-list">
                                  {childList.name}
                                </span>
                              </div>

                              {childList.hasOwnProperty("isNew") &&
                                true(
                                  <span className="new-update-tag fs-13 bold-text">
                                    <IntlMessages id={"sidebar.new"} />
                                  </span>
                                )}
                            </p>
                          </NavLink>
                        </>
                      );
                    })}

                  {list && list.name == "sidebar.filters" && list.child
                    ? list.child.map((childList, i) => {
                        return (
                          <>
                            <NavLink
                              to={childList.routepath}
                              className="nav-link child-list"
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0
                              }}
                              key={i}
                            >
                              <span className="span-shortname">
                                <i
                                  className="fa fa-filter"
                                  style={{
                                    color: getColorCode(childList.color),
                                    fontSize: 12
                                  }}
                                >
                                  {/* {childList.color} */}
                                </i>
                              </span>

                              <p style={listNameStyle}>
                                <span className="span-list">
                                  <IntlMessages id={`${childList.listname}`} />
                                </span>

                                {childList.hasOwnProperty("isNew") &&
                                  true(
                                    <span className="new-update-tag fs-13 bold-text">
                                      <IntlMessages id={"sidebar.new"} />
                                    </span>
                                  )}
                              </p>
                            </NavLink>
                          </>
                        );
                      })
                    : ""}

                  {list.name !== "sidebar.labels" &&
                  list.name !== "sidebar.projects" &&
                  list.name !== "sidebar.favourites" &&
                  list.name !== "sidebar.filters" &&
                  list.child
                    ? list.child.map((childList, i) => {
                        return (
                          <Fragment key={i}>
                            {childList.child ? (
                              <NavSecondChild
                                CurrentRoute={CurrentRoute}
                                listNameStyle={listNameStyle}
                                childList={childList}
                                mini={mini}
                                miniDrawerWichdth={miniDrawerWidth}
                                drawerWidth={drawerWidth}
                              />
                            ) : (
                              <NavLink
                                to={childList.routepath}
                                className="nav-link child-list"
                                key={i}
                              >
                                <i
                                  style={{
                                    color: getColorCode(childList.color)
                                  }}
                                  className="fa fa-tag"
                                ></i>

                                <p style={listNameStyle}>
                                  {childList.listname}

                                  {childList.hasOwnProperty("isNew") &&
                                    true(
                                      <span className="new-update-tag fs-13 bold-text">
                                        <IntlMessages id={"sidebar.new"} />
                                      </span>
                                    )}
                                </p>
                              </NavLink>
                            )}
                          </Fragment>
                        );
                      })
                    : ""}

                  {list.name == "sidebar.projects" && (
                    <a
                      onClick={() => props.openModel()}
                      className="nav-link child-list"
                      style={{
                        cursor: "pointer"
                      }}
                    >
                      <i
                        style={{
                          color: "grey",
                          fontSize: 15
                        }}
                        // className="fas fa-plus"
                      >
                        <img
                          src={addnew}
                          style={{
                            height: "15px",
                            width: "15px"
                          }}
                        ></img>
                      </i>
                      <div style={listNameStyle}>
                        <p style={{ color: "grey" }}>
                          <IntlMessages id={"new_project"} />
                        </p>
                      </div>
                    </a>
                  )}
                  {list.name === "sidebar.projects" &&
                  props.projects.projects ? (
                    <>
                      <Fragment>
                        <ProjectArchivedSidebarList
                          listname={"Archived Projects"}
                          CurrentRoute={CurrentRoute}
                          listNameStyle={listNameStyle}
                          childList={
                            props.projects.projects && props.projects.projects
                          }
                          mini={mini}
                          miniDrawerWichdth={miniDrawerWidth}
                          drawerWidth={drawerWidth}
                          setArchivedMoreProjectOptions={
                            setArchivedMoreProjectOptions
                          }
                          archivedMoreProjectOptions={
                            archivedMoreProjectOptions
                          }
                          updateArchiveProject={updateArchiveProject}
                        />
                      </Fragment>
                    </>
                  ) : (
                    ""
                  )}
                  {list.name == "sidebar.labels" && (
                    <a
                      onClick={() => props.ToggleaddLabel()}
                      className="nav-link child-list"
                      style={{
                        cursor: "pointer"
                      }}
                    >
                      <i
                        style={{
                          color: "grey",
                          fontSize: 15
                        }}
                        // className="fas fa-plus"
                      >
                        <img
                          src={addnew}
                          style={{
                            height: "15px",
                            width: "15px"
                          }}
                        ></img>
                      </i>
                      <div style={listNameStyle}>
                        <p style={{ color: "grey" }}>
                          <IntlMessages id={"new_label"} />
                        </p>
                      </div>
                    </a>
                  )}
                </CardBody>
              </Card>
            </Collapse>
          </li>
        )}
      </>
    );
  } else {
    dynamicList = (
      <li
        style={{
          backgroundColor: list.name != "sidebar.activity" ? "white" : "",
          borderBottom:
            list.name != "sidebar.activity" ? "1px solid #EBEBEB" : ""
        }}
      >
        <NavLink
          to={
            list.name == "sidebar.activity"
              ? `/activity/${props?.auth?.user?.businessId}`
              : list.routepath
          }
          className="nav-link main-list"
          style={{
            display: "flex",
            fontWeight: list.name == "sidebar.activity" ? 600 : "",
            color: list.name == "sidebar.activity" ? "black" : ""
          }}
        >
          {list.name == "sidebar.today" ? (
            <span className="" style={{ marginRight: 5 }}>
              <img
                src={todayIcon}
                style={{
                  height: "19px",
                  width: "19px",
                  marginLeft: 8
                }}
              ></img>
              <small
                style={{
                  fontSize: "8px",
                  position: "relative",
                  left: "-14px",
                  top: 2,
                  color: "#f86b5e"
                }}
              >
                {moment(new Date()).format("DD")}
              </small>
            </span>
          ) : (
            // <i className={list.iconClass} />

            <span className=" " style={{ marginRight: 14 }}>
              <img
                // src={list.src}
                src={list.src}
                style={{
                  height: list.name != "sidebar.activity" ? "18px" : "16px",
                  width: list.name != "sidebar.activity" ? "19px" : "20px",
                  marginLeft: 8
                }}
              ></img>
            </span>
          )}
          {
            <p style={{ fontSize: "14px" }}>
              {<IntlMessages id={list.name} />}
              {list.hasOwnProperty("isNew") && list["isNew"] && (
                <span className="new-update-tag fs-13 bold-text">
                  <IntlMessages id={"sidebar.new"} />
                </span>
              )}
            </p>
          }
        </NavLink>
      </li>
    );
  }

  return <Fragment>{dynamicList}</Fragment>;
};

export default NavList;
