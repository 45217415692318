const authActions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_TYPE: "UPDATE_USER_TYPE",
  UPDATE_USER_DETAILS: "UPDATE_USER_DETAILS",
  UPDATE_SETTINGS: "UPDATE_SETTINGS",
  UPDATE_TOKEN: "UPDATE_TOKEN",
  UPDATE_BUSINESS: "UPDATE_BUSINESS",
  UPDATE_BUSINESS_ARCHIVE: "UPDATE_BUSINESS_ARCHIVE",

  login: data => {
    return {
      type: authActions.LOGIN,
      isLogin: true,
      accessToken: data.token,
      user: data.user,
      accessId: data.deviceId
    };
  },
  logout: () => {
    return {
      type: authActions.LOGOUT,
      isLogin: false,
      accessToken: null
    };
  },
  update_User: user => {
    return {
      type: authActions.UPDATE_USER,
      user: user
    };
  },
  update_user_type: status => {
    return {
      type: authActions.UPDATE_USER_TYPE,
      newUser: status
    };
  },
  update_User_details: user => {
    return {
      type: authActions.UPDATE_USER_DETAILS,
      user: user
    };
  },
  update_Settings: settings => {
    return {
      type: authActions.UPDATE_SETTINGS,
      userSetting: settings
    };
  },
  update_token: token => {
    return {
      type: authActions.UPDATE_TOKEN,
      token: token
    };
  },
  update_business: business => {
    return {
      type: authActions.UPDATE_BUSINESS,
      business
    };
  },
  update_business_archive: isArchived => {
    return {
      type: authActions.UPDATE_BUSINESS_ARCHIVE,
      isArchived: isArchived
    };
  }
};

export default authActions;
