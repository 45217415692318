import React, { useState } from "react";
import { editPencil, maxDescriptionLength } from "helper/constant";
import Shimmer from "components/shimmer/workspcaeShimmerHeader";
import { getUserCharColor } from "helper/methods/PlaceholderColor";
import { connect } from "react-redux";
import ShimmerAvatar from "components/shimmer/workspaceAvatar";
import Loader from "components/loader/Loader";
import IntlMessages from "util/intlMessages";
import { truncateDescription } from "helper/methods/utilFunctions";
const WorkspaceHeader = props => {
  const {
    tabs,
    activeTab,
    toggletab,
    workspaceDetails,
    toggleEditBusiness,
    isLoader,
    archiveBusiness,
    isArchiveLoader,
    isOwner
  } = props;
  const [showMore, setShowMore] = useState(false);

  const workspaceDescription =
    workspaceDetails && workspaceDetails?.description
      ? workspaceDetails?.description
      : "";

  return (
    <div>
      <div className="workspace-details-header">
        <div>
          {workspaceDetails && workspaceDetails?.isArchived && (
            <div className="workspace-archived">
              <div className="workspace-archived-border flex-x fs-14">
                <IntlMessages id={"workspace.workspacearchive1"} />
                <div
                  style={{
                    // textDecoration: "underline",
                    cursor: "pointer",
                    display: "flex"
                  }}
                  onClick={archiveBusiness}
                >
                  &nbsp;
                  <IntlMessages id={"project.archived"} />
                  .&nbsp;
                </div>
                {isOwner && (
                  <>
                    <IntlMessages id={"project.clickhereto"} />
                    &nbsp;
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        display: "flex"
                      }}
                      onClick={archiveBusiness}
                    >
                      <b>
                        <IntlMessages id={"project.Unarchive"} />
                      </b>
                      {isArchiveLoader && <Loader height="20px" width="16px" />}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          <div className="workspace-details-wrapper">
            {isLoader ? (
              <div className="flex-x">
                <ShimmerAvatar />
                <Shimmer />
              </div>
            ) : (
              <>
                <div style={{ display: "flex" }}>
                  <div className="workspace-Avatar">
                    {workspaceDetails && workspaceDetails?.businessMeta ? (
                      <img
                        className="workspace-logo"
                        src={workspaceDetails?.businessMeta?.thumbnail}
                        alt=""
                      />
                    ) : (
                      <div
                        className=" workspace-placeholder"
                        style={{
                          // background: props.topbarTheme.buttonColor,
                          background: getUserCharColor(
                            workspaceDetails?.title?.charAt(0)
                          )
                        }}
                      >
                        {workspaceDetails &&
                          workspaceDetails?.title[0]?.toUpperCase()}
                      </div>
                    )}
                  </div>

                  <div
                    className="workspace-details-contents"
                    style={{ width: "470px" }}
                  >
                    <div
                      className="workspace-title"
                      style={{
                        color: "black",
                        width: "100%",
                        overflowWrap: "break-word",
                        lineHeight: "1"
                      }}
                    >
                      {workspaceDetails && workspaceDetails?.title}
                    </div>
                    <div
                      className={`workspace-description ${
                        !showMore ? "project-description-showmore" : ""
                      }`}
                      style={{
                        width: "100%",
                        overflowWrap: "break-word",
                        lineHeight: "1",
                        overflow: "hidden",
                        WebkitLineClamp:
                          !showMore &&
                          workspaceDescription.length > maxDescriptionLength
                            ? 5
                            : "unset"
                      }}
                      dangerouslySetInnerHTML={{
                        __html: showMore
                          ? workspaceDescription
                          : truncateDescription(
                              workspaceDescription,
                              maxDescriptionLength
                            )
                      }}
                    ></div>
                    {workspaceDescription.length > maxDescriptionLength && (
                      <a
                        style={{
                          fontSize: "12px",
                          width: "fit-content",
                          display: "flex",
                          flexFlow: "row wrap",
                          paddingBottom: "10px",
                          lineHeight: "0.7",
                          fontFamily:
                            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Notosans,Ubuntu,Droid sans,Helvetica Neue,sans-serif"
                        }}
                        href=""
                        onClick={e => {
                          e.preventDefault();
                          setShowMore(!showMore);
                        }}
                      >
                        {!showMore ? (
                          <IntlMessages id="Show-more" />
                        ) : (
                          <IntlMessages id="Show-less" />
                        )}
                      </a>
                    )}
                    <div
                      className="edit-workspace workspace-bg"
                      style={{ width: "fit-content" }}
                      onClick={toggleEditBusiness}
                    >
                      <div
                        style={{
                          background: "#EAECF0",
                          color: "#808080",
                          width: "100%",
                          display: "inline-flex"
                        }}
                      >
                        <span>
                          <img
                            src={editPencil}
                            style={{
                              paddingRight: 10,
                              height: "16px",
                              display: "inline-block"
                            }}
                            alt=""
                          />
                        </span>
                        <IntlMessages id={"workspace.editworkspace_details"} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="workspace-action-nav">
        <ul className="ul-no-margin">
          <li
            className={`workspace-action-nav-items ${
              activeTab == tabs[0] ? "tab-active " : ""
            }`}
            onClick={() => toggletab(tabs[0])}
          >
            <span style={{ fontWeight: 500, fontSize: "15px", color: "black" }}>
              <div>
                <IntlMessages id={"globalsearchbar.projects"} />{" "}
              </div>
            </span>
          </li>
          <li
            className={`workspace-action-nav-items ${
              activeTab == tabs[1] ? "tab-active " : ""
            }`}
            onClick={() => toggletab(tabs[1])}
          >
            <span style={{ fontWeight: 500, fontSize: "15px", color: "black" }}>
              <div>
                <IntlMessages id={"projectdetails.members"} />{" "}
              </div>
            </span>
          </li>
          <li
            className={`workspace-action-nav-items ${
              activeTab == tabs[2] ? "tab-active " : ""
            }`}
            onClick={() => toggletab(tabs[2])}
          >
            <span style={{ fontWeight: 500, fontSize: "15px", color: "black" }}>
              <div>
                <IntlMessages id={"projectdetails.settings"} />{" "}
              </div>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, null)(WorkspaceHeader);
