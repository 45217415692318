import handleResponse, { encryptPayload } from "./handleResponse";
import { BASE_URL, Business } from "./apiConstants";
import { getHeader, getHeaderForProfile } from "./getHeaders";

export function getWorkspaceDetailsByid(id) {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + Business + `${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function getBusinessList(isOwner = false) {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT +
      "business" +
      `${isOwner ? "?type=OWNER" : ""}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function getAllBusiness(token, limit = 10, page = 1) {
  var givenToken = null;
  if (token) {
    givenToken = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    givenToken = getHeader();
  }
  const requestOptions = {
    method: "GET",
    headers: givenToken
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT +
      "business" +
      "/with-project" +
      `?limit=${limit}&page=${page}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function swicthBusiness(business_id) {
  const flag = { isDefault: true };
  const encryptedData = encryptPayload(flag);
  const encryptObj = { encPayload: encryptedData };

  const requestOptions = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT +
      Business +
      `defaultbusiness/${business_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function toggleArchive(business_id) {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + Business + `archive/${business_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function addBusiness(data) {
  const form = new FormData();

  if (data.businessImage !== "") {
    form.append("businessImage", data.businessImage);
  }

  form.append("title", data.title);
  form.append("description", data.description || "");

  const requestOptions = {
    method: "POST",
    headers: getHeaderForProfile(),
    body: form
  };
  return fetch(process.env.REACT_APP_API_ENDPOINT + Business, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function updateBusiness(data, id) {
  const form = new FormData();
  console.log("data", data);
  if (data.businessImage !== "" && data.businessImage !== undefined) {
    form.append("businessImage", data?.businessImage);
  }
  if (data.title !== "" && data.title !== undefined) {
    form.append("title", data.title);
  }

  form.append("isDeleteBusinessPic", data.isDeleteBusinessPic);
  form.append(
    "description",
    data.description &&
      data.description !== undefined &&
      data.description !== null
      ? data.description
      : ""
  );
  const requestOptions = {
    method: "PUT",
    headers: getHeaderForProfile(),
    body: form
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + Business + `${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function getBusinessProject() {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + Business + "projects",
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
