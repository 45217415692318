import moment from "moment";

export const getKeyIndexOf = (array, keyId, findKey) => {
  if (array?.length) {
    var index = array.map(o => o[keyId].id).indexOf(findKey);
    return index;
  }
  return -1;
};
export const getIndexOf = (array, findKey) => {
  if (array?.length) {
    var index = array.map(o => o.id).indexOf(findKey);
    return index;
  }
  return -1;
};
export const objectToArray = groups => {
  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map(key => {
    return {
      project: groups[key].project,
      task: groups[key].task
    };
  });
  return groupArrays;
};
export const doGroupByproject = array => {
  try {
    return array.reduce((result, currentValue) => {
      const keyDate = `project_${currentValue.project.id}`;
      if (!result[keyDate]) {
        result[keyDate] = { task: [], project: currentValue.project };
      }
      result[keyDate].task.push(currentValue);
      return result;
    }, {});
  } catch (err) {
    return {
      status: false
    };
  }
};
export const getDistinctArrayDataProject = (
  currentAssignment,
  newAssignment
) => {
  //  mergin data for elements which have  same type of project  but are more than one array elemnt to one
  let currentAssignmentData = { ...currentAssignment };
  if (newAssignment && currentAssignment) {
    Object.keys(newAssignment).map(function(objectKey, index) {
      if (
        currentAssignment[objectKey].project.id ==
        newAssignment[objectKey].project.id
      ) {
        currentAssignmentData[objectKey].task = [
          ...currentAssignment[objectKey].task,
          ...newAssignment[objectKey].task
        ];
      } else {
        currentAssignmentData[objectKey] = newAssignment[objectKey];
      }
    });
    return currentAssignmentData;
  }
};
export const groupDataToTime = (data = [], keyname_ = "activity") => {
  // this gives an object with dates as keys
  try {
    const groups = data.reduce((groups, activity) => {
      // const date = moment(activity.createdAt).format("DD-MM-YYYY[T]HH:mm a");
      const keyDate = moment(activity.scheduledAt).format("DD MMM YYYY");
      if (!groups[keyDate]) {
        groups[keyDate] = [];
      }
      groups[keyDate].push(activity);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map(date => {
      return {
        date,
        [keyname_]: groups[date]
      };
    });

    return { status: true, data: groupArrays };
  } catch (e) {
    return {
      status: false
    };
  }
};

export const truncateDescription = (description, maxLength) => {
  if (description.length <= maxLength) {
    return description;
  }

  const truncatedText = description.substring(0, maxLength);
  const lastSpaceIndex = truncatedText.lastIndexOf(" ");

  const spaceWithinRange =
    lastSpaceIndex > maxLength - 20 && lastSpaceIndex < maxLength;

  if (spaceWithinRange) {
    return truncatedText.substring(0, lastSpaceIndex);
  }

  return truncatedText;
};
