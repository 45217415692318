import React, { useEffect, useState } from "react";
import ColorDropdown from "components/projects/ColorDropdown";
import { colourStyles, ProjectColors } from "./utils/color";
import ChipInput from "material-ui-chip-input";
import ToastMessage from "components/notifications/ToastMessage";
import "./collaborators.style.css";
import { useToasts } from "react-toast-notifications";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
function InviteTeamModalForm({
  setIsDisabled,
  selectedColor,
  setSelectedColor,
  setProjectName,
  projectName,
  inputref,
  addParticipant,
  setAddParticipant,
  currentLoggedInUser,
  language
}) {
  const { addToast } = useToasts();
  // validate Project name space
  const [isProjectNameError, seisProjectNameError] = useState({
    status: false,
    message: ""
  });

  const [
    validateEmailAndCollaborator,
    setValidateEmailAndCollaborator
  ] = useState({
    status: false,
    message: ""
  });

  const [checkErrorEmailPresent, setCheckErrorEmailPresent] = useState(false);
  const [chipCount, setChipCount] = useState(0);
  const [isEmailValid, setIsEmailValid] = useState({
    status: false,
    message: null
  });
  const [appName, setAppName] = useState();

  useEffect(() => {
    const storeAppname = JSON.parse(localStorage.getItem("appname"));
    setAppName(storeAppname);
  }, []);
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const [dropdownInputValue, setDropdownInputValue] = useState("");
  const handleProjectNameValidatiton = e => {
    const validateName = () => {
      const validate = e.target.value.match(/^\S+\w{0,50}\S{0,}/g);
      if (
        e.target.value.length > 1 &&
        e.target.value.length < 51 &&
        validate !== null &&
        validate
      ) {
        seisProjectNameError({ status: false, message: null });
        return true;
      }
      if (e.target.value.length > 50) {
        seisProjectNameError({
          status: true,
          message: "Maximum 50 characters are allowed"
        });
        return false;
      }
      if (e.target.value.length <= 0)
        seisProjectNameError({
          status: true,
          message: <IntlMessages id={"workspace.nameisrequired"} />
        });
      return false;
    };
    setProjectName(e.target.value);
    validateName() ? setIsDisabled(false) : setIsDisabled(true);
  };
  const isEmailAlreadyParticipant = email => {
    if (email === currentLoggedInUser.email) {
      return true;
    }
    return false;
    // const participant = collaboratorArr;
    // var isExist = false;
    // participant.map(data => {
    //   if (data.user && email == data.user.email) isExist = true;
    //   if (!data.user && data.requestedEmail == email) isExist = true;
    // });

    // return isExist;
  };
  const ErrorEmailValidation = () => {
    return (
      <>
        {isEmailValid.message && isEmailValid.message?.length ? (
          <span className={"error-msg"} style={{ fontWeight: 300 }}>
            {isEmailValid.message}
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  const CUSTOM_THEME = {
    chipsContainer: {
      display: "flex",
      position: "relative",
      //the next 3 are the styles I'm overriding
      border: "1px solid red",
      borderBottom: "1px solid black",
      marginBottom: 14,
      backgroundColor: "#fff",
      font: "13.33333px Arial",
      minHeight: 24,
      alignItems: "center",
      flexWrap: "wrap",
      padding: "2.5px"
    },
    container: {
      flex: 1
    },
    containerOpen: {},
    input: {
      border: "none",
      outline: "none",
      boxSizing: "border-box",
      width: "100%",
      padding: 5,
      margin: 2.5
    },
    suggestionsContainer: {},
    suggestionsList: {
      position: "absolute",
      border: "1px solid #ccc",
      zIndex: 10,
      left: 0,
      top: "100%",
      width: "100%",
      backgroundColor: "#fff",
      listStyle: "none",
      padding: 0,
      margin: 0
    },
    suggestion: {
      padding: "5px 15px"
    },
    suggestionHighlighted: {
      background: "#ddd"
    },
    sectionContainer: {},
    sectionTitle: {}
  };

  const handleShowError = emailid => {
    if (emailid.length > 0) {
      let spaceremove = dropdownInputValue.substr(0, 1);
      if (spaceremove == " " || spaceremove == ",") {
        setDropdownInputValue("");
      }
      let givenEmail = handleCharactersValidation(emailid);
      if (isEmailValidate(givenEmail)) {
        if (isEmailAlreadyParticipant(givenEmail)) {
          setValidateEmailAndCollaborator({
            status: true,
            message: <IntlMessages id={"workspace.email_collaborator"} />
          });
        } else if (isEmailExist(givenEmail)) {
          setValidateEmailAndCollaborator({
            status: true,
            message: (
              <IntlMessages id={"project.project_email_is_already_present"} />
            )
          });
        } else {
          setValidateEmailAndCollaborator({
            status: false,
            message: ""
          });
        }
      } else {
      }
    } else {
    }
  };
  const handleChangeChip = chip => {
    if (!isEmailAlreadyParticipant(chip) && !isEmailExist(chip)) {
      if (addParticipant) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
      setChipCount(addParticipant.length + 1);
      setAddParticipant([...addParticipant, chip]);
    } else if (isEmailExist) {
      // setIsEmailValid({
      //   status: false,
      //   message: "email is already in present"
      // });
      showNotifications(
        <IntlMessages id={"project.project_email_is_already_present"} />,
        "error"
      );
    } else {
      // setIsEmailValid({
      //   status: false,
      //   message: "Email is already collaborator"
      // });
      showNotifications(
        <IntlMessages id={"workspace.email_collaborator"} />,
        "error"
      );
    }
  };
  const handleDeleteChip = (email, index) => {
    var emailArr = [...addParticipant];
    emailArr.splice(index, 1);
    setChipCount(emailArr?.length);
    if (emailArr.length == 0) {
      setIsDisabled(true);
    }
    setAddParticipant(emailArr);
  };
  const isEmailExist = chip => {
    var status = false;
    addParticipant.map(data => {
      if (data === chip) {
        status = true;
      }
    });
    return status;
  };
  const isEmailValidate = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const handleSpaceComma = e => {
    if (
      e.keyCode == 188 ||
      e.keyCode == 32 ||
      e.keyCode == 13 ||
      e.keyCode == 9
    ) {
      setDropdownInputValue("");
    }
    if (isEmailExist(e.target.value)) {
      setDropdownInputValue("");
      setCheckErrorEmailPresent(true);
    } else {
      setCheckErrorEmailPresent(false);
    }
  };

  const handleEmailValidation = email => {
    let givenEmail = handleCharactersValidation(email);
    if (isEmailValidate(givenEmail)) {
      setIsEmailValid({ status: true, message: null });
      handleChangeChip(givenEmail);
    } else {
      //   setIsEmailValid(false);
      setIsEmailValid({
        status: false,
        message: "Please enter valid email example@email.com"
      });
    }
  };
  // remove spaces and comma which are entered by user tio generate chips
  const handleCharactersValidation = email => {
    let givenEmail = email;
    if (removeComma(givenEmail)) {
      givenEmail = removeComma(email);
    }
    givenEmail = removeSpace(givenEmail);
    return givenEmail;
  };
  const removeSpace = email => {
    return email.replace(/\s/g, "");
  };
  const removeComma = email => {
    if (email.includes(",")) {
      const checkIndex = email.indexOf(",");
      if (checkIndex > -1) {
        let givenEmail = email;
        givenEmail = email.slice(checkIndex + 1, email.length);
        return givenEmail;
      }
      return 0;
    }
  };

  return (
    <div>
      <div className="form-group">
        <label className="text-muted" style={{ marginTop: "10px" }}>
          <span style={{ fontSize: 14, color: "#808080" }}>
            {" "}
            {appName?.data?.appName}&nbsp;
            <IntlMessages
              id={"globalworkspacelist.inviteworkspacedecription1"}
            />
          </span>
          {/* Workplate makes teamwork your best work. Invite your new team members to
          get going! */}
        </label>
      </div>
      <div className="form-group" style={{ marginTop: "40px" }}>
        {/* Workspace members */}
        <span style={{ fontSize: 14, fontWeight: 600, color: "black" }}>
          <label>
            {" "}
            <IntlMessages id={"globalworkspacelist.inviteworkspacemembers"} />
          </label>
        </span>
        <div
          className=""
          style={{
            border: " 1px solid #EBEBEB",
            borderRadius: "3px",
            paddingTop: 6
          }}
        >
          <ChipInput
            // theme={CUSTOM_THEME}
            variant="outlined"
            className="changesfieldset"
            ref={inputref}
            value={addParticipant}
            autoFocus
            onBlur={handleSpaceComma}
            id="participantemail"
            name="participantemail"
            onKeyUp={handleSpaceComma}
            inputValue={dropdownInputValue}
            onBeforeAdd={e => handleEmailValidation(e)}
            onChange={chips => {
              handleChangeChip(chips);
            }}
            onUpdateInput={e => {
              if (
                e.target.value.length == 0 ||
                e.target.value == " " ||
                e.target.value == ","
              ) {
                setIsEmailValid(true);
              }
              handleShowError(e.target.value);
              setDropdownInputValue(e.target.value);
            }}
            clearInputValueOnChange={true}
            onDelete={(chip, index) => {
              handleDeleteChip(chip, index);
            }}
            InputProps={{
              style: {
                display: "flex",
                alignItems: "center",
                paddingTop: "0px",
                borderRadius: "0 !important"
              }
            }}
            newChipKeyCodes={[13, 32, 188, 9]}
            style={{
              width: "100%",
              fontSize: "14px"
            }}
            placeholder={InjectPlaceHolderMessage(
              "globalworkspacelist.inviteworkspaceplaceholder",
              language
            )}
          />
        </div>
        {!isEmailValid.status ? (
          <ErrorEmailValidation field="name" />
        ) : (
          // : checkErroralAeadyCollaborator == true ? (
          //   <span className={"error-msg"} style={{ fontWeight: 300 }}>
          //     Email is already collaborator
          //   </span>
          // ) : checkErrorEmailPresent == true ? (
          //   <span className={"error-msg"} style={{ fontWeight: 300 }}>
          //     Email is already present
          //   </span>
          // )
          ""
        )}
        {/* <div>"example1@email.com, example2@email.com"</div> */}
      </div>

      <div className="form-group">
        <label
          className="text-muted"
          style={{ marginTop: "15px", marginBottom: "60px" }}
        >
          <span style={{ fontSize: 14, color: "#808080" }}>
            <b style={{ color: "black", fontWeight: 600 }}>
              <IntlMessages id={"globalworkspacelist.protip!"} />
            </b>{" "}
            <IntlMessages
              id={"globalworkspacelist.inviteworkspacedecription2"}
            />
          </span>
        </label>
      </div>
    </div>
  );
}

export default InviteTeamModalForm;
