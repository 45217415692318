import handleResponse, { encryptPayload } from "./handleResponse";
import {
  BASE_URL,
  project,
  invitecollaborators_,
  label_,
  comment_,
  task_
} from "./apiConstants";

import { getHeader, getHeaderForProfile } from "./getHeaders";

export function addTodo(data) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(process.env.REACT_APP_API_ENDPOINT + task_, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export function updateTodo(data, id) {
  const encryptedData = encryptPayload(data);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + `${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
export function deleteTodo(id, project_id) {
  const id_flag = {
    project_id: project_id.toString(),
    isDeleted: true
  };
  const encryptedData = encryptPayload(id_flag);
  const encryptObj = { encPayload: encryptedData };
  const requestOptions = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(encryptObj)
  };
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + task_ + `${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
