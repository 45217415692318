/** @format */

import React, { useEffect, useState } from "react";
import TodolistWrapper from "./todolistWrapper.style";
import moment from "moment";
import IconTint from "react-icon-tint";
import TaskMenu from "components/projects/Menus/TaskMenu";
import { ReactComponent as RepeatSvgIcon } from "assets/images/recurrenceIcon.svg";
import { deleteTodo } from "helper/services/todoService";
import CustomAlert from "util/CustomAlert";
import { getColorCode } from "util/data/ProjectColors";
import {
  commentnew,
  iconTag,
  threeDot_Icon,
  ListGridCheckImage,
  ListGridCircleImage,
  datesnewmodal
} from "helper/constant";
import { getPriorityPath } from "constatnt";
import { allPriority } from "constatnt";
import NewTaskModal from "components/newTask/NewTaskModal";
import NewTask from "components/newTask/NewTask";
import TaskAssignModal from "components/projects/collaborators/TaskAssignModal";
import TaskAssignee from "components/assignee/TaskAssignee";
import profileModelAction from "redux/profileModel/actions";
import DatePickerNoInput from "components/DatePickers/DatePickerNoInput";
import AssigneeDropdown from "components/newTask/AssigneeDropdown";
import { onChangeTaskAssign } from "helper/methods/QuickActions";
import PriorityPopover from "components/priority/priorityPopover";
import { repeatTypeOptions } from "util/data/taskDetailsutilData";
import {
  doGroupByproject,
  groupDataToTime
} from "helper/methods/utilFunctions";
import "assets/css/taskdetailspopover.css";

import LinearLoader from "components/loader/LinearLoader";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
const { open_profile_modal, close_profile_modal } = profileModelAction;

const TodayListView = props => {
  const [todo, SetTodo] = useState(null);
  const [activeTaskDetail, setActiveTaskDetail] = useState(null);
  const [taskMenu, setTaskMenu] = useState(false);
  const [activeTaskMenu, setActiveTaskMenu] = useState(null);
  const [isCustomAlert, setCustomAlert] = useState(false);
  const sectionalertMessage = "Task will be deleted!";
  const [overdueListName, setOverdueListName] = useState("");
  const [isAssignTaskModal, SetisAssignTaskModal] = useState(false);
  const [isnewTask, setIsNewTask] = useState(false);
  const [isDataFlag, setIsDataFlag] = useState(false);
  const {
    overDuePages,
    isViewMoreLoader,
    handleViewMoreOverdueTasks,
    doCloseQuickAction,
    doUpdateTaskPriority,
    doUpdateTaskScheduleDate,
    doUpdateTaskAssign,
    isQuickActionTask,
    setIsQuickActionTask
  } = props;
  const [projectArr, setProjectArr] = useState([]);
  const [taskProject, setTaskProject] = useState("");
  const [isAlertAction, setIsAlertAction] = useState({
    status: false,
    data: ""
  });

  const {
    isEditTask,
    setIsEditTask,
    updateTaskHandler,
    taskDetail,
    updateActiveTaskDetail
  } = props;

  useEffect(() => {
    if (props.todoList?.length) {
      const taskDataarr =
        props.todoList &&
        props.todoList.filter(data => !data.isDeleted && !data.isArchived);
      let overdueTasksarr = groupDataToTime(taskDataarr, "data");

      if (overdueTasksarr.status) {
        let groudedTaskData = [...overdueTasksarr.data];
        overdueTasksarr.data &&
          overdueTasksarr.data.map((mapData, index) => {
            if (mapData.data) {
              groudedTaskData[index].data = doGroupByproject(mapData.data);
            }
          });
        SetTodo(groudedTaskData);
      }
      setIsDataFlag(true);
    }
  }, [props.todoList]);
  const doOpenTaskDetail = data => {
    props.history.push(`/task-details/${data.id}`);
  };

  const manipulateData = todoArr => {
    if (todoArr) {
      var dataArr = [];
      Promise.all(
        todoArr &&
          todoArr.map(todos => {
            var isDataFlag = false;
            dataArr.map(check => {
              if (check?.name == todos?.project?.name) {
                isDataFlag = true;
              }
            });
            if (!isDataFlag) {
              dataArr.push({
                name: todos?.project?.name,
                color: todos?.project?.color
              });
            }
          })
      );
    }

    dataArr = [...new Set(dataArr)];

    setProjectArr(dataArr);
  };
  const doArchiveTask = async (id, projectId) => {
    updateTaskHandler(
      {
        task_id: id.toString(),
        project_id: projectId?.toString(),
        isArchived: true
      },
      false
    );
  };
  const updateisCompleted = async (projectId, todoId, e, data) => {
    props.updateIsCompleted(projectId, todoId, e.target.checked, data);
  };
  const deleteTask = async (id, projectId) => {
    const Response = await deleteTodo(id, projectId);
    if (Response) {
      if (Response.error) {
      }
      if (Response.data) {
        if (Response.data.isDeleted) {
          removeTaskFromList(Response.data.id);
          props.showNotifications(
            <IntlMessages id={"project.taskdeleted"} />,
            "success"
          );
          props.removeTaskFromList(Response.data.id);
        }
      }
    }
  };

  const removeTaskFromList = id => {
    const TodoArr = todo;

    TodoArr.map((data, i) => {
      if (data.id == id) {
        TodoArr.splice(i, 1);
      }
    });
    manipulateData(TodoArr);
    SetTodo(TodoArr);
  };
  const toggleTaskMenu = (target, taskData) => {
    if (taskMenu) {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(null);
      setActiveTaskDetail(null);
    } else {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(target);

      setActiveTaskDetail(taskData);
    }
  };
  const getTodoStyle = (id, i, data) => {
    if (id == isQuickActionTask.taskId && isQuickActionTask.status) {
      return {
        background: "white",
        display: "flex",
        alignItems: "flex-start",
        // borderBottom: data - 1 == i
        // ? ""
        // : "1px solid #ebebeb",
        boxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        webkitBoxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        mozBoxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        border: "1px solid lightgray",
        transition: "box-shadow 0.3s ease-in-out"
      };
    }
    return {
      background: "white",
      display: "flex",
      // borderBottom: data - 1 == i
      //   ? ""
      //   : "1px solid #ebebeb",
      alignItems: "flex-start",
      transition: "box-shadow 0.3s ease-in-out"
    };
  };

  const handleRedirection = (item, newBusinessId) => {
    if (item.redirection_type && item.redirection_type === "task") {
      if (item.project.id && item.task.id && item.business.id) {
        return props.history.push(`/task-details/${item.task.id}`);
      }
      return false;
    }
    if (item.redirection_type && item.redirection_type === "taskcomment") {
      if (item.project.id && item.task.id && item.business.id) {
        return props.history.push(`/task-details/${item.task_id}`);
      }
      return false;
    }
    if (item.redirection_type && item.redirection_type === "business") {
      if (item.business.id) {
        return props.history.push(`/business/${item.business.id}`);
      }
      return false;
    }
    if (item.redirection_type && item.redirection_type === "project") {
      if (item.project.id) {
        return props.history.push(`/project/${item.project.id}`);
      }
      return false;
    }
  };
  return (
    <div>
      <TodolistWrapper>
        <div className=" ">
          {isDataFlag && todo && todo.length > 0 ? (
            <div>
              <div className="roe-card-style">
                <div
                  className="roe-card-header "
                  style={{ paddingTop: 20, paddingLeft: 0 }}
                >
                  <span
                    style={{ fontWeight: 600, color: "#d4442e", fontSize: 14 }}
                  >
                    {<IntlMessages id={"today.overdue"} />}
                  </span>
                </div>
              </div>
              <div
                className=""
                style={{
                  backgroundColor: "white",
                  padding: "20px 30px 0px",
                  borderRadius: 10
                }}
              >
                {todo &&
                  todo.map((dateElement, i) => {
                    return (
                      <div
                        className="row"
                        style={{
                          paddingBottom: 5,
                          paddingTop: 5,
                          justifyContent: "flex-end",
                          borderBottom:
                            todo.length - 1 == i ? "" : "1px solid #ebebeb"
                        }}
                      >
                        <div
                          className=" col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12 pa-0"
                          style={{
                            color: "#877457",
                            fontWeight: 600,
                            fontSize: 13
                          }}
                        >
                          {" "}
                          {dateElement.date}
                        </div>

                        {Object.keys(dateElement.data).length > 0 &&
                          Object.keys(dateElement.data).map((project, i) => {
                            return (
                              <div className="col-lg-11 col-md-11 col-sm-12 col-xs-12 col-12 pa-0 pb-10">
                                <div
                                  onClick={() =>
                                    handleRedirection &&
                                    handleRedirection({
                                      redirection_type: "project",
                                      project: dateElement.data[project].project
                                    })
                                  }
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer"
                                  }}
                                >
                                  <i
                                    className="fa fa-circle fs-10 "
                                    style={{
                                      color: getColorCode(
                                        dateElement.data[project].project.color
                                      ),
                                      paddingRight: 5
                                    }}
                                  />
                                  <div
                                    title={
                                      dateElement.data[project].project.name
                                    }
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 600
                                    }}
                                  >
                                    {dateElement.data[project].project.name}
                                  </div>
                                </div>
                                <div>
                                  {dateElement.data[project].task &&
                                    dateElement.data[project].task.map(
                                      (data, i) => {
                                        return (
                                          <div>
                                            <>
                                              {isEditTask?.status &&
                                              isEditTask?.data?.id ===
                                                data.id &&
                                              isEditTask?.isOverdue ? (
                                                <div
                                                  style={{
                                                    paddingTop: 5,
                                                    backgroundColor: "white",
                                                    borderRadius: "5px",
                                                    border: "1px solid #c6c2c2",
                                                    position: "relative",
                                                    margin: "10px 0px "
                                                  }}
                                                >
                                                  <NewTask
                                                    updateTask
                                                    action={
                                                      isEditTask?.action || ""
                                                    }
                                                    toggleAction={() =>
                                                      setIsEditTask({
                                                        ...isEditTask,
                                                        action: ""
                                                      })
                                                    }
                                                    collaboratorList={
                                                      props.collaboratorList
                                                    }
                                                    toggle={() => {
                                                      setIsEditTask({
                                                        status: false,
                                                        data: ""
                                                      });
                                                    }}
                                                    taskData={isEditTask.data}
                                                    addTaskHandler={
                                                      updateTaskHandler
                                                    }
                                                    taskProject={taskProject}
                                                    setTaskProject={data =>
                                                      setTaskProject(data)
                                                    }
                                                  />
                                                </div>
                                              ) : (
                                                <div
                                                  key={i}
                                                  className="flex-x  align-center  todo-board"
                                                  style={getTodoStyle(
                                                    data.id,
                                                    i,
                                                    dateElement.data[project]
                                                      .task.length
                                                  )}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flex: 1
                                                    }}
                                                  >
                                                    <div
                                                      className="flex-1"
                                                      style={{
                                                        cursor: "pointer"
                                                      }}
                                                    >
                                                      <div className="flex-x ">
                                                        <div
                                                          className="ptb-7"
                                                          style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            alignSelf: "center",
                                                            borderBottom:
                                                              dateElement.data[
                                                                project
                                                              ].task.length -
                                                                1 ==
                                                              i
                                                                ? ""
                                                                : "1px solid #ebebeb"
                                                          }}
                                                        >
                                                          <div
                                                            className=" pretty p-icon p-round p-smooth"
                                                            style={{
                                                              marginTop: 4
                                                            }}
                                                          >
                                                            <input
                                                              type="checkbox"
                                                              checked={
                                                                data.isCompleted
                                                              }
                                                              onChange={e =>
                                                                updateisCompleted(
                                                                  data.project
                                                                    .id,
                                                                  data.id,
                                                                  e,
                                                                  data
                                                                )
                                                              }
                                                            />
                                                            {!data.isCompleted ? (
                                                              <img
                                                                alt=""
                                                                src={
                                                                  ListGridCircleImage
                                                                }
                                                                style={{
                                                                  height: 18,
                                                                  width: 18
                                                                }}
                                                              ></img>
                                                            ) : (
                                                              <img
                                                                alt=""
                                                                src={
                                                                  ListGridCheckImage
                                                                }
                                                                style={{
                                                                  height: 18,
                                                                  width: 18
                                                                }}
                                                              ></img>
                                                            )}
                                                          </div>
                                                          <div className="flex-1">
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "flex-start"
                                                              }}
                                                            >
                                                              <div className="flex-x flex-wrap  fs-14 flex-1">
                                                                <div
                                                                  className="flex-1"
                                                                  style={{
                                                                    display:
                                                                      "inline-flex",
                                                                    flexWrap:
                                                                      "wrap",
                                                                    alignItems:
                                                                      "center"
                                                                  }}
                                                                >
                                                                  <span
                                                                    className="All_Task_Title fs-14"
                                                                    style={{
                                                                      maxWidth:
                                                                        "50%",
                                                                      color:
                                                                        "black",
                                                                      fontSize:
                                                                        "14px",
                                                                      margin:
                                                                        "3px 25px  3px 0px",
                                                                      wordBreak:
                                                                        "break-word",
                                                                      display:
                                                                        "-webkit-box",
                                                                      WebkitBoxOrient:
                                                                        "vertical",
                                                                      lineClamp: 1,
                                                                      textOverflow:
                                                                        "ellipsis",
                                                                      overflow:
                                                                        "hidden",
                                                                      WebkitLineClamp: 1,
                                                                      WebkitFontSmoothing:
                                                                        "antialiased"
                                                                    }}
                                                                    onClick={() => {
                                                                      doOpenTaskDetail(
                                                                        data
                                                                      );
                                                                    }}
                                                                  >
                                                                    {data.title}
                                                                  </span>
                                                                  {data &&
                                                                    data.priority &&
                                                                    data.priority !==
                                                                      "no_priority" && (
                                                                      <>
                                                                        {allPriority.map(
                                                                          item => {
                                                                            if (
                                                                              item.name ==
                                                                              data.priority
                                                                            ) {
                                                                              return (
                                                                                <>
                                                                                  <div
                                                                                    style={{
                                                                                      margin:
                                                                                        "3px 25px  3px 0px"
                                                                                    }}
                                                                                    onClick={() => {
                                                                                      doOpenTaskDetail(
                                                                                        data
                                                                                      );
                                                                                    }}
                                                                                  >
                                                                                    <img
                                                                                      src={getPriorityPath(
                                                                                        data.priority
                                                                                      )}
                                                                                      height="17"
                                                                                      width="18"
                                                                                      className=""
                                                                                      alt="Priority"
                                                                                      style={{
                                                                                        position:
                                                                                          "relative",
                                                                                        top:
                                                                                          "-2px"
                                                                                      }}
                                                                                    />
                                                                                    <span
                                                                                      style={{
                                                                                        cursor:
                                                                                          "pointer",
                                                                                        padding:
                                                                                          "0px 6px",
                                                                                        color:
                                                                                          "gray"
                                                                                      }}
                                                                                    >
                                                                                      {
                                                                                        item.value
                                                                                      }
                                                                                    </span>
                                                                                  </div>
                                                                                </>
                                                                              );
                                                                            }
                                                                          }
                                                                        )}
                                                                      </>
                                                                    )}

                                                                  {data.labels &&
                                                                    data.labels.map(
                                                                      (
                                                                        item,
                                                                        index
                                                                      ) => {
                                                                        return (
                                                                          <>
                                                                            <div
                                                                              style={{
                                                                                margin:
                                                                                  "3px 25px  3px 0px"
                                                                              }}
                                                                              onClick={() => {
                                                                                doOpenTaskDetail(
                                                                                  data
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div
                                                                                className="my-day-label flex-x mr-6 fs-14 nevy--text align-center overflow-hidden"
                                                                                key={
                                                                                  index
                                                                                }
                                                                              >
                                                                                <div
                                                                                  style={{
                                                                                    height: 20,
                                                                                    width: 18,
                                                                                    marginRight: 5
                                                                                  }}
                                                                                >
                                                                                  <IconTint
                                                                                    src={
                                                                                      iconTag
                                                                                    }
                                                                                    style={{
                                                                                      position:
                                                                                        "relative",
                                                                                      top:
                                                                                        "1px",
                                                                                      height:
                                                                                        "18",
                                                                                      width:
                                                                                        "18"
                                                                                    }}
                                                                                    color={getColorCode(
                                                                                      item.color
                                                                                    )}
                                                                                  />
                                                                                </div>

                                                                                <span
                                                                                  style={{
                                                                                    // color: getColorCode(item.color),
                                                                                    color:
                                                                                      "black",
                                                                                    paddingLeft: 5,
                                                                                    maxWidth:
                                                                                      "300px",
                                                                                    whiteSpace:
                                                                                      "nowrap",
                                                                                    textOverflow:
                                                                                      " ellipsis",
                                                                                    overflow:
                                                                                      "hidden"
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    item.name
                                                                                  }
                                                                                </span>
                                                                              </div>
                                                                            </div>
                                                                          </>
                                                                        );
                                                                      }
                                                                    )}

                                                                  <div
                                                                    className={`my-day-label flex-x mr-0 fs-14 nevy--text align-center overflow-hidden ${
                                                                      data.labels
                                                                        ? data
                                                                            .labels
                                                                            .length >
                                                                          0
                                                                          ? "label"
                                                                          : ""
                                                                        : ""
                                                                    }`}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        margin:
                                                                          "3px 25px  3px 0px"
                                                                      }}
                                                                      onClick={() => {
                                                                        doOpenTaskDetail(
                                                                          data
                                                                        );
                                                                      }}
                                                                    >
                                                                      <img
                                                                        alt=""
                                                                        src={
                                                                          commentnew
                                                                        }
                                                                        style={{
                                                                          height:
                                                                            "18px",
                                                                          width:
                                                                            "15px",
                                                                          marginRight:
                                                                            "0px",
                                                                          marginTop:
                                                                            "0px",
                                                                          textAlign:
                                                                            "center"
                                                                        }}
                                                                      ></img>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "grey",
                                                                          paddingLeft: 5
                                                                        }}
                                                                      >
                                                                        {
                                                                          data.commentCount
                                                                        }
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      alignItems:
                                                                        "center",
                                                                      display:
                                                                        "flex"
                                                                    }}
                                                                    onClick={() => {
                                                                      doOpenTaskDetail(
                                                                        data
                                                                      );
                                                                    }}
                                                                  >
                                                                    {data.scheduledAt && (
                                                                      <img
                                                                        alt=""
                                                                        src={
                                                                          datesnewmodal
                                                                        }
                                                                        height="15px"
                                                                        width="15px"
                                                                        style={{
                                                                          marginRight: 5
                                                                        }}
                                                                      />
                                                                    )}
                                                                    {data.scheduledAt &&
                                                                      data.repeatType !==
                                                                        repeatTypeOptions[0]
                                                                          .value && (
                                                                        <RepeatSvgIcon
                                                                          style={{
                                                                            fill:
                                                                              "green",
                                                                            height:
                                                                              "16px",
                                                                            width:
                                                                              "15px",
                                                                            marginRight:
                                                                              "5px"
                                                                          }}
                                                                        />
                                                                      )}
                                                                    {data.scheduledAt && (
                                                                      <span
                                                                        className="due-date  label"
                                                                        style={{
                                                                          margin:
                                                                            "3px 25px  3px 0px"
                                                                        }}
                                                                      >
                                                                        <IntlMessages
                                                                          id={
                                                                            "task-details.dueon"
                                                                          }
                                                                        />
                                                                        &nbsp;
                                                                        {moment(
                                                                          new Date(
                                                                            data.scheduledAt
                                                                          )
                                                                        ).format(
                                                                          "DD MMM YYYY"
                                                                        )}
                                                                      </span>
                                                                    )}
                                                                  </div>
                                                                  {data.taskAssign &&
                                                                    data.taskAssign.map(
                                                                      item => (
                                                                        <div
                                                                          style={{
                                                                            display:
                                                                              "contents",
                                                                            alignItems:
                                                                              "center"
                                                                          }}
                                                                          onClick={() => {
                                                                            props.open_profile_modal(
                                                                              {
                                                                                profile: item
                                                                              }
                                                                            );
                                                                          }}
                                                                        >
                                                                          <TaskAssignee
                                                                            taskAssign={
                                                                              item
                                                                            }
                                                                          />
                                                                        </div>
                                                                      )
                                                                    )}
                                                                </div>{" "}
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    alignItems:
                                                                      "start"
                                                                  }}
                                                                ></div>
                                                              </div>{" "}
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          id={`overdue-taskmenu-${data.id}`}
                                                          style={{
                                                            marginLeft: 10,
                                                            display: "flex",
                                                            alignItems: "start"
                                                            // marginTop: 16
                                                          }}
                                                          className="overdue-list cursor-pointer"
                                                          onClick={() => {
                                                            setOverdueListName(
                                                              data.title
                                                            );
                                                            toggleTaskMenu(
                                                              `overdue-taskmenu-${data.id}`,
                                                              data
                                                            );
                                                          }}
                                                        >
                                                          <img
                                                            alt=""
                                                            src={threeDot_Icon}
                                                            style={{
                                                              height: 6,
                                                              width: 22,
                                                              marginTop: "14px",
                                                              marginLeft: "6px"
                                                            }}
                                                          ></img>
                                                        </div>
                                                        <div>
                                                          <div
                                                            id={`overdue-taskmenu-labels-${data.id}`}
                                                          />
                                                          <div
                                                            id={`overdue-taskmenu-priority-${data.id}`}
                                                          />

                                                          {isQuickActionTask &&
                                                            isQuickActionTask.status &&
                                                            isQuickActionTask.action ==
                                                              "assign" &&
                                                            isQuickActionTask.target ==
                                                              `overdue-taskmenu-assign-${data.id}` && (
                                                              <>
                                                                <AssigneeDropdown
                                                                  isDisableDropDownIcon
                                                                  TargetId="assing-"
                                                                  setDropdownOpen={() => {
                                                                    doCloseQuickAction();
                                                                  }}
                                                                  businessId={
                                                                    props.auth
                                                                      .user
                                                                      .businessId
                                                                  }
                                                                  projectId={
                                                                    (isQuickActionTask.status &&
                                                                      isQuickActionTask
                                                                        .data
                                                                        .project
                                                                        ?.id) ||
                                                                    ""
                                                                  }
                                                                  dropdownOpen={
                                                                    isQuickActionTask &&
                                                                    isQuickActionTask.status
                                                                  }
                                                                  currentLoggedIn={
                                                                    props.auth
                                                                      ?.user?.id
                                                                  }
                                                                  projectCollaborators={
                                                                    props.collaboratorList
                                                                  }
                                                                  userAssigned={
                                                                    isQuickActionTask.selectedData
                                                                      ? isQuickActionTask.selectedData
                                                                      : ""
                                                                  }
                                                                  setUserAssigned={data => {
                                                                    onChangeTaskAssign(
                                                                      data,
                                                                      doUpdateTaskAssign
                                                                    );
                                                                  }}
                                                                />
                                                              </>
                                                            )}
                                                          {isQuickActionTask &&
                                                            isQuickActionTask.status &&
                                                            isQuickActionTask.action ==
                                                              "schedule" &&
                                                            isQuickActionTask.target ==
                                                              `overdue-taskmenu-schedule-${data.id}` && (
                                                              <div
                                                                style={{
                                                                  position:
                                                                    "relative"
                                                                }}
                                                              >
                                                                <DatePickerNoInput
                                                                  dueDate={
                                                                    isQuickActionTask.selectedData
                                                                      ? isQuickActionTask.selectedData
                                                                      : ""
                                                                  }
                                                                  onUpdateDate={
                                                                    doUpdateTaskScheduleDate
                                                                  }
                                                                  toggle={
                                                                    doCloseQuickAction
                                                                  }
                                                                  isOpen={
                                                                    isQuickActionTask.status
                                                                  }
                                                                  PRIMARY_COLOR={
                                                                    props.PRIMARY_COLOR
                                                                  }
                                                                  AllowAddPreviousDateTask={
                                                                    props.auth
                                                                      .user
                                                                      ?.userSetting
                                                                      ?.AllowAddPreviousDateTask
                                                                  }
                                                                />
                                                              </div>
                                                            )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            );
                          })}
                        {/* <div className="todo-container flex-y">
                          {todo.map((data, i) => {
                            if (data.project.name == project.name) {
                              if (
                                data.isDeleted == false &&
                                data?.isArchived == false
                              ) {
                                return (
                                  <>
                                    {isEditTask?.status &&
                                    isEditTask?.data?.id === data.id &&
                                    isEditTask?.isOverdue ? (
                                      <div
                                        style={{
                                          paddingTop: 5,
                                          paddingBottom: 5
                                        }}
                                      >
                                        <NewTask
                                          updateTask
                                          action={isEditTask?.action || ""}
                                          toggleAction={() =>
                                            setIsEditTask({
                                              ...isEditTask,
                                              action: ""
                                            })
                                          }
                                          collaboratorList={
                                            props.collaboratorList
                                          }
                                          toggle={() => {
                                            setIsEditTask({
                                              status: false,
                                              data: ""
                                            });
                                          }}
                                          taskData={isEditTask.data}
                                          addTaskHandler={updateTaskHandler}
                                          taskProject={taskProject}
                        //                   setTaskProje</div>
                        //                 />
                        //               </div>
                        //             ) : (
                        //               <div
                        //                 key={i}
                        //                 className="flex-x  align-center plr-10 ptb-10 roe-card-style mb-15 todo-board"
                        //                 style={getTodoStyle(data.id)}
                        //               >
                        //                 <div
                        //                   className=" pretty p-icon p-round p-smooth"
                        //                   style={{ marginTop: 3 }}
                        //                 >
                        //                   <input
                        //                     type="checkbox"
                        //                     checked={data.isCompleted}
                        //                     onChange={e =>
                        //                       updateisCompleted(
                        //                         data.project.id,
                        //                         data.id,
                        //                         e,
                        //                         data
                        //                       )
                        //                     }
                        //                   />
                        //                   {!data.isCompleted ? (
                        //                     <img
                        //                       alt=""
                        //                       src={ListGridCircleImage}
                        //                       style={{
                        //                         height: 18,
                        //                         width: 18
                        //                       }}
                        //                     ></img>
                        //                   ) : (
                        //                     <img
                        //                       alt=""
                        //                       src={ListGridCheckImage}
                        //                       style={{
                        //                         height: 18,
                        //                         width: 18
                        //                       }}
                        //                     ></img>
                        //                   )}
                        //                 </div>

                        //                 <div
                        //                   className="flex-1"
                        //                   style={{ cursor: "pointer" }}
                        //                 >
                        //                   <div className="flex-x">
                        //                     <div
                        //                       className="flex-1"
                        //                       onClick={() => {
                        //                         doOpenTaskDetail(data);
                        //                       }}
                        //                     >
                        //                       <div
                        //                         style={{
                        //                           display: "flex",
                        //                           alignItems: "flex-start"
                        //                         }}
                        //                       >
                        //                         <div
                        //                           className="flex-x flex-wrap  fs-14 flex-1"
                        //                           onClick={() => {
                        //                             doOpenTaskDetail(data);
                        //                           }}
                        //                         >
                        //                           <div
                        //                             className="flex-1"
                        //                             style={{
                        //                               display: "inline-flex",
                        //                               flexWrap: "wrap",
                        //                               alignItems: "center"
                        //                             }}
                        //                           >
                        //                             <span
                        //                               className="All_Task_Title fs-14"
                        //                               style={{
                        //                                 maxWidth: "50%",
                        //                                 color: "black",
                        //                                 fontSize: "14px",
                        //                                 fontWeight: 600,
                        //                                 paddingRight: "25px",
                        //                                 wordBreak: "break-word",
                        //                                 display: "-webkit-box",
                        //                                 WebkitBoxOrient:
                        //                                   "vertical",
                        //                                 lineClamp: 1,
                        //                                 textOverflow:
                        //                                   "ellipsis",
                        //                                 overflow: "hidden",
                        //                                 WebkitLineClamp: 1,
                        //                                 WebkitFontSmoothing:
                        //                                   "antialiased"
                        //                               }}
                        //                             >
                        //                               {data.title}
                        //                             </span>
                        //                             {data &&
                        //                               data.priority &&
                        //                               data.priority !==
                        //                                 "no_priority" && (
                        //                                 <>
                        //                                   {allPriority.map(
                        //                                     item => {
                        //                                       if (
                        //                                         item.name ==
                        //                                         data.priority
                        //                                       ) {
                        //                                         return (
                        //                                           <>
                        //                                             <div
                        //                                               style={{
                        //                                                 marginRight: 25
                        //                                               }}
                        //                                             >
                        //                                               <img
                        //                                                 src={getPriorityPath(
                        //                                                   data.priority
                        //                                                 )}
                        //                                                 height="17"
                        //                                                 width="18"
                        //                                                 className=""
                        //                                                 alt="Priority"
                        //                                                 style={{
                        //                                                   position:
                        //                                                     "relative",
                        //                                                   top:
                        //                                                     "-2px"
                        //                                                 }}
                        //                                               />
                        //                                               <span
                        //                                                 style={{
                        //                                                   cursor:
                        //                                                     "pointer",
                        //                                                   padding:
                        //                                                     "0px 6px",
                        //                                                   color:
                        //                                                     "gray"
                        //                                                 }}
                        //                                               >
                        //                                                 {
                        //                                                   item.value
                        //                                                 }
                        //                                               </span>
                        //                                             </div>
                        //                                           </>
                        //                                         );
                        //                                       }
                        //                                     }
                        //                                   )}
                        //                                 </>
                        //                               )}

                        //                             {data.labels &&
                        //                               data.labels.map(
                        //                                 (item, index) => {
                        //                                   return (
                        //                                     <>
                        //                                       <div
                        //                                         style={{
                        //                                           marginRight: 25
                        //                                         }}
                        //                                       >
                        //                                         <div
                        //                                           className="my-day-label flex-x mr-6 fs-14 nevy--text align-center overflow-hidden"
                        //                                           key={index}
                        //                                         >
                        //                                           <div
                        //                                             style={{
                        //                                               height: 20,
                        //                                               width: 18,
                        //                                               marginRight: 5
                        //                                             }}
                        //                                           >
                        //                                             <IconTint
                        //                                               src={
                        //                                                 iconTag
                        //                                               }
                        //                                               style={{
                        //                                                 position:
                        //                                                   "relative",
                        //                                                 top:
                        //                                                   "1px",
                        //                                                 height:
                        //                                                   "18",
                        //                                                 width:
                        //                                                   "18"
                        //                                               }}
                        //                                               color={getColorCode(
                        //                                                 item.color
                        //                                               )}
                        //                                             />
                        //                                           </div>

                        //                                           <span
                        //                                             style={{
                        //                                               // color: getColorCode(item.color),
                        //                                               color:
                        //                                                 "black",
                        //                                               paddingLeft: 5,
                        //                                               maxWidth:
                        //                                                 "300px",
                        //                                               whiteSpace:
                        //                                                 "nowrap",
                        //                                               textOverflow:
                        //                                                 " ellipsis",
                        //                                               overflow:
                        //                                                 "hidden"
                        //                                             }}
                        //                                           >
                        //                                             {item.name}
                        //                                           </span>
                        //                                         </div>
                        //                                       </div>
                        //                                     </>
                        //                                   );
                        //                                 }
                        //                               )}

                        //                             <div
                        //                               className={`my-day-label flex-x mr-0 fs-14 nevy--text align-center overflow-hidden ${
                        //                                 data.labels
                        //                                   ? data.labels.length >
                        //                                     0
                        //                                     ? "label"
                        //                                     : ""
                        //                                   : ""
                        //                               }`}
                        //                             >
                        //                               <div
                        //                                 style={{
                        //                                   marginRight: 25
                        //                                 }}
                        //                               >
                        //                                 <img
                        //                                   alt=""
                        //                                   src={commentnew}
                        //                                   style={{
                        //                                     height: "18px",
                        //                                     width: "15px",
                        //                                     marginRight: "0px",
                        //                                     marginTop: "0px",
                        //                                     textAlign: "center"
                        //                                   }}
                        //                                 ></img>
                        //                                 <span
                        //                                   style={{
                        //                                     color: "grey",
                        //                                     paddingLeft: 5
                        //                                   }}
                        //                                 >
                        //                                   {data.commentCount}
                        //                                 </span>
                        //                               </div>
                        //                             </div>
                        //                             <div
                        //                               style={{
                        //                                 alignItems: "center",
                        //                                 display: "flex"
                        //                               }}
                        //                             >
                        //                               {data.scheduledAt &&
                        //                                 data.repeatType !==
                        //                                   repeatTypeOptions[0]
                        //                                     .value && (
                        //                                   <RepeatSvgIcon
                        //                                     style={{
                        //                                       fill: "green",
                        //                                       height: "16px",
                        //                                       width: "15px",
                        //                                       marginRight: "5px"
                        //                                     }}
                        //                                   />
                        //                                 )}
                        //                               {data.scheduledAt && (
                        //                                 <span
                        //                                   className="due-date  label"
                        //                                   style={{
                        //                                     marginRight: 25
                        //                                   }}
                        //                                 >
                        //                                   Due on{" "}
                        //                                   {moment(
                        //                                     new Date(
                        //                                       data.scheduledAt
                        //                                     )
                        //                                   ).format(
                        //                                     "DD MMM YYYY"
                        //                                   )}
                        //                                 </span>
                        //                               )}
                        //                             </div>

                        //                             <div
                        //                               style={{
                        //                                 display: "contents",
                        //                                 alignItems: "center"
                        //                               }}
                        //                             >
                        //                               {data.taskAssign &&
                        //                                 data.taskAssign.map(
                        //                                   item => (
                        //                                     <TaskAssignee
                        //                                       taskAssign={item}
                        //                                     />
                        //                                   )
                        //                                 )}
                        //                             </div>
                        //                           </div>{" "}
                        //                           <div
                        //                             style={{
                        //                               display: "flex",
                        //                               alignItems: "start"
                        //                             }}
                        //                           >
                        //                             <div
                        //                               className="fs-12 demi-bold-text"
                        //                               style={{
                        //                                 display: "flex",
                        //                                 alignItems: "start",
                        //                                 cursor: "alias"
                        //                               }}
                        //                             >
                        //                               <div
                        //                                 style={{
                        //                                   marginRight: 5
                        //                                 }}
                        //                               >
                        //                                 <i
                        //                                   className="fa fa-circle fs-10"
                        //                                   style={{
                        //                                     color: getColorCode(
                        //                                       project.color
                        //                                     )
                        //                                   }}
                        //                                 />
                        //                               </div>

                        //                               <span
                        //                                 title={project.name}
                        //                                 style={{
                        //                                   fontSize: 12,
                        //                                   fontWeight: 600,
                        //                                   color: "black",
                        //                                   display:
                        //                                     "inline-block",
                        //                                   whiteSpace: "nowrap",
                        //                                   overflow: "hidden",
                        //                                   textOverflow:
                        //                                     "ellipsis",
                        //                                   maxWidth: "150px",
                        //                                   alignItems: "end"
                        //                                 }}
                        //                               >
                        //                                 {" "}
                        //                                 {project.name}
                        //                               </span>
                        //                             </div>
                        //                           </div>
                        //                         </div>{" "}
                        //                       </div>
                        //                     </div>

                        //                     <div
                        //                       id={`overdue-taskmenu-${data.id}`}
                        //                       style={{
                        //                         marginLeft: 10,
                        //                         display: "flex",
                        //                         alignItems: "start",
                        //                         marginTop: 7
                        //                       }}
                        //                       onClick={() => {
                        //                         setOverdueListName(data.title);
                        //                         toggleTaskMenu(
                        //                           `overdue-taskmenu-${data.id}`,
                        //                           data
                        //                         );
                        //                       }}
                        //                       className="cursor-pointer"
                        //                     >
                        //                       <img
                        //                         alt=""
                        //                         src={threeDot_Icon}
                        //                         style={{
                        //                           height: 6,
                        //                           width: 22
                        //                         }}
                        //                       ></img>
                        //                     </div>
                        //                     <div>
                        //                       <div
                        //                         id={`overdue-taskmenu-labels-${data.id}`}
                        //                       />
                        //                       <div
                        //                         id={`overdue-taskmenu-priority-${data.id}`}
                        //                       />

                        //                       {isQuickActionTask &&
                        //                         isQuickActionTask.status &&
                        //                         isQuickActionTask.action ==
                        //                           "assign" &&
                        //                         isQuickActionTask.target ==
                        //                           `overdue-taskmenu-assign-${data.id}` && (
                        //                           <>
                        //                             <AssigneeDropdown
                        //                               isDisableDropDownIcon
                        //                               TargetId="assing-"
                        //                               setDropdownOpen={() => {
                        //                                 doCloseQuickAction();
                        //                               }}
                        //                               businessId={
                        //                                 props.auth.user
                        //                                   .businessId
                        //                               }
                        //                               projectId={
                        //                                 (isQuickActionTask.status &&
                        //                                   isQuickActionTask.data
                        //                                     .project?.id) ||
                        //                                 ""
                        //                               }
                        //                               dropdownOpen={
                        //                                 isQuickActionTask &&
                        //                                 isQuickActionTask.status
                        //                               }
                        //                               currentLoggedIn={
                        //                                 props.auth?.user?.id
                        //                               }
                        //                               projectCollaborators={
                        //                                 props.collaboratorList
                        //                               }
                        //                               userAssigned={
                        //                                 isQuickActionTask.selectedData
                        //                                   ? isQuickActionTask.selectedData
                        //                                   : ""
                        //                               }
                        //                               setUserAssigned={data => {
                        //                                 onChangeTaskAssign(
                        //                                   data,
                        //                                   doUpdateTaskAssign
                        //                                 );
                        //                               }}
                        //                             />
                        //                           </>
                        //                         )}
                        //                       {isQuickActionTask &&
                        //                         isQuickActionTask.status &&
                        //                         isQuickActionTask.action ==
                        //                           "schedule" &&
                        //                         isQuickActionTask.target ==
                        //                           `overdue-taskmenu-schedule-${data.id}` && (
                        //                           <div
                        //                             style={{
                        //                               position: "relative"
                        //                             }}
                        //                           >
                        //                             <DatePickerNoInput
                        //                               // AllowAddPreviousDateTask={AllowAddPreviousDateTask}
                        //                               dueDate={
                        //                                 isQuickActionTask.selectedData
                        //                                   ? isQuickActionTask.selectedData
                        //                                   : ""
                        //                               }
                        //                               onUpdateDate={
                        //                                 doUpdateTaskScheduleDate
                        //                               }
                        //                               toggle={
                        //                                 doCloseQuickAction
                        //                               }
                        //                               isOpen={
                        //                                 isQuickActionTask.status
                        //                               }
                        //                               PRIMARY_COLOR={
                        //                                 props.PRIMARY_COLOR
                        //                               }
                        //                             />
                        //                           </div>
                        //                         )}
                        //                     </div>
                        //                   </div>
                        //                 </div>
                        //               </div>
                        //             )}
                        //           </>
                        //         );
                        //       }
                        //     }
                        //   })}
                        // </div> */}
                      </div>
                    );
                  })}
                <div>
                  {overDuePages?.current &&
                  overDuePages?.total &&
                  overDuePages.current < overDuePages.total ? (
                    <>
                      <div
                        style={{
                          color: props.PRIMARY_COLOR,
                          fontSize: 14,
                          textDecoration: isViewMoreLoader
                            ? "none"
                            : "underline",
                          textAlign: "center",
                          cursor: isViewMoreLoader ? "not-allowed" : "pointer",
                          paddingBottom: 10
                        }}
                      >
                        {isViewMoreLoader ? (
                          <LinearLoader />
                        ) : (
                          <div
                            style={{
                              height: 100,
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: "2.09rem"
                            }}
                            onClick={() =>
                              !isViewMoreLoader
                                ? handleViewMoreOverdueTasks()
                                : {}
                            }
                          >
                            {
                              <IntlMessages
                                id={"globalactivity.viewmorebutton"}
                              />
                            }
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </TodolistWrapper>
      <NewTaskModal
        setmodal={() => setIsNewTask(!isnewTask)}
        modal={isnewTask}
        showNotifications={message => props.showNotifications(message)}
      />
      <TaskAssignModal
        isOpen={isAssignTaskModal}
        toggle={() => {
          SetisAssignTaskModal(!isAssignTaskModal);
        }}
        collaboratorList={props.collaboratorList}
        dummyData={props.dummyData || false}
        taskId={taskDetail && taskDetail.id}
        projectId={props.projectId}
        taskAssign={taskDetail && taskDetail.taskAssign}
        // doUpdateTaskAssign={updateTaskAssign}
        updateTaskAssign={data => {
          SetisAssignTaskModal(!isAssignTaskModal);
          updateActiveTaskDetail({
            ...taskDetail,
            taskAssign: data
              ? {
                  id: data.id,
                  name: data.name,
                  thumbnail: data.userMeta?.thumbnail
                }
              : null
          });
        }}
      />
      <TaskMenu
        projectTask
        isMenu={taskMenu}
        toggleMenu={() => toggleTaskMenu()}
        viewTaskDetails={() => {
          doOpenTaskDetail(activeTaskDetail);
          setTaskMenu(false);
          setActiveTaskMenu("");
        }}
        doArchiveTask={() =>
          doArchiveTask(activeTaskDetail?.id, activeTaskDetail.project.id)
        }
        target={activeTaskMenu}
        editLabels={() => {
          setIsEditTask({
            status: true,
            data: activeTaskDetail,
            action: "label",
            isOverdue: true
          });
        }}
        editAssign={() => {
          if (activeTaskDetail && activeTaskDetail.id) {
            setIsQuickActionTask({
              status: true,
              taskId: activeTaskDetail.id,
              action: "assign",
              target: `overdue-taskmenu-assign-${activeTaskDetail.id}`,
              selectedData:
                activeTaskDetail && activeTaskDetail.taskAssign
                  ? { user: activeTaskDetail?.taskAssign }
                  : "",
              data: activeTaskDetail
            });
          }
        }}
        editPriority={() => {
          if (activeTaskDetail && activeTaskDetail.id) {
            setIsQuickActionTask({
              status: true,
              taskId: activeTaskDetail.id,
              action: "priority",
              target: `overdue-taskmenu-priority-${activeTaskDetail.id}`,
              selectedData: activeTaskDetail && activeTaskDetail.priority,
              data: activeTaskDetail
            });
          }
        }}
        editDate={() => {
          setIsQuickActionTask({
            status: true,
            taskId: activeTaskDetail.id,
            action: "schedule",
            target: `overdue-taskmenu-schedule-${activeTaskDetail.id}`,
            selectedData: activeTaskDetail && activeTaskDetail.scheduledAt,
            data: activeTaskDetail
          });
        }}
        editTask={() => {
          if (taskMenu) {
            setIsEditTask({
              status: true,
              data: activeTaskDetail,
              isOverdue: true
            });
          } else {
            setIsEditTask({ status: false, data: "" });
          }
          setTaskMenu(!taskMenu);
        }}
        deleteTask={id => {
          setTaskMenu(!taskMenu);
          setCustomAlert(true);
        }}
      />
      <CustomAlert
        CustomMessage={overdueListName}
        action="delete"
        messageType="task"
        PRIMARY_COLOR={props.PRIMARY_COLOR}
        show={isCustomAlert}
        onConfirm={() => {
          deleteTask(activeTaskDetail.id, activeTaskDetail.project.id);
          setCustomAlert(false);
        }}
        onCancel={() => setCustomAlert(false)}
        alertMessage={sectionalertMessage}
      />
      {isQuickActionTask &&
        isQuickActionTask.status &&
        isQuickActionTask.action == "priority" && (
          <PriorityPopover
            isOpen={isQuickActionTask.status}
            toggle={doCloseQuickAction}
            target={
              isQuickActionTask.status && isQuickActionTask.target
                ? isQuickActionTask.target
                : "priority-task-details"
            }
            selectedPriority={
              isQuickActionTask?.status && isQuickActionTask.selectedData
                ? isQuickActionTask.selectedData
                : "no_priority"
            }
            updatePriority={doUpdateTaskPriority}
            PRIMARY_COLOR={props.PRIMARY_COLOR}
          />
        )}
    </div>
  );
};
export default connect(null, { open_profile_modal })(TodayListView);
