/** @format */

import React, { useEffect, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import "./WorkspacePopoverWrapper.style.css";
import { getUserCharColor } from "helper/methods/PlaceholderColor";
import { connect } from "react-redux";
import { WorkspacePopoverCrossIcon } from "helper/constant";
import workspaceDropdownAction from "redux/workspaceDropdown/actions";

import BusinessListShimmer from "components/shimmer/BusinessListShimmer";
import IntlMessages from "util/intlMessages";
const { open_workspace_modal, close_workspace_modal } = workspaceDropdownAction;

const WorkspacePopover = props => {
  const {
    target,
    modal,
    setmodal,
    archivedBusinessArr,
    businessListArr,
    currentbusinessName,
    currentbusinessId,
    isBusinessLoader,
    history,
    PRIMARY_COLOR,
    toggleNewBusiness,
    swicthBusiness,
    currentBusinessMeta,
    isOpen
  } = props;

  const [recentWorkspace, setRecentWorkspace] = useState([]);
  useEffect(() => {
    let recent_workspace = JSON.parse(localStorage.getItem("recent_workspace"));
    setRecentWorkspace(recent_workspace);
  }, [isOpen]);
  return (
    <div>
      <Popover
        trigger="legacy"
        isOpen={modal}
        hideArrow
        placement={"bottom-start"}
        // onClick={() =>
        //   props.workspaceDropdownAction.isOpen
        //     ? setmodal(props.close_workspace_modal())
        //     : setmodal(props.open_workspace_modal())
        // }
        toggle={() =>
          props.workspaceDropdownAction.isOpen
            ? setmodal(props.close_workspace_modal())
            : setmodal(props.open_workspace_modal())
        }
        target={target}
      >
        <PopoverBody>
          <div className="workspace-popover">
            <div
              className="workspace-popover-header"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "10px"
              }}
            >
              <div style={{ width: "100%" }}>
                {<IntlMessages id={"globalworkspacelist.workspaces"} />}
              </div>
              <div className="cursor-pointer" onClick={() => setmodal(!modal)}>
                <img
                  src={WorkspacePopoverCrossIcon}
                  alt=""
                  style={{
                    height: 12,
                    width: 12,
                    cursor: "pointer"
                  }}
                ></img>
              </div>
            </div>

            <div
              className="workspace-container"
              style={{
                maxHeight: "45vh",
                overflow: "scroll",
                marginBottom: 5,
                paddingTop: 15
              }}
            >
              {businessListArr && businessListArr?.length > 1 && (
                <>
                  <div>
                    <div className="default-list-header">
                      <IntlMessages
                        id={"globalworkspacelist.recentworkspaces"}
                      />
                    </div>
                    <div className="default-workspace-list">
                      <div className="workspace-Avatar">
                        <div
                          className=" workspace-placeholder-search-popover"
                          style={{
                            // background: props.topbarTheme.buttonColor,
                            background:
                              currentBusinessMeta &&
                              currentBusinessMeta.length > 0
                                ? ""
                                : getUserCharColor(
                                    currentbusinessName &&
                                      currentbusinessName.charAt(0)
                                  ),
                            cursor: "pointer",
                            objectFit: "cover"
                          }}
                          onClick={() => {
                            history.push(
                              `/business/${currentbusinessId}`,
                              props.close_workspace_modal()
                            );
                          }}
                        >
                          <>
                            {currentBusinessMeta &&
                            currentBusinessMeta.length > 0 ? (
                              <>
                                <img
                                  alt="img"
                                  src={currentBusinessMeta}
                                  style={{
                                    width: 38,
                                    height: 38,
                                    borderRadius: 7,
                                    objectFit: "cover"
                                  }}
                                />
                              </>
                            ) : (
                              currentbusinessName &&
                              currentbusinessName.charAt(0).toUpperCase()
                            )}
                          </>
                        </div>
                      </div>
                      <div className="workspace-details-container">
                        <div
                          className="workspace-title- text-underline"
                          style={{
                            color: "black",
                            width: "92%",
                            overflowWrap: "break-word",
                            margin: "0px",
                            fontSize: "14px",
                            fontWeight: "500",
                            cursor: "pointer",
                            marginLeft: 10,
                            position: "relative",
                            top: "-3px"
                          }}
                          onClick={() => {
                            history.push(`/business/${currentbusinessId}`);
                            props.workspaceDropdownAction.isOpen
                              ? setmodal(props.close_workspace_modal())
                              : setmodal(props.open_workspace_modal());
                            // toggle();
                          }}
                        >
                          {currentbusinessName && currentbusinessName}
                        </div>
                      </div>
                      {currentbusinessId && (
                        <div className="workspace-details-active">
                          <i
                            className="fa fa-check-circle"
                            style={{
                              color: PRIMARY_COLOR ? PRIMARY_COLOR : "black"
                            }}
                            aria-hidden="true"
                          ></i>
                        </div>
                      )}
                    </div>
                    <>
                      {recentWorkspace &&
                        recentWorkspace?.length > 0 &&
                        recentWorkspace.map((business, i) => {
                          if (currentbusinessId !== business.businessId) {
                            return (
                              <>
                                <div className="default-workspace-list">
                                  <div className="workspace-Avatar">
                                    <div
                                      className=" workspace-placeholder-search-popover"
                                      style={{
                                        // background: props.topbarTheme.buttonColor,
                                        background: business?.businessMeta
                                          ? ""
                                          : getUserCharColor(
                                              business &&
                                                business.businessName.charAt(0)
                                            ),
                                        cursor: "pointer",
                                        objectFit: "cover"
                                      }}
                                      onClick={() => {
                                        swicthBusiness(business?.businessId);
                                        history.push(
                                          `/business/${business?.businessId}`
                                        );
                                      }}
                                    >
                                      <>
                                        {business?.businessMeta ? (
                                          <>
                                            <img
                                              alt="img"
                                              src={business?.businessMeta}
                                              style={{
                                                width: 38,
                                                height: 38,
                                                borderRadius: 7,
                                                objectFit: "cover"
                                              }}
                                            />
                                          </>
                                        ) : (
                                          business.businessName &&
                                          business.businessName
                                            .charAt(0)
                                            .toUpperCase()
                                        )}
                                      </>
                                    </div>
                                  </div>
                                  <div className="workspace-details-container">
                                    <div
                                      className="workspace-title- text-underline"
                                      style={{
                                        color: "black",
                                        width: "92%",
                                        overflowWrap: "break-word",
                                        margin: "0px",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        cursor: "pointer",
                                        marginLeft: 10,
                                        position: "relative",
                                        top: "-3px"
                                      }}
                                      onClick={() => {
                                        swicthBusiness(business?.businessId);
                                        history.push(
                                          `/business/${business?.businessId}`
                                        );
                                      }}
                                    >
                                      {business.businessName &&
                                        business.businessName}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })}
                    </>
                  </div>
                  <hr style={{ marginTop: "5px" }} />
                </>
              )}
              <div>
                {businessListArr && businessListArr?.length > 0 && (
                  <div
                    className="default-list-header"
                    style={{ paddingBottom: 5 }}
                  >
                    <IntlMessages id={"globalworkspacelist.yourworkspaces"} />
                  </div>
                )}

                <div>
                  {isBusinessLoader ? (
                    <BusinessListShimmer />
                  ) : (
                    <>
                      {businessListArr &&
                        businessListArr?.length > 0 &&
                        businessListArr.map(business => {
                          return (
                            <>
                              <div className="default-workspace-list">
                                <div className="workspace-Avatar">
                                  <div
                                    className=" workspace-placeholder-search-popover"
                                    style={{
                                      // background: props.topbarTheme.buttonColor,
                                      background: business?.businessMeta
                                        ?.thumbnail
                                        ? ""
                                        : getUserCharColor(
                                            business && business.title.charAt(0)
                                          ),
                                      cursor: "pointer",
                                      objectFit: "cover"
                                    }}
                                    onClick={() => {
                                      swicthBusiness(business?.id);
                                      history.push(`/business/${business?.id}`);
                                    }}
                                  >
                                    <>
                                      {business?.businessMeta ? (
                                        <>
                                          <img
                                            alt=""
                                            src={business?.businessMeta?.url}
                                            style={{
                                              width: 38,
                                              height: 38,
                                              borderRadius: 7,
                                              objectFit: "cover"
                                            }}
                                          />
                                        </>
                                      ) : (
                                        business.title &&
                                        business.title.charAt(0).toUpperCase()
                                      )}
                                    </>
                                  </div>
                                </div>
                                <div className="workspace-details-container">
                                  <div
                                    className="workspace-title- text-underline"
                                    style={{
                                      color: "black",
                                      width: "92%",
                                      overflowWrap: "break-word",
                                      margin: "0px",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      cursor: "pointer",
                                      marginLeft: 10,
                                      position: "relative",
                                      top: "-3px"
                                    }}
                                    onClick={() => {
                                      swicthBusiness(business?.id);
                                      history.push(`/business/${business?.id}`);
                                    }}
                                  >
                                    {business.title && business.title}
                                  </div>
                                </div>
                                {currentbusinessId == business.id && (
                                  <div className="workspace-details-active">
                                    <i
                                      className="fa fa-check-circle"
                                      style={{
                                        color: PRIMARY_COLOR
                                          ? PRIMARY_COLOR
                                          : "black"
                                      }}
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                      {archivedBusinessArr && archivedBusinessArr?.length > 0 && (
                        <div>
                          <div className="default-list-header">
                            <IntlMessages
                              id={"globalworkspacelist.archievedworkspaces"}
                            />
                          </div>
                          <div className="workspace-details-container">
                            <>
                              {archivedBusinessArr &&
                                archivedBusinessArr?.length > 0 &&
                                archivedBusinessArr.map(business => {
                                  return (
                                    <>
                                      <div className="default-workspace-list">
                                        <div className="workspace-Avatar">
                                          <div
                                            className=" workspace-placeholder-search-popover"
                                            style={{
                                              // background: props.topbarTheme.buttonColor,
                                              background: getUserCharColor(
                                                business &&
                                                  business.title.charAt(0)
                                              ),
                                              cursor: "pointer",
                                              objectFit: "cover"
                                            }}
                                            onClick={() => {
                                              history.push(
                                                `/business/${business?.id}`
                                              );
                                            }}
                                          >
                                            {business.title &&
                                              business.title
                                                .charAt(0)
                                                .toUpperCase()}
                                          </div>
                                        </div>
                                        <div className="workspace-details-container">
                                          <div
                                            className="workspace-title- text-underline"
                                            style={{
                                              color: "black",
                                              width: "92%",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              cursor: "pointer",
                                              marginLeft: 10,
                                              position: "relative",
                                              top: "-3px"
                                            }}
                                            onClick={() => {
                                              history.push(
                                                `/business/${business?.id}`
                                              );
                                            }}
                                          >
                                            {business.title && business.title}
                                          </div>
                                        </div>
                                        {currentbusinessId == business.id && (
                                          <div className="workspace-details-active">
                                            <i
                                              className="fa fa-check-circle"
                                              style={{
                                                color: PRIMARY_COLOR
                                                  ? PRIMARY_COLOR
                                                  : "black"
                                              }}
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  );
                                })}
                            </>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <hr style={{ marginTop: "0px" }} />
            <div
              className="flex-x"
              style={{
                justifyContent: "center"
              }}
            >
              <button
                className="btn"
                style={{
                  backgroundColor: PRIMARY_COLOR,
                  color: "#FFF",
                  width: 280,
                  marginBottom: "10px",
                  fontSize: "12px"
                }}
                onClick={() => {
                  toggleNewBusiness();

                  props.workspaceDropdownAction.isOpen
                    ? setmodal(props.close_workspace_modal())
                    : setmodal(props.open_workspace_modal());

                  // toggle();
                  // setAddBusiness(true);
                  // isToggleAllowed && setmodal(!modal);
                }}
              >
                <span
                  style={{ fontSize: "12px", color: "white", fontWeight: 600 }}
                >
                  {" "}
                  <IntlMessages
                    id={"globalworkspacelist.addworkspacesbutton"}
                  />
                </span>
              </button>
            </div>
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps, {
  open_workspace_modal,
  close_workspace_modal
})(WorkspacePopover);
