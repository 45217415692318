import handleResponse from "./handleResponse";
import { BASE_URL, _sharebleLink } from "./apiConstants";

export function retrivePublicTask(token) {
  const requestOptions = {
    method: "GET"
  };
  if (token) {
    return fetch(
      process.env.REACT_APP_API_ENDPOINT + _sharebleLink + `?token=${token}`,
      requestOptions
    ).then(respnose => {
      if (respnose.status !== 200) {
        return false;
      }
      return respnose.text();
    });
  }
}
