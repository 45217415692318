import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import enhancer from "./enhancer/forgotPasswordEnhancer";
import { forgotPassword } from "helper/services/authServices";
import Loader from "components/loader/Loader";
import Notifications, { notify } from "react-notify-toast";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import { connect } from "react-redux";

const ForgotPassword = props => {
  const [isLoader, setIsLoader] = useState(false);
  const [appName, setAppName] = useState();
  React.useEffect(() => {
    const storeAppname = JSON.parse(localStorage.getItem("appname"));
    setAppName(storeAppname);
  }, []);
  const loginContainer = {
    backgroundColor: "rgb(250,250,250)",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };
  const handleForgotPassword = async e => {
    e.preventDefault();
    let { values, handleSubmit } = props;
    if (values.email) {
      setIsLoader(true);
      try {
        const Response = await forgotPassword({ email: values.email });
        if (Response.errors) {
          setIsLoader(false);
          notify.show(Response.errors.message, "error");
        } else if (Response.error) {
          setIsLoader(false);
          notify.show(Response.error.message, "error");
        } else {
          notify.show(Response.message, "success");

          setTimeout(function() {
            window.location.href = "/login";
          }, 500);

          // window.alert(Response.message);
          // window.location.href = "/login";
          setIsLoader(false);
        }
      } catch (e) {
        setIsLoader(false);
      }
    }
    handleSubmit();
  };

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;

  const ErrorComponment = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  return (
    <div className="container-fluid" style={loginContainer}>
      <Notifications />
      <div className="">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 40
          }}
        >
          <img
            src={appName?.data?.icon}
            alt="icon"
            height="50px"
            style={{ marginRight: 10 }}
          />
        </div>
        {/* <div
          className="login-icon"
          style={{ paddingTop: 60, paddingBottom: 10 }}
        > */}
        {/* <img src={ForgotIcon} alt="icon" height="100px" /> */}
        {/* <img src={iconDemo} alt="icon" height="55px" />
        </div> */}
        {/* <div
          className="login-title "
          style={{ fontSize: 20, color: "black", fontWeight: "600" }}
        >
          Forgot Password
        </div> */}
        <div className="form-container">
          {/* <div
          className="text-center form-info-text plr-24 mt-16"
          style={{ fontSize: 14, color: "#808080" }}
        > */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 16,
              fontWeight: "600",
              color: "#5e6c84",
              paddingTop: 60
            }}
          >
            <IntlMessages id={"forgetpassword.title"} />
          </div>

          {/* </div> */}
          <form className="plr-60 ptb-80" onSubmit={handleForgotPassword}>
            <div className="form-group" style={{ marginBottom: "50px" }}>
              <input
                style={{ height: "40px" }}
                type="email"
                className="form-control react-form-input"
                id="email"
                aria-describedby="emailHelp"
                placeholder={InjectPlaceHolderMessage(
                  "forgetpassword.placeholder",
                  props?.LanguageSwitcher?.language?.languageId
                )}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorComponment field="email" />
            </div>
            {isLoader ? (
              <Loader />
            ) : (
              <button
                type="submit"
                className="btn form-button"
                style={{
                  borderRadius: "5px",
                  height: "40px",
                  fontSize: "14px",
                  color: "white",
                  fontWeight: "600",
                  background: "#0eb998"
                }}
              >
                <IntlMessages id={"forgetpassword.submit"} />
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,

    LanguageSwitcher: { ...state.LanguageSwitcher }
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps)
)(ForgotPassword);
